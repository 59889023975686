import { ParametrosPOConstants, ParametrosPO } from './types.d'

export const getParametrosPO = (idIntegrador: string): Record<string, any> => ({
  type: ParametrosPOConstants.ACTION_GET_PARAMETROSPO,
  payload: { idIntegrador }
})

export const setSelectedParametrosPO = (idIntegrador: string): Record<string, any> => ({
  type: ParametrosPOConstants.ACTION_SET_SELECTED_PARAMETROSPO,
  payload: { idIntegrador }
})

export const saveParametrosPO = (data: ParametrosPO): Record<string, any> => ({
  type: ParametrosPOConstants.ACTION_SAVE_PARAMETROSPO,
  payload: { data }
})
