import Codes from '../../helpers/Codes'
import { Integrador, IntegradorQueryParams } from './types.d'
import Exception from '../../helpers/Exception'
import { apiRequest } from 'api'

export const getIntegradores = async (
  params: IntegradorQueryParams,
  token: string
): Promise<Integrador[]> => {
  try {
    return apiRequest<Integrador[]>('/v1/integradores', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/integrador/get', error)
  }
}
