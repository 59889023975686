export enum TitleAlignment {
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT'
}

export type LineChartOptions = {
  chart: {
    id: string
    height: number
    spanNullValues?: boolean
    group?: string
    zoom: {
      enabled: boolean
    }
  }
  title?: {
    text?: string
    align?: TitleAlignment
  }
  dataLabels?: {
    enabled: boolean
  }
  tooltip?: {
    enabled?: boolean
    enabledOnSeries?: boolean
    shared?: boolean
    followCursor?: boolean
    intersect?: boolean
  }
  grid: {
    show: boolean
    row: {
      colors: Array<string> // takes an array which will be repeated on columns
      opacity: number
    }
  }
  yaxis: {
    title?: {
      text: string
    }
    type?: string
  }
  xaxis: {
    title?: {
      text: string
    }
    type?: string
    categories?: Array<any>
  }
  legend?: Legend
  stroke?: Stroke
  forecastDataPoints?: Array<{
    name?: string
    count?: number | Array<number>
    dashArray?: number
  }>
  fill?: {
    colors?: Array<string>
    type?: string
    opacity?: number
    gradient?: Gradiente
  }
  colors?: Array<string>
  markers?: Markers
  annotations?: Annotation
}

type Annotation = {
  points?: Array<Point>
}

export type Point = {
  x?: number
  y?: number
  marker?: Marker
}

type Legend = {
  show?: boolean
}

type Gradiente = {
  shade?: string
  gradientToColors?: Array<string>
  shadeIntensity?: number
  type?: string
  opacityFrom?: number
  opacityTo?: number
  stops?: Array<number>
}

type Stroke = {
  curve?: string
  width?: Array<number> | number
  dashArray?: Array<number> | number
}

type Markers = {
  size?: number | Array<number>
  colors?: any
  strokeColors?: string
  strokeWidth?: number
  strokeOpacity?: number
  strokeDashArray?: number
  fillOpacity?: number
  discrete?: Array<Discrete>
  shape?: string
  radius?: number
  offsetX?: number
  offsetY?: number
  onClick?: any
  onDblClick?: any
  showNullDataPoints?: boolean
  hover?: {
    size?: number
    sizeOffset?: number
  }
}

export type Discrete = {
  seriesIndex?: number
  dataPointIndex?: number
  fillColor?: string
  strokeColor?: string
  size?: number
  shape?: string
}

type Axis = {
  name: any
  data: Array<number | null>
}

type Props = {
  values: Array<Axis> | undefined
  chartOptions: ChartOptions
}

export type ParentProps = Props
