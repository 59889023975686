import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Resources
import './styles.scss'
import HomeIcon from '../../resources/img/icons/home-icon'
import PlanejamentoIcon from '../../resources/img/icons/planejamento-icon'
import AnaliseIcon from '../../resources/img/icons/analise-icon'
import ParametrosPOIcon from '../../resources/img/icons/parametrosPO-icon'
import colors, { Colors } from 'styles/variables/colors'
import { mdiWeight } from '@mdi/js'
import Icon from '@mdi/react'

// Types
import { DrawerItem } from './types.d'
import { ApplicationStore } from 'store/types.d'

//Componentes
import {
  Drawer as DrawerMui,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import WeightIcon from 'resources/img/icons/weight-icon'

export const drawerItens: DrawerItem[] = [
  {
    key: 0,
    icon: <HomeIcon width={'1.8rem'} height={'1.8rem'} fill='white' />,
    label: 'link:home',
    path: '/',
    checkActivePath: (path: string) => path === '/'
  },
  {
    key: 1,
    icon: <PlanejamentoIcon width={'1.8rem'} height={'1.8rem'} fill='white' />,
    label: 'link:weekly_planning',
    path: '/planejamentoSemanalV2',
    checkActivePath: (path: string) => path.indexOf('/planejamentoSemanalV2') >= 0
  },
  {
    key: 2,
    icon: <AnaliseIcon width={'1.8rem'} height={'1.8rem'} fill='white' />,
    label: 'link:growth_analysis',
    path: '/analiseCrescimento',
    checkActivePath: (path: string) => path.indexOf('/analiseCrescimento') >= 0
  },
  {
    key: 3,
    icon: <WeightIcon width={'1.8rem'} height={'1.8rem'} fill='white' />,
    label: 'link:weights',
    path: '/pesos',
    checkActivePath: (path: string) => path.indexOf('/pesos') >= 0
  },
  {
    key: 4,
    icon: <ParametrosPOIcon width={'1.8rem'} height={'1.8rem'} fill='white' />,
    label: 'link:PO_parameters',
    path: '/parametrosPO',
    checkActivePath: (path: string) => path.indexOf('/parametrosPO') >= 0
  }
]

export const IconItem: React.FC<any> = (item: any) => {
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  if (item.active === true) {
    if (item.item.key == 0)
      return <HomeIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    if (item.item.key == 1)
      return (
        <PlanejamentoIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
      )
    else if (item.item.key == 2)
      return <AnaliseIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else if (item.item.key == 3)
      return <WeightIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
    else if (item.item.key == 4)
      return (
        <ParametrosPOIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
      )
    else return <HomeIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.accent)} />
  } else {
    if (item.item.key == 0)
      return <HomeIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    if (item.item.key == 1)
      return (
        <PlanejamentoIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
      )
    else if (item.item.key == 2)
      return <AnaliseIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else if (item.item.key == 3)
      return <WeightIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
    else if (item.item.key == 4)
      return (
        <ParametrosPOIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
      )
    else return <HomeIcon width={'1.8rem'} height={'1.8rem'} fill={colors(theme, Colors.gray)} />
  }
}
const Drawer: React.FC = () => {
  // const { pathname } = props.location
  const { pathname } = useLocation()
  const history = useHistory()
  const { isDrawerOpen } = useSelector((state: ApplicationStore) => state.main)
  const { t } = useTranslation()
  const handleClick = (path: string) => {
    history.push(path)
  }
  return (
    <DrawerMui
      className={`drawer ${isDrawerOpen ? 'drawer-opened' : 'drawer-closed'}`}
      variant='permanent'
      classes={{
        paper: isDrawerOpen ? 'drawer-opened' : 'drawer-closed'
      }}
    >
      <div className='drawer-spacing' />
      <List>
        {drawerItens.map((i) => (
          <Tooltip key={i.key} title={t(i.label) as string}>
            <ListItem
              button
              onClick={() => handleClick(i.path)}
              selected={i.checkActivePath(pathname)}
            >
              <ListItemIcon>
                <IconItem item={i} active={i.checkActivePath(pathname)}>
                  {' '}
                </IconItem>
              </ListItemIcon>
              <ListItemText primary={t(i.label)} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </DrawerMui>
  )
}
export default Drawer
