import React, { ReactChild } from 'react'
// Types
import { AlertModalTypes, Themes } from 'store/main/types.d'

// Components
import {
  mdiInformationOutline,
  mdiAlertOctagonOutline,
  mdiAlertCircleOutline,
  mdiCheckCircleOutline
} from '@mdi/js'
import Icon from '@mdi/react'

// Resources
import colors, { Colors } from 'styles/variables/colors'

export const getIcon = (type: string, theme: Themes): ReactChild => {
  switch (type) {
    case AlertModalTypes.info:
      return <Icon path={mdiInformationOutline} size={1.5} color={colors(theme, Colors.info)} />
    case AlertModalTypes.warning:
      return <Icon path={mdiAlertOctagonOutline} size={1.5} color={colors(theme, Colors.warning)} />
    case AlertModalTypes.error:
      return <Icon path={mdiAlertCircleOutline} size={1.5} color={colors(theme, Colors.error)} />
    case AlertModalTypes.success:
      return <Icon path={mdiCheckCircleOutline} size={1.5} color={colors(theme, Colors.success)} />
    default:
      return <Icon path={mdiInformationOutline} size={1.5} color={colors(theme, Colors.info)} />
  }
}

export const getColor = (type: string, lightOpacity: boolean, theme: Themes): string => {
  if (lightOpacity) {
    switch (type) {
      case AlertModalTypes.info:
        return colors(theme, Colors.infoLightOpacity)
      case AlertModalTypes.warning:
        return colors(theme, Colors.warningLightOpacity)
      case AlertModalTypes.error:
        return colors(theme, Colors.errorLightOpacity)
      case AlertModalTypes.success:
        return colors(theme, Colors.successLightOpacity)
      default:
        return colors(theme, Colors.white)
    }
  } else {
    switch (type) {
      case AlertModalTypes.info:
        return colors(theme, Colors.info)
      case AlertModalTypes.warning:
        return colors(theme, Colors.warning)
      case AlertModalTypes.error:
        return colors(theme, Colors.error)
      case AlertModalTypes.success:
        return colors(theme, Colors.success)
      default:
        return colors(theme, Colors.white)
    }
  }
}
