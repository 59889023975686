import { call, put, Effect } from 'redux-saga/effects'

// Types
import { MainConstants } from './types.d'
import { StorageKeys } from 'storage/types.d'

// Methods
import { getItemFromStorage, setItemInStorage } from 'storage'

function* fetchData(payload: any) {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerCheckSettings(): any {
  try {
    yield fetchData({ isCheckingSettings: true })
    const theme = getItemFromStorage(StorageKeys.Theme)
    const locale = getItemFromStorage(StorageKeys.Locale)
    const dataType = getItemFromStorage(StorageKeys.DataType)

    // const columnsTableEconomic = getItemFromStorage(StorageKeys.ColumnsTableEconomic)
    yield call(fetchData, {
      theme,
      locale,
      dataType
    })
  } catch (error: any) {
  } finally {
    yield fetchData({ isCheckingSettings: true })
  }
}

export function* workerSetTheme(action: Effect): any {
  try {
    const { theme } = action.payload
    setItemInStorage(StorageKeys.Theme, theme)
    yield put({
      type: MainConstants.REDUCER_SET_MAIN_DATA,
      payload: {
        theme
      }
    })
    yield call(fetchData, {
      theme
    })
  } catch (error: any) {}
}

export function* workerSetLocale(action: Effect): any {
  try {
    const { locale } = action.payload
    setItemInStorage(StorageKeys.Locale, locale)
    yield call(fetchData, {
      locale
    })
  } catch (error: any) {}
}
