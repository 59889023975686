import React, { Fragment, useEffect, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// Components
import { FormControl, Input, InputAdornment } from '@material-ui/core'
import WeighingScatterCharts from 'components/charts/WeighingScatterCharts'
import { Search } from '@material-ui/icons'
import Loading from 'components/shared/Loading'

// Types
import { ApplicationStore } from 'store/types'
import { GranjaConstants } from 'store/granjas/types.d'

// Methods
import { getActiveLotPesos } from 'store/granjas/actions'

// Resources
import './styles.scss'
import Legend from 'components/shared/Legend'
import { getLotesAlojamentos } from 'store/lotes/actions'

const AviaryWeighingCharts: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [originalData, setOriginalData] = useState<any>([])
  const [searchFieldValue, setSearchFieldValue] = useState<string>('')
  const { activeLotPesos, activeLotpesosLoading } = useSelector(
    (state: ApplicationStore) => state.granja
  )
  const { selectedGranjaId } = useSelector((state: ApplicationStore) => state.granja)
  const { lotesAlojamentos } = useSelector((state: ApplicationStore) => state.lote)
  const [chartDataFiltered, setChartDataFiltered] = useState<any>([])
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  useEffect(() => {
    if ((selectedGranjaId && !lotesAlojamentos.length) || prevSelectedGranjaId !== selectedGranjaId)
      dispatch(
        getLotesAlojamentos({
          idGranja: selectedGranjaId,
          lotesAtivos: true,
          idIntegrador: selectedIntegrador?.id
        })
      )
  }, [selectedGranjaId, selectedIntegrador])

  const prevSelectedGranjaId = usePrevious(selectedGranjaId)

  const lastRef = useRef(null)

  useEffect(() => {
    if (
      selectedGranjaId &&
      prevSelectedGranjaId !== undefined &&
      prevSelectedGranjaId !== selectedGranjaId
    ) {
      setOriginalData([])
      setChartDataFiltered([])

      dispatch({
        type: GranjaConstants.REDUCER_SET_GRANJA_DATA,
        payload: {
          activeLotPesos: []
        }
      })
    }
  }, [selectedGranjaId])

  useEffect(() => {
    if (
      selectedGranjaId &&
      (prevSelectedGranjaId === undefined || prevSelectedGranjaId !== selectedGranjaId)
    ) {
      dispatch(
        getActiveLotPesos(selectedGranjaId, true, undefined, undefined, selectedIntegrador?.id)
      )
    }
  }, [selectedGranjaId, activeLotPesos])

  useEffect(() => {
    if (activeLotPesos.length) {
      setOriginalData(activeLotPesos)
      filterCharts(searchFieldValue, activeLotPesos)
    }
  }, [activeLotPesos])

  const filterCharts = (value: string, data: any[]) => {
    if (value === null || value === '') {
      setChartDataFiltered(data)
      return
    }
    const filtered = data.filter((d: any) => d.idGalpao.toLowerCase().includes(value.toLowerCase()))
    setChartDataFiltered(filtered)
  }

  return (
    <div className='chart-list'>
      <div className='filters-group-chart'>
        <FormControl style={{ width: '200px', padding: '5px' }}>
          <Input
            classes={{ root: 'select-root' }}
            disableUnderline
            value={searchFieldValue}
            onChange={(e) => {
              setSearchFieldValue(e.target.value)
              filterCharts(e.target.value, originalData)
            }}
            placeholder={t('aviary')}
            endAdornment={
              <InputAdornment position='end'>
                <Search className={'icon'} />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      <Legend />
      <Fragment>
        <div className={'charts-container'}>
          {chartDataFiltered.length
            ? chartDataFiltered.map((item: any, idGalpao: string) => {
                return (
                  <div className={'charts'} key={idGalpao}>
                    <h3 className={'chart-title'}>{item.idGalpao}</h3>
                    <WeighingScatterCharts realWeighings={item.pesos}></WeighingScatterCharts>
                  </div>
                )
              })
            : t('weighings:none_weighing')}
          <div style={{ minHeight: '30px', width: '100%' }} ref={lastRef} />
        </div>
        {activeLotpesosLoading && <Loading />}
      </Fragment>
    </div>
  )
}

export default AviaryWeighingCharts
