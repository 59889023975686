import { call, Effect, put, PutEffect, select } from 'redux-saga/effects'
import Exception from '../../helpers/Exception'
// Methods
import { workerSignOut } from 'store/auth/sagas'

// types
import { MainConstants } from '../main/types.d'
import store from '../index'
import {
  getLotes,
  getLotesAcompanhamentos,
  getLotesAlojamentos,
  getLotesDuracao,
  getLotesFechamentos,
  getLotesPesos,
  getQuantLotesProcessados,
  getLotesQuantidadeMensal
} from 'api/lote'
import {
  DuracaoCiclo,
  Lote,
  LoteConstants,
  LoteFechado,
  LotesAcompanhamentos,
  LotesAlojamentos,
  LotesFechamentos,
  LotesPesos
} from './types.d'
import _ from 'lodash'
import Codes from 'helpers/Codes'
import moment from 'moment'
import { IntegradorStore } from 'store/integrador/types'

export function* putLoteData(payload: unknown): Generator<
  PutEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: LoteConstants.REDUCER_SET_LOTE_DATA,
    payload
  })
}

export function* putMainData(payload: unknown): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetLotes(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })

    const { credentials } = store.getState().auth

    let lotes: Lote[] = yield call(getLotes, params, credentials.token)
    lotes = _.sortBy(lotes, ['nroLote'])

    yield call(putLoteData, {
      lotes,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(Codes.Internals.UNEXPECTED_ERROR, 'sagas/lotes/workerGetLotes', error)
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedLote(action: Effect): any {
  const { lote } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })
    yield call(putLoteData, {
      selectedLote: lote,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerSetSelectedLote',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesPesos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })

    const { credentials } = store.getState().auth

    const lotesPesos: LotesPesos[] = yield call(getLotesPesos, params, credentials.token)

    yield call(putLoteData, {
      lotesPesos,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesPesos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesAlojamentos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })

    const { credentials } = store.getState().auth

    const lotesAlojamentos: LotesAlojamentos[] = yield call(
      getLotesAlojamentos,
      params,
      credentials.token
    )
    yield call(putLoteData, {
      lotesAlojamentos,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesAlojamentos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesAcompanhamentos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })

    const { credentials } = store.getState().auth

    const lotesAcompanhamentos: LotesAcompanhamentos[] = yield call(
      getLotesAcompanhamentos,
      params,
      credentials.token
    )

    yield call(putLoteData, {
      lotesAcompanhamentos,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesAcompanhamentos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesFechamentos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })

    const { credentials } = store.getState().auth

    const lotesFechamentos: LotesFechamentos[] = yield call(
      getLotesFechamentos,
      params,
      credentials.token
    )

    yield call(putLoteData, {
      lotesFechamentos,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesFechamentos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedLoteAcompanhamento(action: Effect): any {
  const { acompanhamento } = action.payload
  try {
    yield call(putLoteData, { loteLoading: true })

    yield call(putLoteData, {
      selectedLoteAcompanhamento: acompanhamento,
      loteLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerSetSelectedLotesAcompanhamentos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { loteLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetOneYearAgoLotes(action: Effect): any {
  try {
    const { idIntegrador } = action.payload

    yield call(putLoteData, { oneYearAgoLotesLoading: true })

    const { credentials } = store.getState().auth
    let { oneYearAgoLotes } = store.getState().lote

    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
    oneYearAgo.setDate(1)

    if (!oneYearAgoLotes) {
      oneYearAgoLotes = yield call(
        getLotesDuracao,
        {
          inicio: oneYearAgo.toISOString(),
          idIntegrador
        },
        credentials.token
      )
    }
    yield call(putLoteData, {
      oneYearAgoLotes,
      oneYearAgoLotesLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetOneYearAgoLotes',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { oneYearAgoLotesLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetQuantLotesProcessados(): any {
  try {
    yield call(putLoteData, { quantLotesProcessadosLoading: true })

    const { credentials } = store.getState().auth
    const { quantLotesProcessados } = store.getState().lote
    let lotesTotais

    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    const { selectedIntegrador } = integradorStore

    if (!quantLotesProcessados) {
      lotesTotais = yield call(getQuantLotesProcessados, credentials.token, {
        idIntegrador: selectedIntegrador?.id
      })
    }
    yield call(putLoteData, {
      quantLotesProcessados: lotesTotais?.count,
      quantLotesProcessadosLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetQuantLotesProcessados',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { quantLotesProcessadosLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesAtivos(action: Effect): any {
  const { idIntegrador } = action.payload

  try {
    yield call(putLoteData, { lotesAtivosLoading: true })
    const { credentials } = store.getState().auth

    const lotesAtivos = yield call(getQuantLotesProcessados, credentials.token, {
      idIntegrador,
      ativo: true
    })
    yield call(putLoteData, {
      lotesAtivos: lotesAtivos.count,
      lotesAtivosLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesAtivos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { lotesAtivosLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesDuracaoCiclos(): any {
  try {
    yield call(putLoteData, { duracaoCiclosLoading: true })

    const { oneYearAgoLotes } = store.getState().lote

    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)
    oneYearAgo.setDate(1)

    const duracaoCiclos = oneYearAgoLotes
      .filter(
        (lote): lote is LoteFechado =>
          !!(
            Date.parse(lote.dataNascimento) > oneYearAgo.getTime() &&
            // lote.alojamentos.length > 0 &&
            lote.fechamento
          )
      )
      .map((lote) => ({
        nroLote: lote.nroLote,
        mesAlojamento: new Date(lote.dataNascimento).getMonth(),
        tempoAlojado:
          (Date.parse(lote.fechamento.dataSaida) - Date.parse(lote.dataNascimento)) /
          (1000 * 60 * 60 * 24) // Divide o total pelo total de milisegundos correspondentes a 1 dia. (1000 milisegundos = 1 segundo)
      }))
    let ciclos = duracaoCiclos
      .reduce(
        (acc, lote) => {
          acc[lote.mesAlojamento].push(lote)
          return acc
        },
        [...Array(12)].map<DuracaoCiclo[]>(() => [])
      )
      .map((ciclos) =>
        ciclos.reduce(
          (acc, ciclo, idx, arr) => {
            return [(acc[0] += ciclo.tempoAlojado), arr.length]
          },
          [0, 0]
        )
      )
      .map(([acc, len]) => acc / len || 0)

    ciclos = ciclos.map((d) => Number(d.toFixed()))
    yield call(putLoteData, {
      duracaoCiclos: ciclos,
      duracaoCiclosLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesDuracaoCiclos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { duracaoCiclosLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesPorMes(): any {
  try {
    yield call(putLoteData, { lotesPorMesLoading: true })

    const { credentials } = store.getState().auth
    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    const { selectedIntegrador } = integradorStore

    const { quantidades } = yield call(getLotesQuantidadeMensal, credentials.token, {
      data: moment(oneYearAgo).set('date', 1).add(1, 'M').format('YYYY-MM-DD'),
      idIntegrador: selectedIntegrador?.id
    })
    yield call(putLoteData, {
      lotesPorMes: quantidades,
      lotesPorMesLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerGetLotesPorMes',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { lotesPorMesLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetLotesFechamentosMedias(): any {
  try {
    yield call(putLoteData, { lotesFechamentosMediasLoading: true })

    const { credentials } = store.getState().auth
    let { lotesFechamentosMedias } = store.getState().lote
    let medias

    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    const { selectedIntegrador } = integradorStore

    if (!lotesFechamentosMedias.mediaAbate && !lotesFechamentosMedias.mediaCiclo) {
      medias = yield call(
        getLotesFechamentos,
        { medias: true, idIntegrador: selectedIntegrador?.id },
        credentials.token
      )
    }

    lotesFechamentosMedias = {
      mediaCiclo: Number(medias?.mediaCiclo.toFixed(1)),
      mediaAbate: Number(medias?.mediaAbates.toFixed(3))
    }
    yield call(putLoteData, {
      lotesFechamentosMedias,
      lotesFechamentosMediasLoading: false
    })
  } catch (error: any) {
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/lotes/workerLotesFechamentosMedias',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putLoteData, { lotesFechamentosMediasLoading: false })
    yield call(putMainData, { message })
  }
}
