import { call, Effect, put, PutEffect, select } from 'redux-saga/effects'
import { getIntegradores } from '../../api/integrador'
import Exception from '../../helpers/Exception'
import _ from 'lodash'

// Methods
import { workerSignOut } from 'store/auth/sagas'

// types
import { Integrador, IntegradorConstants, IntegradorStore } from './types.d'
import { MainConstants } from '../main/types.d'
import store from '../index'
import Codes from 'helpers/Codes'
import { ParametrosPOConstants } from 'store/parametrosPO/types.d'

function* putIntegradorData(payload: any): Generator<
  PutEffect<{
    type: IntegradorConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: IntegradorConstants.REDUCER_SET_INTEGRADOR_DATA,
    payload
  })
}

function* putMainData(payload: any): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetIntegradores(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putIntegradorData, { integradorLoading: true })

    const { credentials } = store.getState().auth

    const integradores: Integrador[] = yield call(getIntegradores, params, credentials.token)

    yield call(putIntegradorData, {
      integradores,
      integradorLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/integrador/workerGetIntegradores',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putIntegradorData, { integradorLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedIntegrador(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putIntegradorData, { integradorLoading: true })
    const { credentials } = store.getState().auth
    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    let { integradores } = integradorStore
    if (!integradores.length) {
      integradores = yield call(getIntegradores, params, credentials.token)
    }
    const selectedIntegrador =
      _.find(integradores, (k: Integrador) => k.id === params.id) || integradores[0]
    if (!integradores.length || !selectedIntegrador) {
      throw new Exception(Codes.UserInteraction.INTEGRADOR_NOT_FOUND, 'workerGetSelectedIntegrador')
    }
    yield call(putIntegradorData, {
      integradores,
      selectedIntegrador,
      integradorLoading: false
    })
    yield put({
      type: ParametrosPOConstants.REDUCER_SET_PARAMETROSPO_DATA,
      payload: { selectedParametrosPO: undefined }
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/integrador/workerSetSelectedIntegrador',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putIntegradorData, { integradorLoading: false })
    yield call(putMainData, { message })
  }
}
