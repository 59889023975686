import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useRef, useState } from 'react'

// Types
import { ApplicationStore } from 'store/types'
import { Axis } from './types'
import { LineChartOptions } from 'components/charts/LineChart/types'

// Methods
import { useTranslation } from 'react-i18next'
import { getLotesProcessados } from 'store/dadosProcessados/actions'
import { getChartOptions, getDailyData } from './helpers'

// Components
import GranjaFilterCard from 'components/cards/GranjaFilterCard'
import AnaliseCrescimentoTable from 'components/tables/AnaliseCrescimentoTable'
import LineChart from 'components/charts/LineChart'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { DadosAnalise } from 'store/analiseCrescimento/types'
import _ from 'lodash'

const AnaliseCrescimento: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { lotesAtivosProcessados } = useSelector(
    (state: ApplicationStore) => state.dadosProcessados
  )
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const [weightDailyData, setWeightDailyData] = useState<Array<Axis>>()
  const [filteredWeightDailyData, setFilteredWeightDailyData] = useState<Array<Axis>>()
  const [weightChartOptions, setWeightChartOptions] = useState<LineChartOptions>()
  const [mortalityDailyData, setMortalityDailyData] = useState<Array<Axis>>()
  const [filteredMortalityDailyData, setFilteredMortalityDailyData] = useState<Array<Axis>>()
  const [mortalityChartOptions, setMortalityChartOptions] = useState<LineChartOptions>()
  const [foodConversionDailyData, setFoodConversionDailyData] = useState<Array<Axis>>()
  const [filteredFoodConversionDailyData, setFilteredFoodConversionDailyData] =
    useState<Array<Axis>>()
  const [foodConversionChartOptions, setFoodConversionChartOptions] = useState<LineChartOptions>()
  const [consumptionDailyData, setConsumptionDailyData] = useState<Array<Axis>>()
  const [filteredConsumptionDailyData, setFilteredConsumptionDailyData] = useState<Array<Axis>>()
  const [consumptionChartOptions, setConsumptionChartOptions] = useState<LineChartOptions>()
  const [filteredGalpoes, setFilteredGalpoes] = useState<any>([])
  const { analiseCrescimentoLoading } = useSelector(
    (state: ApplicationStore) => state.analiseCrescimento
  )
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedIntegratorId = usePrevious(selectedIntegrador?.id)

  useEffect(() => {
    if (!lotesAtivosProcessados || prevSelectedIntegratorId !== selectedIntegrador?.id) {
      dispatch(getLotesProcessados({ idIntegrador: selectedIntegrador?.id }))

      setWeightDailyData(undefined)
      setWeightChartOptions(undefined)
      setMortalityDailyData(undefined)
      setMortalityChartOptions(undefined)
      setFoodConversionDailyData(undefined)
      setFoodConversionChartOptions(undefined)
      setConsumptionDailyData(undefined)
      setConsumptionChartOptions(undefined)
      setFilteredGalpoes([])
    }
    if (lotesAtivosProcessados) {
      if (lotesAtivosProcessados?.galpao && !weightDailyData) {
        const weightChartData = getDailyData(
          lotesAtivosProcessados?.galpao[0]?.pesoDiario,
          theme,
          'valor'
        )
        if (weightChartData?.axisValues) setWeightDailyData(weightChartData?.axisValues)
        const weightChartOptions = getChartOptions(
          // weightChartData?.colors,
          t('weighings'),
          'weight',
          weightChartData.categories,
          weightChartData.discretes,
          [colors(theme, Colors.redDark)],
          t('weight') + ', ' + t('unity:kg'),
          t('day')
        )
        setWeightChartOptions(weightChartOptions)
      }
      if (lotesAtivosProcessados?.galpao && !mortalityDailyData) {
        const mortalityChartData = getDailyData(
          lotesAtivosProcessados?.galpao[0]?.mortalidadeDiaria,
          theme,
          'mortes'
        )
        if (mortalityChartData?.axisValues) setMortalityDailyData(mortalityChartData?.axisValues)
        const mortalityChartOptions = getChartOptions(
          // mortalityChartData?.colors,
          t('mortality'),
          'mortality',
          mortalityChartData.categories,
          mortalityChartData.discretes,
          [colors(theme, Colors.redDark)],
          t('mortality') + ', ' + t('unity:animals_number'),
          t('day')
        )
        setMortalityChartOptions(mortalityChartOptions)
      }
      if (lotesAtivosProcessados?.galpao && !foodConversionDailyData) {
        const foodConversionChartData = getDailyData(
          lotesAtivosProcessados?.galpao[0]?.conversaoAlimentarDiaria,
          theme,
          'conversaoalimentar'
        )
        if (foodConversionChartData?.axisValues)
          setFoodConversionDailyData(foodConversionChartData?.axisValues)
        const foodConversionChartOptions = getChartOptions(
          t('food_conversion'),
          'foodConversion',
          // foodConversionChartData?.colors,
          foodConversionChartData.categories,
          foodConversionChartData.discretes,
          [colors(theme, Colors.redDark)],
          t('CA') +
            ', ' +
            t('consumption') +
            ' ' +
            t('unity:kg') +
            ' ' +
            '/' +
            t('weight') +
            ' ' +
            t('unity:kg') +
            ' ',
          t('day')
        )
        setFoodConversionChartOptions(foodConversionChartOptions)
      }
      if (lotesAtivosProcessados?.galpao && !consumptionDailyData) {
        const consumptionChartData = getDailyData(
          lotesAtivosProcessados?.galpao[0]?.consumoDiario,
          theme,
          'consumo'
        )
        if (consumptionChartData?.axisValues)
          setConsumptionDailyData(consumptionChartData?.axisValues)
        const consumptionChartOptions = getChartOptions(
          // consumptionChartData?.colors,
          t('consumption'),
          'consumo',
          consumptionChartData.categories,
          consumptionChartData.discretes,
          [colors(theme, Colors.redDark)],
          t('consumption') + ', ' + t('unity:kg'),
          t('day')
        )
        setConsumptionChartOptions(consumptionChartOptions)
      }
    }
  }, [lotesAtivosProcessados, selectedIntegrador])

  const setLots = (dadosAnalise: Array<DadosAnalise>) => {
    if (dadosAnalise) {
      let galpoes = dadosAnalise.map((d) => d.idGalpao)
      galpoes = galpoes.filter((l) => l !== undefined)
      if (galpoes) setFilteredGalpoes(galpoes)
    }
  }

  useEffect(() => {
    if (filteredGalpoes.length) {
      if (weightDailyData?.length)
        setFilteredWeightDailyData(
          _.chain(weightDailyData)
            .keyBy('name')
            .at(filteredGalpoes)
            .value()
            .filter((d) => d !== undefined)
        )
      if (mortalityDailyData?.length)
        setFilteredMortalityDailyData(
          _.chain(mortalityDailyData)
            .keyBy('name')
            .at(filteredGalpoes)
            .value()
            .filter((d) => d !== undefined)
        )
      if (foodConversionDailyData?.length)
        setFilteredFoodConversionDailyData(
          _.chain(foodConversionDailyData)
            .keyBy('name')
            .at(filteredGalpoes)
            .value()
            .filter((d) => d !== undefined)
        )
      if (consumptionDailyData?.length)
        setFilteredConsumptionDailyData(
          _.chain(consumptionDailyData)
            .keyBy('name')
            .at(filteredGalpoes)
            .value()
            .filter((d) => d !== undefined)
        )
    }
  }, [filteredGalpoes])

  return (
    <div className='analise-crescimento-container'>
      {<h1>{t('active_lots')}</h1>}
      <GranjaFilterCard active={true} withActiveLots={true} />
      <AnaliseCrescimentoTable setLots={setLots} />
      {!filteredWeightDailyData?.length &&
      !filteredMortalityDailyData?.length &&
      !filteredFoodConversionDailyData?.length &&
      !filteredConsumptionDailyData?.length &&
      !analiseCrescimentoLoading ? (
        <h2 className='txt'>{t('no_chart_data_for_this_unity')}</h2>
      ) : (
        <>
          <div className='charts'>
            <LineChart values={filteredWeightDailyData} chartOptions={weightChartOptions} />
            <LineChart values={filteredMortalityDailyData} chartOptions={mortalityChartOptions} />
          </div>
          <div className='charts'>
            <LineChart
              values={filteredFoodConversionDailyData}
              chartOptions={foodConversionChartOptions}
            />
            <LineChart
              values={filteredConsumptionDailyData}
              chartOptions={consumptionChartOptions}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default AnaliseCrescimento
