import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import * as Yup from 'yup'

// Components
import { Form } from '@unform/web'
import Input from 'components/widgets/Input'

// Types
import { Props } from './types.d'
import { useSelector } from 'react-redux'
import { ApplicationStore } from 'store/types'
import _ from 'lodash'
import { Parametros } from 'store/parametrosPO/types'

const ParametersForm: React.FC<Props> = ({ parameters, saveData }) => {
  const formRef: any = useRef(null)
  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)
  const [newParametros, setNewParametros] = useState<Parametros>()

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedIntegratorId = usePrevious(selectedIntegrador?.id)

  useEffect(() => {
    if (
      (!parameters ||
        (prevSelectedIntegratorId !== selectedIntegrador?.id &&
          prevSelectedIntegratorId !== undefined)) &&
      selectedIntegrador?.id
    )
      formRef?.current?.reset()
  }, [parameters, selectedIntegrador])

  useEffect(() => {
    if (parameters) {
      formRef.current.setData(parameters)
      setNewParametros(parameters)
    }
  }, [parameters])

  const verifyData = async (data: Parametros) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        abateMinDiario: Yup.number()
          .integer(t('components:formFields:insert_integer'))
          .min(0, t('components:formFields:must_be_greatter_than_0'))
          .typeError(t('components:formFields:invalid_number'))
          .required(() => t('components:formFields:required')),
        abateMaxDiario: Yup.number()
          .integer(t('components:formFields:insert_integer'))
          .min(0, t('components:formFields:must_be_greatter_than_0'))
          .typeError(t('components:formFields:invalid_number'))
          .required(() => t('components:formFields:required')),
        proporcaoMinMachosFemeas: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .max(1, t('components:formFields:must_be_less_than_1'))
          .required(() => t('components:formFields:required')),
        proporcaoMaxMachosFemeas: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .max(1, t('components:formFields:must_be_less_than_1'))
          .required(() => t('components:formFields:required')),
        mediaMinIdadeCiclo: Yup.number()
          .integer(t('components:formFields:insert_integer'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .typeError(t('components:formFields:invalid_number'))
          .required(() => t('components:formFields:required')),
        mediaMaxIdadeCiclo: Yup.number()
          .integer(t('components:formFields:insert_integer'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .typeError(t('components:formFields:invalid_number'))
          .required(() => t('components:formFields:required'))
      })
      const newData = { ...newParametros }
      if (newParametros) {
        newData[data.target.id] =
          data.target.value === '' || undefined
            ? null
            : isNaN(data.target.value)
            ? data.target.value
            : Number(data.target.value)
        // data.target.value
        setNewParametros(newData)
        await schema.validate(newData, { abortEarly: false })
        saveData(newData)
      }
    } catch (err) {
      console.log(err)
      console.log(formRef)
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: any) => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  return (
    <Form
      className={'parametrosPOForm'}
      ref={formRef}
      onChange={(data) => verifyData(data)}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onSubmit={() => {}}
    >
      <Input
        name='abateMinDiario'
        label={t('components:formFields:abate_min_diario')}
        placeholder={t('unity:animals/day')}
        onFocus={clearError}
      />
      <Input
        name='abateMaxDiario'
        label={t('components:formFields:abate_max_diario')}
        placeholder={t('unity:animals/day')}
        onFocus={clearError}
      />
      <Input
        name='proporcaoMinMachosFemeas'
        label={t('components:formFields:proporcao_min_machos_femeas')}
        placeholder={'000'}
        onFocus={clearError}
      />
      <Input
        name='proporcaoMaxMachosFemeas'
        label={t('components:formFields:proporcao_max_machos_femeas')}
        placeholder={'000'}
        onFocus={clearError}
      />
      <Input
        name='mediaMinIdadeCiclo'
        label={t('components:formFields:media_min_idade_ciclo') + ', ' + t('days').toLowerCase()}
        placeholder={'000'}
        onFocus={clearError}
      />
      <Input
        name='mediaMaxIdadeCiclo'
        label={t('components:formFields:media_max_idade_ciclo') + ', ' + t('days').toLowerCase()}
        placeholder={'000'}
        onFocus={clearError}
      />
    </Form>
  )
}

export default ParametersForm
