/**
 * Format value
 */
import { StorageKeys } from 'storage/types.d'
import { getItemFromStorage } from 'storage'
import { Locales } from 'store/main/types.d'
import moment from 'moment-timezone'

const getUnitySystem = () => {
  return getItemFromStorage(StorageKeys.Locale)
}

/**
 * @function
 * @param Date Value to be formatted
 * @return Date formatted
 */
export default (value: Date | string): string => {
  if (value === null || value === undefined) {
    return ''
  }

  value = moment.utc(value).format()
  const unitySystem = getUnitySystem()
  const res =
    unitySystem === Locales.en
      ? moment.utc(value).tz('UTC-2').format('MM/DD/YY')
      : moment.utc(value).tz('UTC-2').format('DD/MM/YY')

  return res
}
