import React from 'react'

// Types
import { Props } from './types.d'

// Resources
import './styles.scss'

// Components
import ReactLoading from 'react-loading'
import { Modal } from '@material-ui/core'

const Loading: React.FC<Props> = ({ size }: Props) => {
  return (
    // <Modal
    //   open={true}
    //   aria-labelledby='modal-modal-title'
    //   aria-describedby='modal-modal-description'
    // >
    <span className='loading'>
      <ReactLoading color='#990000' type='spin' width={size || 30} height={size || 30} />
    </span>
    // </Modal>
  )
}
export default Loading
