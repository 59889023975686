import { AuthenticationBody, UpdatePwdQueryBody } from 'api/auth/types.d'
import { AuthConstants } from './types.d'

export const signIn = (body: AuthenticationBody): Record<string, any> => ({
  type: AuthConstants.ACTION_SIGN_IN,
  payload: { body }
})

export const signOut = (): Record<string, any> => ({
  type: AuthConstants.ACTION_SIGN_OUT,
  payload: { logout: true }
})

export const checkAuthentication = (): Record<string, any> => ({
  type: AuthConstants.ACTION_CHECK_AUTHENTICATION,
  payload: {
    checkingFromRouter: true
  }
})

export const updatePassword = (data: UpdatePwdQueryBody): Record<string, any> => ({
  type: AuthConstants.ACTION_UPDATE_PASSWORD,
  payload: {
    data
  }
})

export const requestRecoveryEmail = (email: string) => ({
  type: AuthConstants.ACTION_REQUEST_CHANGE_PWD_EMAIL,
  payload: {
    email
  }
})
export const checkChangePwdToken = (token: string) => ({
  type: AuthConstants.ACTION_CHECK_CHANGE_PWD_TOKEN,
  payload: {
    token
  }
})
