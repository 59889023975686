export type GranjaStore = {
  granjaLoading: boolean
  galpaoLoading: boolean
  nucleoLoading: boolean
  granjas: Granjas[]
  granjasNucleos: GranjasNucleos[]
  selectedGranja?: Granja
  selectedGranjaId?: number
  selectedGranjasNucleos?: GranjaNucleo
  selectedGalpao?: Galpao
  galpoes: Galpao[]
  galpoesIelmLoading: boolean
  galpoesIelm: number | null
  tipoDadoPeso: WeighingDataType
  lotesAtivos: boolean
  averageWeighingData: boolean
  activeLotPesos: PesagemProcessada[]
  pesos: PesagemProcessada[]
  pesosLoading: boolean
  activeLotpesosLoading: boolean
}

export type Granja = {
  id: number
  idExtensionista: number
  idCriador: number
  municipio: number
  uf: string
  ativo: boolean
}

export type GranjasNucleos = {
  id: string
  numeroNucleo?: string
  idGranja: number
  distanciaAbatedouroITA: number
  distanciaAbatedouroNV: number
  sGraus: number
  sMinutos: number
  sSegundos: number
  wGraus: number
  wMinutos: number
  wSegundos: number
}

export type Galpao = {
  id: string
  idNucleo: string
  numeroGalpao: string
  tipo: string
  qtdExautores: number
  placaEvaporativa: string
  largura: number
  comprimento: number
  capacidadeSilo: number
  capacidadeAlojamento: number
  ativo: boolean
  marcaComedouro: string
}

export type GranjaPesagemProcessada = {
  clientId: string
  idGalpao: string
  tipoGalpao: string
  pesos: PesagemProcessada[]
}

export type PesagemProcessada = {
  id: number
  nroLote: Int
  dia: Int
  data: Date
  valor: number
  tipo: string
  tipoPeso: string
}

export enum GranjaConstants {
  ACTION_GET_GRANJAS = 'ACTION_GET_GRANJAS',
  ACTION_GET_GRANJAS_NUCLEOS = 'ACTION_GET_GRANJAS_NUCLEOS',
  ACTION_SET_SELECTED_GRANJA = 'ACTION_SET_SELECTED_GRANJA',
  ACTION_SET_SELECTED_GALPAO = 'ACTION_SET_SELECTED_GALPAO',
  ACTION_GET_GALPOES = 'ACTION_GET_GALPOES',
  ACTION_SET_SELECTED_GRANJA_NUCLEOS = 'ACTION_SET_SELECTED_GRANJA_NUCLEOS',
  ACTION_GET_GAPOES_IELM_GALPAO = 'ACTION_GET_GAPOES_IELM_GALPAO',
  REDUCER_CLEAR_GRANJA_DATA = 'REDUCER_CLEAR_GRANJA_DATA',
  REDUCER_SET_GRANJA_DATA = 'REDUCER_SET_GRANJA_DATA',
  ACTION_GET_PESOS = 'ACTION_GET_PESOS',
  ACTION_GET_ACTIVE_LOT_PESOS = 'ACTION_GET_ACTIVE_LOT_PESOS'
}
