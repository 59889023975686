import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationStore } from 'store/types.d'
import { TextField, Button, IconButton, InputAdornment, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Visibility, VisibilityOff } from '@material-ui/icons'

// Resources
import imgBgLight from '../../resources/img/poultrytrader-background-image-light.png'
import logoRegular from 'resources/img/svg/poultrytrader-logo-regular.svg'

// Types
import { Props } from './types.d'
import { Themes } from 'store/main/types.d'

// Methods
import { signIn } from 'store/auth/actions'

//Components
import LocaleSelector from 'components/shared/LocaleSelector'
import { NavLink } from 'react-router-dom'

const SignIn: React.FC<Props> = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isAuthenticating } = useSelector((state: ApplicationStore) => state.auth)
  const { theme, message } = useSelector((state: ApplicationStore) => state.main)
  const [isPasswordVisible, togglePasswordVisibility] = useState(false)
  const [email, handleEmail] = useState('')
  const [password, handlePassword] = useState('')
  const [emailError, handleEmailError] = useState('')
  const [passwordError, handlePasswordError] = useState('')

  const validate = (): boolean => {
    let success = true
    if (email.trim().length < 4) {
      success = false
      handleEmailError('auth:email_too_short')
    }
    if (email.trim().indexOf('@') < 0 || email.trim().indexOf('.') < 0) {
      success = false
      handleEmailError('auth:email_invalid')
    }
    if (password.length < 4) {
      success = false
      handlePasswordError('auth:password_too_short')
    }

    return success
  }

  const submit = () => {
    if (!validate()) return
    dispatch(signIn({ email, password }))
  }

  const handlePasswordKeyPress = (event: any) => {
    if (event?.key === 'Enter') {
      submit()
    }
  }

  return (
    <div id={'signin'} className={'auth-container'}>
      <div className={'auth-picture-container'}>
        <img src={theme === Themes.LIGHT ? imgBgLight : imgBgLight} className={'auth-picture'} />
      </div>
      <aside className='auth-panel'>
        <div className='auth-settings-row'>
          {/* <ThemeToggle /> */}
          <LocaleSelector />
        </div>
        <div className='auth-form-container'>
          <div className='auth-paper'>
            <img src={theme === Themes.LIGHT ? logoRegular : logoRegular} className={'auth-logo'} />
            <div className='auth-form'>
              <text className={'auth-error'}>{t(message?.text)}</text>
            </div>
            <div className='auth-form'>
              <div className='auth-row'>
                <TextField
                  id='email'
                  disabled={isAuthenticating}
                  label={t('email')}
                  value={email}
                  variant='outlined'
                  fullWidth
                  helperText={t(emailError)}
                  error={emailError !== ''}
                  onChange={(e: any) => {
                    handleEmail(e.target.value)
                    handleEmailError('')
                  }}
                />
              </div>
              <div className='auth-row'>
                <TextField
                  id='password'
                  disabled={isAuthenticating}
                  label={t('password')}
                  value={password}
                  type={isPasswordVisible ? 'text' : 'password'}
                  variant='outlined'
                  fullWidth
                  helperText={t(passwordError)}
                  error={passwordError !== ''}
                  onChange={(e: any) => {
                    handlePassword(e.target.value)
                    handlePasswordError('')
                  }}
                  onKeyPress={handlePasswordKeyPress}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          disabled={isAuthenticating}
                          aria-label='toggle password visibility'
                          onMouseDown={() => {
                            togglePasswordVisibility(true)
                          }}
                          onMouseUp={() => {
                            togglePasswordVisibility(false)
                          }}
                        >
                          {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className='auth-row'>
                <NavLink id='recover-password-link' to='/recover-password' className='auth-navlink'>
                  {t('auth:i_forgot_my_password')}
                </NavLink>
              </div>
            </div>
            <Button
              id={'submit'}
              color={isAuthenticating ? 'secondary' : 'primary'}
              variant='contained'
              fullWidth
              onClick={submit}
              disabled={isAuthenticating}
              endIcon={
                isAuthenticating ? (
                  <CircularProgress style={{ maxWidth: '1.5rem', maxHeight: '1.5rem' }} />
                ) : (
                  <div />
                )
              }
            >
              {t(isAuthenticating ? 'auth:signing_in' : 'auth:sign_in')}
            </Button>
          </div>
        </div>
      </aside>
    </div>
  )
}

export default SignIn
