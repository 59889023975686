import { call, Effect, put, PutEffect, select } from 'redux-saga/effects'
import { getGranjas, getGranjasNucleos, getPesagemProcessada } from '../../api/granja'
import Exception from '../../helpers/Exception'
import _ from 'lodash'

// Methods
import { workerSignOut } from 'store/auth/sagas'

// types
import { Galpao, Granja, GranjaConstants, GranjasNucleos, GranjaStore } from './types.d'
import { MainConstants } from '../main/types.d'
import store from '../index'
import Codes from 'helpers/Codes'
import { getGalpoes } from 'api/galpao'
import { putAnaliseCrescimentoData } from 'store/analiseCrescimento/sagas'
import { putLoteData } from 'store/lotes/sagas'
import { IntegradorStore } from 'store/integrador/types'

function* putGranjaData(payload: any): Generator<
  PutEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: GranjaConstants.REDUCER_SET_GRANJA_DATA,
    payload
  })
}

function* putMainData(payload: any): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetGranjas(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putGranjaData, { granjaLoading: true })

    const { credentials } = store.getState().auth

    let granjas: Granja[] = yield call(getGranjas, params, credentials.token)
    granjas = _.sortBy(granjas, ['id'])
    yield call(putGranjaData, {
      granjas,
      granjaLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/sagas/workerGetGranjas',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { granjaLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetGranjasNucleos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putGranjaData, { nucleoLoading: true })
    const { credentials } = store.getState().auth
    const granjaStore: GranjaStore = yield select((s) => s.granja)
    let { granjasNucleos } = granjaStore

    granjasNucleos = yield call(getGranjasNucleos, params, credentials.token)
    granjasNucleos = granjasNucleos.map((gn: GranjasNucleos) => {
      return {
        ...gn,
        numeroNucleo: gn.id?.slice(0, 2)
      }
    })

    if (!granjasNucleos) {
      throw new Exception(Codes.UserInteraction.NUCLEO_NOT_FOUND, 'workerSetSelectedNucleo')
    }
    yield call(putGranjaData, {
      granjasNucleos,
      nucleoLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/nucleos/sagas/workerGetGranjasNucleos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { nucleoLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetGalpoes(): any {
  try {
    yield call(putGranjaData, { galpaoLoading: true })

    const { credentials } = store.getState().auth

    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    const { selectedIntegrador } = integradorStore

    let galpoes: Galpao[] = yield call(
      getGalpoes,
      { idIntegrador: selectedIntegrador?.id },
      credentials.token
    )
    galpoes = _.sortBy(galpoes, ['id'])
    galpoes = galpoes.map((g) => ({
      ...g,
      numeroGalpao: '0' + g.numeroGalpao
    }))
    yield call(putGranjaData, {
      galpoes,
      galpaoLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/galpoes/workerGalpoes',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { galpaoLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedGranja(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putGranjaData, { granjaLoading: true })
    yield call(putGranjaData, {
      selectedGranjaId: params.id,
      granjaLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/workerSetSelectedGranja',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { granjaLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedGranjasNucleos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putGranjaData, { nucleoLoading: true })
    const { credentials } = store.getState().auth
    const granjaStore: GranjaStore = yield select((s) => s.granja)
    let { granjasNucleos } = granjaStore
    if (!granjasNucleos.length) {
      granjasNucleos = yield call(getGranjasNucleos, params, credentials.token)
    }
    const selectedGranjasNucleos =
      _.find(granjasNucleos, (k: GranjasNucleos) => k.id === params.id) || granjasNucleos[0]
    if (!granjasNucleos.length || !selectedGranjasNucleos) {
      throw new Exception(
        Codes.UserInteraction.GRANJA_NUCLEOS_NOT_FOUND,
        'workerSetSelectedGranjasNucleos'
      )
    }
    yield call(putAnaliseCrescimentoData, {
      dadosAnalise: undefined
    })
    yield call(putLoteData, {
      lotes: [],
      lotesPesos: undefined,
      selectedLote: undefined
    })
    yield call(putGranjaData, {
      galpoes: [],
      selectedGalpao: undefined,
      granjasNucleos,
      selectedGranjasNucleos,
      nucleoLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/workerSetSelectedGranjasNucleos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { nucleoLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedGalpao(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putGranjaData, { galpaoLoading: true })
    const { credentials } = store.getState().auth
    const granjaStore: GranjaStore = yield select((s) => s.granja)
    let { galpoes } = granjaStore
    if (!galpoes.length) {
      galpoes = yield call(getGalpoes, params, credentials.token)
    }
    const selectedGalpao = _.find(galpoes, (k: Galpao) => k.id === params.id) || galpoes[0]
    if (!galpoes.length || !selectedGalpao) {
      throw new Exception(Codes.UserInteraction.GALPAO_NOT_FOUND, 'workerSetSelectedGalpao')
    }
    yield call(putAnaliseCrescimentoData, {
      dadosAnalise: undefined
    })
    yield call(putLoteData, {
      lotes: [],
      lotesPesos: undefined,
      selectedLote: undefined
    })
    yield call(putGranjaData, {
      galpoes,
      selectedGalpao,
      galpaoLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/workerSetSelectedGalpao',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { galpaoLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetGalpoesIelm(): any {
  try {
    yield call(putGranjaData, { galpoesIelmLoading: true })

    const { credentials } = store.getState().auth
    const GranjaStore: GranjaStore = yield select((s) => s.granja)
    const { galpoesIelm } = GranjaStore
    let ielm

    const integradorStore: IntegradorStore = yield select((s) => s.integrador)
    const { selectedIntegrador } = integradorStore

    if (!galpoesIelm) {
      ielm = yield call(
        getGalpoes,
        { ielm: true, idIntegrador: selectedIntegrador?.id },
        credentials.token
      )
    }
    yield call(putGranjaData, {
      galpoesIelm: ielm.ielm?.toFixed(1),
      galpoesIelmLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/workerGalpoesIelm',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { galpoesIelmLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetPesos(action: Effect): any {
  try {
    yield call(putGranjaData, { pesosLoading: true })
    const { credentials } = store.getState().auth

    const { idGranja, media, lotesAtivos, inicio, fim, idIntegrador } = action.payload

    const params = {
      idGranja,
      media,
      lotesAtivos,
      inicio,
      fim,
      idIntegrador
    }

    const res = yield call(getPesagemProcessada, params, credentials.token)

    yield call(putGranjaData, {
      lotesAtivos: lotesAtivos,
      pesos: [...res],
      pesosLoading: false
    })
    return
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/pesagem/balanca/processada/workerGetPesos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { pesosLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetActiveLotPesos(action: Effect): any {
  try {
    yield call(putGranjaData, { activeLotpesosLoading: true })
    const { credentials } = store.getState().auth

    const { idGranja, media, inicio, fim, idIntegrador } = action.payload
    const params = {
      idGranja,
      media,
      lotesAtivos: true,
      inicio,
      fim,
      idIntegrador
    }

    const res = yield call(getPesagemProcessada, params, credentials.token)

    yield call(putGranjaData, {
      activeLotPesos: [...res],
      activeLotpesosLoading: false
    })
    return
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/granjas/workerGetActiveLotPesos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putGranjaData, { pesosLoading: false })
    yield call(putMainData, { message })
  }
}
