import moment from 'moment'

// Types
import { Weighing, DateType } from './types.d'
import { Themes } from 'store/main/types'

// Resources
import { CsvBuilder } from 'filefy'
import Format from 'helpers/Format'

export const getWeighingsPeriod = (
  startDate: Date,
  endDate: Date,
  startDateOffset?: number
): Record<string, any> => {
  const _startDate = new Date(startDate)
  _startDate.setDate(_startDate.getDate() - (startDateOffset ?? 0))
  const _endDate = new Date(endDate)
  _endDate.setDate(_endDate.getDate() - (startDateOffset ?? 0))

  return {
    start: _startDate.toISOString().substr(0, 10),
    end: _endDate.toISOString().substr(0, 10)
  }
}

export const getGranjaFaixaDias: (
  lotInitDate: string,
  weighingsPeriod: Record<string, any>
) => [number, number] | [] = (lotInitDate: any, weighingsPeriod: any) => {
  if (!lotInitDate || !weighingsPeriod.start) {
    return []
  }

  const _lotInitDate = new Date(lotInitDate)
  const _initDate = new Date(weighingsPeriod.start)
  const _endDate = new Date(weighingsPeriod.end)

  const getDayNumber = (date: Date) => {
    const dateDiff = Number(date) - Number(_lotInitDate)
    const daysDiff = Math.ceil(Math.abs(dateDiff) / (1000 * 60 * 60 * 24)) + 1
    return dateDiff < 0 ? daysDiff * -1 : daysDiff
  }

  return [getDayNumber(_initDate), getDayNumber(_endDate)]
}

export async function getGranjaPesos(
  setGranjaPesos: any,
  setGranjaPesosFiltered: any,
  granjaPesos: any,
  pesosPeriod: Record<string, any>,
  selectedGranjaId?: number
) {
  if (!selectedGranjaId) {
    setGranjaPesos([])
    setGranjaPesosFiltered([])
    return
  }

  if (!pesosPeriod.start || !pesosPeriod.end) {
    setGranjaPesos([])
    setGranjaPesosFiltered([])
    return
  }

  const aux: Weighing[] = []
  granjaPesos.map((p: Weighing) => {
    if (p.date && p?.date >= pesosPeriod.start && p?.date <= pesosPeriod.end) {
      const row = {
        aviaryId: p.aviaryId,
        lotNumber: p.lotNumber,
        age: p.age,
        weightType: p.weightType,
        date: moment(p.date).format('DD-MM-YYYY'),
        weight: Format(p.weight),
        scaleSector: p.scaleSector,
        type: p.type ?? '',
        color: getCellColor(p?.type)
      }
      aux.push(row)
    }
  })
  setGranjaPesosFiltered(aux)
}

export const handleDateChange = (
  dateType: string,
  event: React.ChangeEvent<{ value: string }>,
  weighingsPeriod: Record<string, any>,
  setWeighingsPeriod: any
) => {
  try {
    const newDate = event.target.value
    if (dateType === DateType.start) {
      if (newDate !== weighingsPeriod.start)
        setWeighingsPeriod(getWeighingsPeriod(new Date(newDate), new Date(weighingsPeriod.end)))
    } else if (dateType === DateType.end) {
      if (newDate !== weighingsPeriod.end)
        setWeighingsPeriod(getWeighingsPeriod(new Date(weighingsPeriod.start), new Date(newDate)))
    }
  } catch (error: any) {
    console.log(error?.message)
  }
}

export const getLotStartUTCDate = (lotInitDate: string) => {
  try {
    const date = new Date(lotInitDate)
    const dateUTC = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
    return dateUTC.toLocaleDateString()
  } catch (error: any) {
    return ''
  }
}

export const getTableColumnName = (
  columnNumber: number,
  granjaFaixaDias: [number, number] | [],
  lotInitDate: string
) => {
  let [_startDay] = granjaFaixaDias
  _startDay = _startDay ?? 1
  const weightDay = (_startDay > 0 ? _startDay : 1) + columnNumber
  return weightDay && lotInitDate
    ? String(weightDay) +
        ' (' +
        moment(lotInitDate)
          .add(weightDay - 1, 'd')
          .format('MM/DD/YYYY') +
        ')'
    : String(columnNumber)
}

// const getWeighingsToExport = (weighings: Weighing[]) => {
//   return weighings.map((w) => {
//     const getWeighing = (day: number) => {
//       return w.weighings ? w[day]?.weight : ''
//     }

//     const result: [string | number] = [w.aviaryId]
//     for (let index = 0; index <= 6; index++) {
//       result.push(getWeighing(index))
//     }
//     return result
//   })
// }

// export const exportCsv = (allColumns: any, allData: Weighing[]) => {
//   const columns = allColumns.filter((columnDef: any) => columnDef['export'] !== false)

//   new CsvBuilder('export.csv')
//     .setDelimeter(';')
//     .setColumns(columns.map((columnDef: any) => columnDef.title.props?.children ?? columnDef.title))
//     .addRows(getWeighingsToExport(allData))
//     .exportFile()
// }

export const getCellColor = (type: string | undefined): string => {
  switch (type) {
    // case 'r':
    //   return colors(theme, Colors.redDark)
    // case 'i':
    //   return colors(theme, Colors.gray)
    // case 'p':
    //   return colors(theme, Colors.accent)
    // default:
    //   return colors(theme, Colors.white)
    case 'r':
      return '922700'
    case 'i':
      return '939597'
    case 'p':
      return 'FF6400'
    default:
      return '000'
  }
}

export const cellStyle = (rowData: any) => {
  return {
    color: rowData ? '#' + rowData?.color : '#000'
  }
}
