import React from 'react'
import { connect } from 'react-redux'
import { useLocation, withRouter } from 'react-router-dom'
import { bindActionCreators, Dispatch } from 'redux'

// Resources
import './styles.scss'
import { ExitToApp } from '@material-ui/icons'
import { Settings } from '@material-ui/icons'

// Types
import { Props } from './types.d'
import { ApplicationStore } from 'store/types.d'
import { PopMenuItems } from 'components/ProfileButton/types'

// Components
import LocaleSelector from 'components/shared/LocaleSelector'
import ProfileButton from 'components/ProfileButton'
import { AppBar } from '@material-ui/core'
import Hamburger from 'components/shared/Hamburger'
import UserSettingsForm from 'components/Forms/UserSettingsForm'

// Methods
import { signOut } from 'store/auth/actions'
import { toggleDrawer } from 'store/main/actions'
import IntegradorSwitcher from 'components/switchers/IntegradorSwitcher'

const HeaderBar = (props: Props) => {
  const location = useLocation()
  const [open, setOpen] = React.useState(false)
  const items: PopMenuItems[] = [
    {
      title: 'settings',
      icon: <Settings color={'primary'} />,
      onClick: () => {
        setOpen(true)
      }
    },
    {
      title: 'auth:sign_out',
      icon: <ExitToApp color={'primary'} />,
      onClick: () => {
        props.signOut()
      }
    }
  ]
  // const { isDrawerOpen } = useSelector((state: ApplicationStore) => state.main)
  // const handleClick = () => {
  //   props.toggleDrawer(!isDrawerOpen)
  // }

  const closeSettingsForm = () => {
    setOpen(false)
  }

  return (
    <>
      <UserSettingsForm open={open} handleClose={closeSettingsForm} />
      <div className={'headerbar'}>
        <AppBar position='fixed' color={'primary'}>
          <div className='headerbar-content'>
            <Hamburger />
            {location.pathname !== '/planejamentoSemanalV2' ? (
              <IntegradorSwitcher />
            ) : (
              <div className='emptyComponent'></div>
            )}
            <LocaleSelector />
            {/* <ThemeToggle
            lightColor={colors(Themes.LIGHT, Colors.white)}
            darkColor={colors(Themes.LIGHT, Colors.white)}
          /> */}
            <ProfileButton items={items} />
          </div>
        </AppBar>
      </div>
    </>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      signOut,
      toggleDrawer
    },
    dispatch
  )

export default withRouter(connect(mapStoreToProps, mapDispatchToProps)(HeaderBar))
