import React from 'react'
import { Props } from './types'

const PlanejamentoIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='planejamento'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 40 40'
    >
      <path
        id='Caminho_662'
        data-name='Caminho 662'
        d='M40.821,5.107H36.536V2.25H33.679V5.107H10.821V2.25H7.964V5.107H3.679L2.25,6.536V40.821L3.679,42.25H40.821l1.429-1.429V6.536ZM39.393,39.393H5.107V13.679H39.393Zm0-28.571H5.107V7.964H39.393ZM10.821,22.25H7.964v2.857h2.857ZM7.964,27.964h2.857v2.857H7.964Zm2.857,5.714H7.964v2.857h2.857ZM16.536,22.25h2.857v2.857H16.536Zm2.857,5.714H16.536v2.857h2.857Zm-2.857,5.714h2.857v2.857H16.536Zm2.857-17.143H16.536v2.857h2.857Zm5.714,5.714h2.857v2.857H25.107Zm2.857,5.714H25.107v2.857h2.857Zm-2.857,5.714h2.857v2.857H25.107Zm2.857-17.143H25.107v2.857h2.857Zm5.714,5.714h2.857v2.857H33.679Zm2.857,5.714H33.679v2.857h2.857ZM33.679,16.536h2.857v2.857H33.679Z'
        transform='translate(-2.25 -2.25)'
        fill={fill}
        fillRule='evenodd'
      />
    </svg>
  )
}

export default PlanejamentoIcon
