import React from 'react'
import { useTranslation } from 'react-i18next'
import MaterialTable, { MaterialTableProps } from 'material-table'

const notNullValidate = (fieldName: string): ((rowData: any) => boolean) => {
  return (rowData: any) => rowData[fieldName] !== ''
}

const CustomMaterialTable: React.FC<MaterialTableProps<any>> = (props: MaterialTableProps<any>) => {
  const { t } = useTranslation()
  return (
    <MaterialTable
      localization={{
        pagination: {
          labelRowsSelect: t('components:material_table:lines'),
          firstTooltip: t('components:material_table:first_page'),
          lastTooltip: t('components:material_table:last_page'),
          nextTooltip: t('components:material_table:next_page'),
          previousTooltip: t('components:material_table:previous_page'),
          labelDisplayedRows: t('components:material_table:label_displayed_rows')
        },
        body: {
          editRow: { deleteText: t('components:material_table:delete_row_message') },
          emptyDataSourceMessage: t('no_data')
        }
      }}
      {...props}
    />
  )
}
export default CustomMaterialTable
export { notNullValidate }
