/**
 * Format value
 */
import { StorageKeys } from 'storage/types.d'
import { getItemFromStorage } from 'storage'
import { Locales } from 'store/main/types.d'

const getLocale = () => {
  return getItemFromStorage(StorageKeys.Locale)
}

/**
 * @function
 * @param number Value to be formatted
 * @return number formatted
 */
export default (number: number | string | undefined): string => {
  if (number === null || number === undefined) {
    return ''
  }

  const locale = getLocale()

  const rSign = locale === Locales.en ? '.' : ','
  const wSign = locale === Locales.en ? ',' : '.'

  let res = number.toString()
  const index = res.indexOf('.')
  if (index !== -1) res = res.substr(0, index) + rSign + res.substr(index + 1, res.length)

  res = res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, wSign)

  return res
}
