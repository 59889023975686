import { Themes } from 'store/main/types.d'

export type Color = {
  light: string
  dark: string
}

export enum Colors {
  accent = 'accent',
  accent70 = 'accent70',
  accent35 = 'accent35',
  accentDark = 'accentDark',
  background = 'background',
  backgroundCard = 'backgroundCard',
  ghostWhite = 'ghostWhite',
  grayMedium = 'grayMedium',
  gray = 'gray',
  gray70 = 'gray70',
  gray35 = 'gray35',
  lightOrange = 'lightOrange',
  veryPaleOrange = 'veryPaleOrange',
  redDark = 'redDark',
  shadow = 'shadow',
  shadowLight = 'shadowLight',
  yellow = 'yellow',
  yellowLightOpacity = 'yellowLightOpacity',
  white = 'white',
  accentPlusLight = 'accentPlusLight',
  accentLight = 'accentLight',
  black = 'black',
  error = 'error',
  foreground = 'foreground',
  grayDarkLight = 'grayDarkLight',
  grayDark = 'grayDark',
  grayLightMedium = 'grayLightMedium',
  grayLight = 'grayLight',
  blue = 'blue',
  blueLightOpacity = 'blueLightOpacity',
  help = 'help',
  info = 'info',
  warning = 'warning',
  success = 'success',
  infoLightOpacity = 'infoLightOpacity',
  warningLightOpacity = 'warningLightOpacity',
  successLightOpacity = 'successLightOpacity',
  errorLightOpacity = 'errorLightOpacity'
}

const colors: { [key: string]: Color } = {
  accent: {
    light: '#D1422A',
    dark: '#D1422A'
  },
  accent70: {
    light: 'rgba(209, 66, 42, 0.7)',
    dark: 'rgba(209, 66, 42, 0.35)'
  },
  accent35: {
    light: 'rgba(209, 66, 42, 0.35)',
    dark: 'rgba(209, 66, 42, 0.35)'
  },
  accentDark: {
    light: '#990000',
    dark: '#990000'
  },
  background: {
    light: '#f8f8f8',
    dark: '#f8f8f8'
  },
  backgroundCard: {
    light: '#f8f8f8',
    dark: '#f8f8f8'
  },
  ghostWhite: {
    light: '#f8f8f8',
    dark: '#f8f8f8'
  },
  gray: {
    light: '#939597',
    dark: '#939597'
  },
  gray70: {
    light: 'rgba(rgb(147,149,151, 0.70)',
    dark: 'rgba(rgb(147,149,151, 0.70)'
  },
  gray35: {
    light: 'rgba(rgb(147,149,151, 0.35)',
    dark: 'rgba(rgb(147,149,151, 0.35)'
  },
  grayMedium: {
    light: '#4d4d4d',
    dark: '#4d4d4d'
  },
  lightOrange: {
    light: '#ff924d',
    dark: '#ff924d'
  },
  veryPaleOrange: {
    light: '#ffeeeb',
    dark: '#ffeeeb'
  },
  redDark: {
    light: '#922700',
    dark: '#922700'
  },
  shadow: {
    light: 'rgba(0, 0, 0, 0.15)',
    dark: 'rgba(0, 0, 0, 0.15)'
  },
  shadowLight: {
    light: 'rgba(0, 0, 0, 0.1)',
    dark: 'rgba(0, 0, 0, 0.1)'
  },
  yellow: {
    light: '#F5DF4D',
    dark: '#F5DF4D'
  },
  yellowLightOpacity: {
    light: 'rgba(255, 255, 0, 0.4)',
    dark: 'rgba(255, 255, 0, 0.8)'
  },
  white: {
    light: '#fff',
    dark: '#fff'
  },

  accentPlusLight: {
    light: '#ef7343',
    dark: '#ef7343'
  },
  accentLight: {
    light: '#e8af30',
    dark: '#e8af30'
  },
  black: {
    light: '#000',
    dark: '#000'
  },
  blue: {
    light: 'rgb(40, 121, 172)',
    dark: 'rgb(40, 121, 172)'
  },
  blueLightOpacity: {
    light: 'rgba(40, 121, 172, 0.2)',
    dark: 'rgba(40, 121, 172, 0.2)'
  },
  error: {
    light: '#e00',
    dark: '#e00'
  },
  foreground: {
    light: '#4d4d4d',
    dark: '#fff'
  },
  grayLightMedium: {
    light: '#cbcbcb',
    dark: '#cbcbcb'
  },
  grayLight: {
    light: '#eee',
    dark: '#eee'
  },
  grayDarkLight: {
    light: '#8a8a8a',
    dark: '#8a8a8a'
  },
  grayDark: {
    light: '#4d4d4d',
    dark: '#4d4d4d'
  },
  help: {
    light: '#2CD1B5',
    dark: '#2CD1B5'
  },
  green: {
    light: 'green',
    dark: '#295e0a'
  },
  info: {
    light: 'rgba(19, 54, 142)',
    dark: 'rgba(19, 54, 142)'
  },
  warning: {
    light: 'rgba(245, 223, 77)',
    dark: 'rgba(245, 223, 77)'
  },
  success: {
    light: 'rgba(42, 148, 39)',
    dark: 'rgba(42, 148, 39)'
  },
  infoLightOpacity: {
    light: 'rgba(19, 54, 142, 0.2)',
    dark: 'rgba(19, 54, 142, 0.2)'
  },
  warningLightOpacity: {
    light: 'rgba(245, 223, 77, 0.2)',
    dark: 'rgba(245, 223, 77, 0.2)'
  },
  successLightOpacity: {
    light: 'rgba(42, 148, 39, 0.2)',
    dark: 'rgba(42, 148, 39, 0.2)'
  },
  errorLightOpacity: {
    light: 'rgba(222, 35, 41, 0.2)',
    dark: 'rgba(222, 35, 41, 0.2)'
  }
}

export default (theme: Themes, color: Colors): string =>
  theme === Themes.LIGHT ? colors[color].light : colors[color].dark
