import React from 'react'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { IconButton } from '@material-ui/core'
import { Brightness2, Brightness4 } from '@material-ui/icons'

// Types
import { Props } from './types.d'
import { Themes } from 'store/main/types.d'
import { ApplicationStore } from 'store/types.d'

// Methods
import { setTheme } from 'store/main/actions'
import colors, { Colors } from 'styles/variables/colors'

const ThemeToggle = (props: Props) => (
  <IconButton
    aria-label='delete'
    onClick={() => {
      const theme = props.theme === Themes.LIGHT ? Themes.DARK : Themes.LIGHT
      props.setTheme(theme)
    }}
  >
    {props.theme === Themes.LIGHT ? (
      <Brightness4 style={{ color: props.lightColor || colors(Themes.LIGHT, Colors.accent) }} />
    ) : (
      <Brightness2 style={{ color: props.darkColor || colors(Themes.LIGHT, Colors.white) }} />
    )}
  </IconButton>
)

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTheme
    },
    dispatch
  )

export default connect(mapStoreToProps, mapDispatchToProps)(ThemeToggle)
