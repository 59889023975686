import axiosInstance from '../config/axiosConfig'

export async function apiRequest<R = any>(
  url: string,
  token: string,
  params?: Record<string, unknown>
): Promise<R> {
  const { data } = await axiosInstance.get(url, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  })
  return data
}

export async function apiPatch<B = any>(
  url: string,
  token: string,
  body: B,
  params?: Record<string, unknown>
): Promise<any> {
  const { data } = await axiosInstance.patch(url, body, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  })
  return data
}

export async function apiPost<B = any>(
  url: string,
  token: string,
  body: B,
  params?: Record<string, unknown>
): Promise<any> {
  const { data } = await axiosInstance.post(url, body, {
    headers: {
      Authorization: `Bearer ${token}`
    },
    params
  })
  return data
}
