import { takeLatest, all, ForkEffect } from 'redux-saga/effects'
import {
  workerGetGranjasNucleos,
  workerGetGranjas,
  workerGetGalpoes,
  workerSetSelectedGranja,
  workerSetSelectedGranjasNucleos,
  workerSetSelectedGalpao,
  workerGetGalpoesIelm,
  workerGetPesos,
  workerGetActiveLotPesos
} from './sagas'
import { GranjaConstants } from './types.d'

export function* watcherGetGranjas(): Generator<
  ForkEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_GET_GRANJAS, workerGetGranjas)
}
export function* watcherSetSelectedGranja(): Generator<
  ForkEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_SET_SELECTED_GRANJA, workerSetSelectedGranja)
}
export function* watcherGetGranjasNucleos(): Generator<
  ForkEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_GET_GRANJAS_NUCLEOS, workerGetGranjasNucleos)
}
export function* watcherSetSelectedGranjasNucleos(): Generator<
  ForkEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    GranjaConstants.ACTION_SET_SELECTED_GRANJA_NUCLEOS,
    workerSetSelectedGranjasNucleos
  )
}
export function* watcherGetGalpoes(): Generator<
  ForkEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_GET_GALPOES, workerGetGalpoes)
}
export function* watcherSetSelectedGalpao(): Generator<
  ForkEffect<{
    type: GranjaConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_SET_SELECTED_GALPAO, workerSetSelectedGalpao)
}
export function* watcherGetGalpoesIelm(): Generator<
  ForkEffect<{
    type: GranjaConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_GET_GAPOES_IELM_GALPAO, workerGetGalpoesIelm)
}

export function* watcherGetPesos(): Generator<
  ForkEffect<{
    type: GranjaConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_GET_PESOS, workerGetPesos)
}

export function* watcherGetActiveLotPesos(): Generator<
  ForkEffect<{
    type: GranjaConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(GranjaConstants.ACTION_GET_ACTIVE_LOT_PESOS, workerGetActiveLotPesos)
}

export default function* granjaWatchers(): Generator<any, any, any> {
  yield all([
    watcherGetGranjas(),
    watcherSetSelectedGranja(),
    watcherGetGranjasNucleos(),
    watcherSetSelectedGranjasNucleos(),
    watcherGetGalpoes(),
    watcherSetSelectedGalpao(),
    watcherGetGalpoesIelm(),
    watcherGetPesos(),
    watcherGetActiveLotPesos()
  ])
}
