import produce from 'immer'
import { AnyAction } from 'redux'
import { DadosProcessadosStore, DadosProcessadosConstants } from './types.d'

const INITIAL_STORE: DadosProcessadosStore = {
  lotesEscaladosProcessadosLoading: false,
  lotesAtivosProcessadosLoading: false,
  lotesAtivosProcessados: undefined,
  lotesEscaladosProcessados: undefined
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case DadosProcessadosConstants.REDUCER_SET_DADOS_PROCESSADOS_DATA:
        Object.assign(draft, payload)
        break
      case DadosProcessadosConstants.REDUCER_CLEAR_DADOS_PROCESSADOS_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
