import produce from 'immer'
import { AnyAction } from 'redux'

import { AnaliseCrescimentoStore, AnaliseCrescimentoConstants } from './types.d'

const INITIAL_STORE: AnaliseCrescimentoStore = {
  dadosAnalise: undefined,
  pesos: [],
  analiseCrescimentoLoading: false,
  pesosLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case AnaliseCrescimentoConstants.REDUCER_SET_ANALISE_CRESCIMENTO_DATA:
        Object.assign(draft, payload)
        break
      case AnaliseCrescimentoConstants.REDUCER_CLEAR_ANALISE_CRESCIMENTO_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
