import { Discrete, LineChartOptions, TitleAlignment } from 'components/charts/LineChart/types.d'
import _ from 'lodash'
import { ChartData, Axis } from './types'
import { LoteAcompanhamentoDiario } from 'store/dadosProcessados/types'
import { Themes, DadoDiario } from 'store/main/types'
import colors, { Colors } from 'styles/variables/colors'

export const getDailyData = (
  data: Array<LoteAcompanhamentoDiario> | undefined,
  theme: Themes,
  yAxisValue: string
): ChartData => {
  const axisValues: any = []
  const discretes: Array<any> = []
  let categories: Array<number> = []
  let countKey = -1
  data?.map((m: LoteAcompanhamentoDiario) => {
    const keys = Object.keys(m)
    for (const key of keys) {
      if (Number(key)) {
        if (m[key].length) {
          m[key]?.map((dadoDiario: DadoDiario) => {
            // countKey++
            if (dadoDiario[yAxisValue]) {
              categories.push(Number(dadoDiario.dia))
            }
          })
        }
      }
    }
    categories = _.uniq(categories)
    categories = _.orderBy(categories)

    for (const key of keys) {
      if (Number(key)) {
        countKey++
        const chartData: Axis = { name: '', data: [] }
        chartData.name = Number(key)
        if (m[key].length) {
          m[key]?.map((dadoDiario: DadoDiario) => {
            if (dadoDiario[yAxisValue]) {
              chartData.data.push(
                yAxisValue === 'lucro'
                  ? Number(Number(dadoDiario[yAxisValue]).toFixed())
                  : Number(Number(dadoDiario[yAxisValue]).toFixed(3))
              )
            }
          })

          categories.map((c: number, index: number) => {
            let foundDay = false
            m[key]?.map((dadoDiario: DadoDiario) => {
              if (Number(dadoDiario.dia) === c) foundDay = true
            })
            if (!foundDay) {
              chartData.data.splice(index, 0, null)
            }
          })

          m[key]?.map((dadoDiario: DadoDiario) => {
            if (dadoDiario?.tipo === 'p') {
              const discrete: Discrete = {
                seriesIndex: countKey,
                dataPointIndex: categories.findIndex((dia) => dia === Number(dadoDiario.dia)),
                fillColor: colors(theme, Colors.white),
                strokeColor: colors(theme, Colors.white),
                size: 1,
                shape: 'circle'
              }

              discretes.push(discrete)
            }
          })
        }
        axisValues.push(chartData)
      }
    }
  })

  return {
    axisValues,
    categories,
    discretes
  }
}

export const getChartOptions = (
  title: string,
  id: string,
  categories?: Array<number>,
  discretes?: Array<Discrete>,
  colors?: Array<string>,
  yaxisTitle?: string,
  xAxisTitle?: string
): LineChartOptions => {
  const options: LineChartOptions = {
    chart: {
      id: id,
      height: 350,
      group: 'group',
      zoom: {
        enabled: true
      }
    },
    title: {
      text: title,
      align: TitleAlignment.CENTER
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: true
    },
    tooltip: {
      shared: false
    },
    grid: {
      show: true,
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.3
      }
    },
    yaxis: {
      title: {
        text: yaxisTitle || ''
      }
    },
    xaxis: {
      title: {
        text: xAxisTitle || ''
      },
      categories: categories || []
    },
    stroke: {
      width: 1,
      curve: 'straight'
    },
    colors: colors || [],
    markers: {
      size: 1,
      shape: 'circle',
      discrete: discretes ?? [],
      colors: colors,
      strokeColors: colors?.length ? colors[0] : '#922700'
    }
  }
  return options
}
