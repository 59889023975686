import React from 'react'
import { Props } from './types'

const HomeIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='home'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M10,20V14H14V20H19V12H22L12,3L2,12H5V20H10Z'
        transform='translate(-2.25 -2.25)'
      />
    </svg>
  )
}

export default HomeIcon
