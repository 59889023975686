import {
  LotePesosQueryParams,
  LoteQueryParams,
  LotesAcompanhamentos,
  LotesAlojamentosQueryParams,
  LotesFechamentosQueryParams
} from 'api/lote/types.d'
import { Lote, LoteConstants } from './types.d'

export const getLotes = (params?: LoteQueryParams): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES,
  payload: { params }
})

export const getLotesPesos = (params?: LotePesosQueryParams): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_PESOS,
  payload: { params }
})

export const getLotesAlojamentos = (params?: LotesAlojamentosQueryParams): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_ALOJAMENTOS,
  payload: { params }
})

export const getLotesAcompanhamentos = (params?: LotesAcompanhamentos): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_ACOMPANHAMENTOS,
  payload: { params }
})

export const getLotesFechamementos = (
  params?: LotesFechamentosQueryParams
): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_FECHAMENTOS,
  payload: { params }
})

export const setSelectedLoteAcompanhamento = (acompanhamento?: unknown): Record<string, any> => ({
  type: LoteConstants.ACTION_SET_SELECTED_LOTE_ACOMPANHAMENTO,
  payload: { acompanhamento }
})

export const setSelectedLote = (lote?: Lote): Record<string, any> => ({
  type: LoteConstants.ACTION_SET_SELECTED_LOTE,
  payload: { lote }
})

export const getOneYearAgoLotes = (idIntegrador?: string): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_ONE_YEAR_AGO_LOTES,
  payload: { idIntegrador }
})

export const getLotesDuracaoCiclos = (): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_DURACAO_CICLO
})

export const getLotesPorMes = (): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_POR_MES
})

export const getLotesFechamentosMedias = (): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_FECHAMENTOS_MEDIAS
})

export const getQuantLotesProcessados = (): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_PROCESSADOS
})

export const getLotesAtivos = (idIntegrador?: string): Record<string, any> => ({
  type: LoteConstants.ACTION_GET_LOTES_ATIVOS,
  payload: { idIntegrador }
})
