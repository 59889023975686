import { DadoDiario, MediaPorCategoria } from '../main/types'

export type DadosProcessadosStore = {
  lotesEscaladosProcessadosLoading: boolean
  lotesAtivosProcessadosLoading: boolean
  lotesAtivosProcessados?: LotesProcessados
  lotesEscaladosProcessados?: LotesProcessados
}

export type LotesProcessados = {
  mortalidadeMedia?: MediaPorCategoria
  pesoMedio?: MediaPorCategoria
  lucroMedio?: MediaPorCategoria
  galpao?: Array<GalpaoDadoDiario>
}

export type LoteAcompanhamentoDiario = {
  [key: string]: Array<DadoDiario>
}

export type GalpaoDadoDiario = {
  lucroDiario?: Array<LoteAcompanhamentoDiario>
  mortalidadeDiaria?: Array<LoteAcompanhamentoDiario>
  pesoDiario?: Array<LoteAcompanhamentoDiario>
  conversaoAlimentarDiaria?: Array<LoteAcompanhamentoDiario>
  consumoDiario?: Array<LoteAcompanhamentoDiario>
}

export enum DadosProcessadosConstants {
  ACTION_GET_DADOS_PROCESSADOSS = 'ACTION_GET_DADOS_PROCESSADOSS',
  ACTION_GET_LOTES_PROCESSADOS = 'ACTION_GET_LOTES_PROCESSADOS',
  REDUCER_CLEAR_DADOS_PROCESSADOS_DATA = 'REDUCER_CLEAR_DADOS_PROCESSADOS_DATA',
  REDUCER_SET_DADOS_PROCESSADOS_DATA = 'REDUCER_SET_DADOS_PROCESSADOS_DATA'
}
