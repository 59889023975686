import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

// Components
import { Button, TextField, Tooltip } from '@material-ui/core'
import CustomMaterialTable from 'components/tables/CustomMaterialTable'
import { MTableToolbar } from 'material-table'
import Loading from 'components/shared/Loading'
import Legend from 'components/shared/Legend'
import ReactExport from 'react-data-export'
import Icon from '@mdi/react'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { mdiFileDownload } from '@mdi/js'

// Types
import { Props, Weighing, DateType } from './types.d'
import { ApplicationStore } from 'store/types'
import { PesagemProcessada } from 'store/granjas/types'

// Methods
import './helpers'
import {
  cellStyle,
  getGranjaFaixaDias,
  getGranjaPesos,
  getWeighingsPeriod,
  handleDateChange
} from './helpers'
import { getLotesAlojamentos } from 'store/lotes/actions'
import { getPesos } from 'store/granjas/actions'

const AviaryWeighingTable = ({ activeLots, averageData }: Props) => {
  const { selectedGranjaId } = useSelector((state: ApplicationStore) => state.granja)

  const { pesos, pesosLoading } = useSelector((state: ApplicationStore) => state.granja)
  const { lotesAlojamentos } = useSelector((state: ApplicationStore) => state.lote)
  const [pesosPeriod, setWeighingsPeriod] = React.useState(
    getWeighingsPeriod(
      new Date(new Date().getFullYear(), 0, 1),
      new Date(new Date().getFullYear(), 11, 31)
    )
  )
  const [granjaInitDate, setGranjaStartDate] = React.useState<string | undefined>(undefined)

  const [granjaFaixaDias, setGranjaFaixaDias] = React.useState<[number, number] | []>([])
  const [granjasPesos, setGranjasPesos] = React.useState<Weighing[]>([])
  const [granjasPesosFiltered, setGranjasPesosFiltered] = React.useState<Weighing[]>([])
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const columns = [
    {
      title: t('aviary'),
      field: 'aviaryId',
      cellStyle: (value: any, rowData: any) => cellStyle(rowData)
    },
    {
      title: t('lot'),
      field: 'lotNumber',
      cellStyle: (value: any, rowData: any) => cellStyle(rowData)
    },
    {
      title: t('date'),
      field: 'date',
      sorting: false,
      cellStyle: (value: any, rowData: any) => cellStyle(rowData)
    },
    {
      title: t('scale_sector'),
      field: 'weightType',
      cellStyle: (value: any, rowData: any) => cellStyle(rowData)
    },
    {
      title: t('age') + ' (' + t('in_days') + ')',
      field: 'age',
      cellStyle: (value: any, rowData: any) => cellStyle(rowData)
    },
    {
      title: t('weight') + ' (g)',
      field: 'weight',
      sorting: false,
      cellStyle: (value: any, rowData: any) => cellStyle(rowData)
    }
  ]

  const [pesosFile, setWeighingsFile] = React.useState<any>([
    {
      columns: [],
      data: []
    }
  ])
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelSheet
  const exportTxt = t('export_to_xlsx')

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedGranjaId = usePrevious(selectedGranjaId)
  const prevActiveLots = usePrevious(activeLots)
  const prevAverageData = usePrevious(averageData)

  useEffect(() => {
    setGranjasPesosFiltered([])
  }, [activeLots, averageData])

  useEffect(() => {
    if (
      (selectedGranjaId && !lotesAlojamentos.length) ||
      prevSelectedGranjaId !== selectedGranjaId ||
      (selectedGranjaId && prevActiveLots !== activeLots)
    )
      dispatch(
        getLotesAlojamentos({
          idGranja: selectedGranjaId,
          lotesAtivos: activeLots,
          idIntegrador: selectedIntegrador?.id
        })
      )
  }, [selectedGranjaId, activeLots, selectedIntegrador])

  useEffect(() => {
    if (
      (selectedGranjaId && !pesos) ||
      prevSelectedGranjaId !== selectedGranjaId ||
      (selectedGranjaId && prevActiveLots !== activeLots) ||
      (selectedGranjaId && prevAverageData !== averageData)
    ) {
      setGranjasPesosFiltered([])
      dispatch(
        getPesos(
          selectedGranjaId,
          averageData,
          activeLots,
          new Date(new Date().getFullYear(), 0, 1),
          new Date(new Date().getFullYear(), 11, 31)
        )
      )
    }
  }, [selectedGranjaId, activeLots, averageData])

  useEffect(() => {
    if (lotesAlojamentos?.length) {
      const _granjaInitDate = _.minBy(lotesAlojamentos, 'dataAlojamento')
      if (_granjaInitDate) setGranjaStartDate(_granjaInitDate.dataAlojamento)
      getDaysRange(_granjaInitDate?.dataAlojamento)
    }
  }, [lotesAlojamentos])

  useEffect(() => {
    if (pesos?.length) loadingWeighing()
  }, [pesos])

  const loadingWeighing = () => {
    const aux: Weighing[] = []
    pesos.map((p: any) => {
      p.pesos?.map((pp: PesagemProcessada) => {
        const row = {
          aviaryId: p.idGalpao,
          lotNumber: pp.nroLote,
          age: pp.dia,
          date: pp.data,
          weight: pp.valor,
          type: pp.tipo ?? '',
          weightType: pp.tipoPeso,
          color: pp?.tipo
        }
        aux.push(row)
      })
    })
    setGranjasPesos(aux)
  }

  const getDaysRange = (initDate?: string) => {
    const [startDay, endDay] = getGranjaFaixaDias(
      initDate ?? granjaInitDate ?? pesosPeriod.start,
      pesosPeriod
    )

    if (startDay && endDay) {
      setGranjaFaixaDias([startDay, endDay])
    } else {
      setGranjaFaixaDias([])
    }
  }

  useEffect(() => {
    getDaysRange()
    const now = new Date()
    const currentYear = now.getFullYear()
    const startYear = new Date(pesosPeriod.start).getFullYear()

    if (selectedGranjaId && startYear < currentYear) {
      setGranjasPesosFiltered([])
      dispatch(
        getPesos(
          selectedGranjaId,
          averageData,
          activeLots,
          pesosPeriod.start,
          pesosPeriod.end,
          selectedIntegrador?.id
        )
      )
    }
  }, [pesosPeriod])

  useEffect(() => {
    if (granjasPesos.length && pesosPeriod) {
      getGranjaPesos(
        setGranjasPesos,
        setGranjasPesosFiltered,
        granjasPesos,
        pesosPeriod,
        selectedGranjaId
      )
    }
  }, [pesosPeriod, granjasPesos])

  useEffect(() => {
    const file: any = [
      {
        columns: [],
        data: []
      }
    ]

    columns.map((c: any) => {
      file[0].columns.push({ title: c.title, width: { wpx: 100 } })
    })

    granjasPesosFiltered.map((a: any) => {
      const granjaWeights: any = []
      granjaWeights.push(
        {
          value: a.aviaryId,
          style: { font: { color: { rgb: 'FF' + a.color } } }
        },
        {
          value: a.lotNumber,
          style: { font: { color: { rgb: 'FF' + a.color } } }
        },
        {
          value: a.date,
          style: { font: { color: { rgb: 'FF' + a.color } } }
        },
        {
          value: a.weightType,
          style: { font: { color: { rgb: 'FF' + a.color } } }
        },
        {
          value: a.age,
          style: { font: { color: { rgb: 'FF' + a.color } } }
        },
        {
          value: a.weight,
          style: { font: { color: { rgb: 'FF' + a.color } } }
        }
      )

      file[0].data.push(granjaWeights)
    })
    setWeighingsFile(file)
  }, [granjasPesosFiltered])
  return (
    <div className='aviary-weighing-body'>
      {pesosLoading ? (
        <Loading />
      ) : (
        <>
          <Legend />
          <CustomMaterialTable
            style={{ width: '100%' }}
            columns={columns}
            data={
              columns && granjasPesos.length && granjasPesosFiltered.length
                ? granjasPesosFiltered
                : []
            }
            options={{
              showTitle: false,
              pageSize: 20,
              searchFieldAlignment: 'right',
              sorting: true,
              filtering: true
              // exportButton: { csv: true },
              // exportAllData: false,
              // exportCsv
            }}
            title='Pesagens'
            components={{
              Toolbar: (props) => (
                <div>
                  <MTableToolbar {...props} />
                  <div className={'toolbar'}>
                    <TextField
                      id='initDate'
                      label={t('from')}
                      type='date'
                      defaultValue={pesosPeriod.start}
                      onMouseLeave={(event: any) =>
                        handleDateChange(DateType.start, event, pesosPeriod, setWeighingsPeriod)
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      className='input-margin'
                    />
                    <TextField
                      id='endDate'
                      label={t('to')}
                      type='date'
                      defaultValue={pesosPeriod.end}
                      onMouseLeave={(event: any) =>
                        handleDateChange(DateType.end, event, pesosPeriod, setWeighingsPeriod)
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      className='input-margin'
                    />
                    <ExcelFile
                      filename={t('weights') + ' ' + t('aviary') + ' ' + selectedGranjaId}
                      element={
                        <Tooltip title={exportTxt}>
                          <Button>
                            <Icon
                              path={mdiFileDownload}
                              size={1}
                              color={colors(theme, Colors.grayDark)}
                            />
                          </Button>
                        </Tooltip>
                      }
                    >
                      <ExcelSheet dataSet={pesosFile} name={t('aviary') + ' ' + selectedGranjaId} />
                    </ExcelFile>
                  </div>
                </div>
              )
            }}
          />
        </>
      )}
    </div>
  )
}

export default AviaryWeighingTable
