import { Typography } from '@material-ui/core'
import { GridApi, RowDragEndEvent, RowDropZoneParams } from 'ag-grid-community'
import { constants } from 'buffer'
import i18next, { t } from 'i18next'
import _, { Dictionary } from 'lodash'
import moment from 'moment'
import React, { ReactElement } from 'react'
import { IntegratorEnum } from 'store/integrador/types.d'
import { weekDay } from 'store/main/types.d'
import {
  MovementTypeEnum,
  OrigemDadoEnum,
  PlanejamentoSemanal,
  SummaryTableData,
  WeeklyPlanningTotals
} from 'store/planejamentoSemanal/types.d'

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

export const TabPanel = (props: TabPanelProps): ReactElement<any, any> => {
  const { children, value, index, ...other } = props
  return (
    <Typography
      style={{ width: '100%' }}
      className='typography'
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <div className='div-typography'>{children}</div>}
    </Typography>
  )
}

export const generatesTotals = (data: PlanejamentoSemanal[]): WeeklyPlanningTotals => {
  let averageWeight = 0
  let averageAge = 0
  let averageConsumption = 0

  data.map((fw: PlanejamentoSemanal) => {
    if (fw.pesoPredito) averageWeight += fw.pesoPredito * fw.animaisPreditos || 0
    if (fw.idadeSaida) averageAge += fw.idadeSaida * fw.animaisPreditos || 0
    if (fw.consumo) averageConsumption += fw.consumo * fw.animaisPreditos || 0
  })

  const predictedAnimalsTotal = _.sumBy(data, 'animaisPreditos')
  averageWeight = averageWeight / predictedAnimalsTotal
  averageAge = averageAge / predictedAnimalsTotal
  averageConsumption = averageConsumption / predictedAnimalsTotal
  const predictedProfit = _.sumBy(data, 'lucro')
  return {
    origemDado: OrigemDadoEnum.total,
    idIntegrador: t('average_and_totals'),
    pesoPredito: Number(averageWeight.toFixed(3)),
    idadeSaida: Number(averageAge.toFixed()),
    animaisPreditos: Number(predictedAnimalsTotal),
    consumo: Number(averageConsumption.toFixed(3)),
    lucro: Number(predictedProfit.toFixed(2))
  }
}

export const generateSummaryData = (data: PlanejamentoSemanal[]): SummaryTableData[] => {
  const summaryTableData: SummaryTableData[] = []

  for (const key in IntegratorEnum) {
    const dataFilteredByIntegrator = data?.filter(
      (d: PlanejamentoSemanal) => d.idIntegrador === key
    )
    if (dataFilteredByIntegrator?.length) {
      const summaryData = getWeekTotals(dataFilteredByIntegrator)
      summaryTableData.push(...summaryData)
    }
  }

  return summaryTableData
}

const getWeekTotals = (data: PlanejamentoSemanal[]) => {
  const summary: SummaryTableData[] = []
  let averageWeight = 0
  let averageAge = 0
  let averageConsumption = 0
  let idIntegrator = ''
  let count = 0

  const dataGrouppedByDay = _.groupBy(data, 'dataSaidaFormatada')

  for (const k in dataGrouppedByDay) {
    dataGrouppedByDay[k]?.map((fw: PlanejamentoSemanal) => {
      if (fw.pesoPredito && fw.animaisPreditos) averageWeight += fw.pesoPredito * fw.animaisPreditos
      if (fw.idadeSaida && fw.animaisPreditos) averageAge += fw.idadeSaida * fw.animaisPreditos
      if (fw.consumo && fw.animaisPreditos) averageConsumption += fw.consumo * fw.animaisPreditos
      idIntegrator = fw.idIntegrador
    })
    const predictedAnimalsTotal = _.sumBy(dataGrouppedByDay[k], 'animaisPreditos')
    const predictedProfit = _.sumBy(dataGrouppedByDay[k], 'lucro')
    averageWeight = averageWeight / predictedAnimalsTotal
    averageAge = averageAge / predictedAnimalsTotal
    averageConsumption = averageConsumption / predictedAnimalsTotal

    const weekdayNumber = moment(k).weekday()
    const weekday = weekDay[weekdayNumber]?.label
    const weekDayLabel = weekday ? i18next.t(weekday) : null

    summary.push({
      id: count++,
      idIntegrador: idIntegrator,
      data: k,
      diaSemana: weekDayLabel,
      pesoPredito: Number(averageWeight?.toFixed(3)),
      idadeSaida: Number(averageAge?.toFixed()),
      animaisPreditos: Number(predictedAnimalsTotal),
      consumo: Number(averageConsumption?.toFixed(3)),
      lucro: Number(predictedProfit?.toFixed(2))
    })
  }
  return summary
}

export const getDropZoneParams = (
  container: any,
  movementType: MovementTypeEnum,
  sourceApi: GridApi,
  dropApi: GridApi
): RowDropZoneParams => {
  const dropZone = {
    getContainer: () => {
      return container as any
    },
    onDragStop: (params: RowDragEndEvent) => {
      const data = { ...params.nodes[0].data }
      data.movementType = movementType

      const transaction = {
        add: [data]
      }

      dropApi.applyTransaction(transaction)

      const nodes = params.nodes

      if (sourceApi)
        sourceApi.applyTransaction({
          remove: nodes.map(function (node: any) {
            return node.data
          })
        })
    }
  }
  return dropZone
}

export const getFileData = (fileData?: PlanejamentoSemanal[]): Array<Record<string, any>> => {
  if (fileData) {
    const data: Array<Record<string, any>> = []
    fileData?.map((d: PlanejamentoSemanal) => {
      const fileLine = [
        { value: d.movementType || undefined },
        { value: d.idIntegradorDestino || undefined },
        { value: d.dataSaidaFormatada || undefined },
        { value: d.idGranja || undefined },
        { value: d.numeroNucleo || undefined },
        { value: d.numeroGalpao || undefined },
        { value: d.nroLote || undefined },
        { value: d.sexo || undefined },
        { value: d.uf || undefined },
        { value: d.municipio || undefined },
        { value: d.distanciaAbatedouroNV || undefined },
        { value: d.distanciaAbatedouroITA || undefined },
        { value: d.pesoPredito || undefined },
        { value: d.idadeSaida || undefined },
        { value: d.animaisPreditos || undefined },
        { value: d.consumo || undefined },
        { value: d.lucro || undefined }
      ]

      data.push(fileLine)
    })
    return data
  } else return []
}

export const getFileTotals = (totals: Record<string, any>) => {
  const total = [
    { value: undefined },
    { value: t('average_and_totals') },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: undefined },
    { value: Number(totals[0]?.pesoPredito?.toFixed(3)) },
    { value: Number(totals[0]?.idadeSaida?.toFixed()) },
    { value: Number(totals[0]?.animaisPreditos) },
    { value: Number(totals[0]?.consumo?.toFixed(3)) },
    { value: Number(totals[0]?.lucro?.toFixed(2)) }
  ]
  return total
}
