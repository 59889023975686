import { apiRequest } from 'api'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import { PesagemProcessada } from 'store/granjas/types'
import {
  Granja,
  GranjasNucleos,
  GranjasNucleosQueryParams,
  GranjaQueryParams,
  PesagemProcessadaQueryParams
} from './types'

export const getGranjas = async (params: GranjaQueryParams, token: string): Promise<Granja[]> => {
  try {
    return apiRequest<Granja[]>('/v1/granjas', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/granjas/get', error)
  }
}

export const getGranjasNucleos = async (
  params: GranjasNucleosQueryParams,
  token: string
): Promise<GranjasNucleos[]> => {
  try {
    return apiRequest<GranjasNucleos[]>('/v1/granjas/nucleos', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/granjas/nucleos/get', error)
  }
}

export const getPesagemProcessada = async (
  params: PesagemProcessadaQueryParams,
  token: string
): Promise<PesagemProcessada[]> => {
  try {
    return apiRequest<PesagemProcessada[]>('/v1/predictions/scaleProcessedWeighing', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/pesagem/balanca/processada/get',
      error
    )
  }
}
