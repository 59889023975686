import React, { useRef, useState } from 'react'
import { Dispatch, bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { TextField, Button, InputAdornment, IconButton } from '@material-ui/core'
import { useParams, NavLink, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Resources
import imgBgLight from 'resources/img/poultrytrader-background-image-light.png'
import imgBgDark from 'resources/img/poultrytrader-background-image-light.png'
import logoLight from 'resources/img/svg/poultrytrader-logo-regular.svg'
import logoRegular from 'resources/img/svg/poultrytrader-logo-regular.svg'

// Methods
import { requestRecoveryEmail, checkChangePwdToken } from 'store/auth/actions'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types'
import { Themes } from 'store/main/types.d'
import { Visibility, VisibilityOff } from '@material-ui/icons'

// Components
import ThemeToggle from 'components/shared/ThemeToggle'
import LocaleSelector from 'components/shared/LocaleSelector'
import { forgotPassword, recoverPassword } from 'api/user/recover-password'

//modal
import Alert, { AlertHandles } from 'components/shared/Alert'

const RecoverPassword = (props: Props) => {
  const { token }: any = useParams()
  const { t } = useTranslation()
  const { isCheckingRecoveryToken, requestingRecoveryEmail, requestEmailStatus } = props
  const history = useHistory()
  const newPasswordRef: any = useRef(null)

  //modal
  const sucessAlertRef = useRef<AlertHandles>(null)
  const errorAlertRef = useRef<AlertHandles>(null)

  // Hooks
  const [email, handleEmail] = useState('')
  const [emailError, handleEmailError] = useState('')
  const [password, handlePassword] = useState('')
  const [passwordError, handlePasswordError] = useState('')
  const [passwordConfirmation, handlePasswordConfirmation] = useState('')
  const [passwordConfirmationError, handlePasswordConfirmationError] = useState('')
  const [passwordMatch, handlePasswordMatch] = useState(false)
  const [isPasswordVisible, togglePasswordVisibility] = useState(false)
  const [feedbackUser, setFeedbackUser] = useState('')

  const handleField = (e: any) => {
    const { id, value } = e.target
    switch (id) {
      case 'email':
        handleEmail(value)
        break
      case 'password':
        handlePassword(value)
        break
      default:
        break
    }
  }

  const validate = (field: 'email' | 'password', email?: string, password?: string): boolean => {
    let hasErrors = false
    switch (field) {
      case 'email':
        {
          if (!email || email?.trim().length < 4) {
            handleEmailError('auth:email_too_short')
            hasErrors = true
          }
          if (!email || email?.trim()?.indexOf('@') < 0 || email?.trim()?.indexOf('.') < 0) {
            handleEmailError('auth:email_invalid')
            hasErrors = true
          }
        }
        break
      case 'password':
        {
          if (!email || email?.trim().length < 4) {
            handleEmailError('auth:email_too_short')
            hasErrors = true
          }
          if (!email || email?.trim()?.indexOf('@') < 0 || email?.trim()?.indexOf('.') < 0) {
            handleEmailError('auth:email_invalid')
            hasErrors = true
          }
          if (!password) {
            hasErrors = true
            handlePasswordError('auth:password_too_short')
          } else if (password.length < 4) {
            handlePasswordError('auth:password_too_short')
            hasErrors = true
          }
        }
        break
      default:
        break
    }
    return hasErrors
  }

  const submit = async () => {
    if (token) {
      if (!passwordMatch) return
      if (!validate('password', email, password)) {
        const response = await recoverPassword({ email, token, newPassword: password })
        console.log('response', response)
        if (response.statusCode) {
          setFeedbackUser(`${response.message}`)
          errorAlertRef.current?.openAlert()
        } else {
          setFeedbackUser(`${response.message}`)
          sucessAlertRef.current?.openAlert()
          setTimeout(() => {
            history.push('/')
          }, 3000)
        }
        return
      }
    } else {
      if (!validate('email', email)) {
        const response = await forgotPassword({ email })
        if (response.statusCode) {
          setFeedbackUser(`${response.message}`)
          errorAlertRef.current?.openAlert()
        } else {
          setFeedbackUser(`${response.message}`)
          sucessAlertRef.current?.openAlert()
        }
        return
      }
    }
  }

  return (
    <div id={'recover-password'} className={'auth-container'}>
      <Alert ref={sucessAlertRef} type='success' customMessage={feedbackUser} />
      <Alert ref={errorAlertRef} type='error' customMessage={feedbackUser} />
      <div className={'auth-picture-container'}>
        <img
          src={props.theme === Themes.LIGHT ? imgBgLight : imgBgDark}
          className={'auth-picture'}
        />
      </div>
      <aside className='auth-panel'>
        <div className='auth-settings-row'>
          <ThemeToggle />
          <LocaleSelector />
        </div>
        <div className='auth-form-container'>
          <div className='auth-paper'>
            <NavLink id={'auth-logo-poultrytrader'} className='auth-logo-container' to='/'>
              <img
                src={props.theme === Themes.LIGHT ? logoRegular : logoLight}
                className={'auth-logo'}
              />
            </NavLink>
            <div className={token ? 'auth-form' : 'hidden'}>
              <div className='auth-row'>
                <p>{t('auth:register_new_password')}</p>
              </div>
              <div className='auth-row'>
                <TextField
                  id='email'
                  error={emailError !== ''}
                  label={t('email')}
                  value={email}
                  variant='outlined'
                  onChange={handleField}
                  fullWidth
                  helperText={t(emailError)}
                />
              </div>
              <div className='auth-row'>
                <TextField
                  id='password'
                  label={t('password')}
                  type={isPasswordVisible ? 'text' : 'password'}
                  variant='outlined'
                  fullWidth
                  value={password}
                  disabled={isCheckingRecoveryToken}
                  onChange={handleField}
                  error={passwordError !== ''}
                  helperText={t(passwordError)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onMouseDown={() => {
                            togglePasswordVisibility(true)
                          }}
                          onMouseUp={() => {
                            togglePasswordVisibility(false)
                          }}
                        >
                          {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
              <div className='auth-row'>
                <TextField
                  id='newPasswordConfirmation'
                  label={t('components:formFields:repeat_new_pwd')}
                  type={isPasswordVisible ? 'text' : 'password'}
                  variant='outlined'
                  fullWidth
                  value={passwordConfirmation}
                  disabled={isCheckingRecoveryToken}
                  error={passwordConfirmationError !== ''}
                  helperText={t(passwordConfirmationError)}
                  onChange={(e) => {
                    const value = e.target.value
                    handlePasswordConfirmation(e.target.value)
                    if (value !== password) {
                      handlePasswordConfirmationError('auth:password_confirmation_error')
                      handlePasswordMatch(false)
                    } else {
                      handlePasswordMatch(true)
                      handlePasswordConfirmationError('')
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onMouseDown={() => {
                            togglePasswordVisibility(true)
                          }}
                          onMouseUp={() => {
                            togglePasswordVisibility(false)
                          }}
                        >
                          {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div className={!token ? 'auth-form' : 'hidden'}>
              <div className='auth-row'>
                <p>{t('auth:enter_yout_email_to_recover_pwd')}</p>
              </div>
              <div className='auth-row'>
                <TextField
                  id='email'
                  error={emailError !== ''}
                  label={t('email')}
                  value={email}
                  variant='outlined'
                  onChange={handleField}
                  fullWidth
                  helperText={t(emailError)}
                />
              </div>
            </div>
            <Button color='primary' variant='contained' fullWidth onClick={submit}>
              {t('submit')}
            </Button>
          </div>
        </div>
      </aside>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme,
  isCheckingRecoveryToken: store.auth.isCheckingRecoveryToken,
  requestingRecoveryEmail: store.auth.requestingRecoveryEmail,
  requestEmailStatus: store.auth.requestEmailStatus
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      requestRecoveryEmail,
      checkChangePwdToken
    },
    dispatch
  )
}

export default connect(mapStoreToProps, mapDispatchToProps)(RecoverPassword)
