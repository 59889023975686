import { all, ForkEffect, takeLatest } from 'redux-saga/effects'
import {
  workerGetLotes,
  workerGetLotesAcompanhamentos,
  workerGetLotesAlojamentos,
  workerGetLotesFechamentos,
  workerGetLotesPesos,
  workerSetSelectedLote,
  workerSetSelectedLoteAcompanhamento,
  workerGetLotesDuracaoCiclos,
  workerGetLotesPorMes,
  workerGetLotesFechamentosMedias,
  workerGetQuantLotesProcessados,
  workerGetLotesAtivos,
  workerGetOneYearAgoLotes
} from './sagas'
import { LoteConstants } from './types.d'

export function* watcherLotes(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES, workerGetLotes)
}
export function* watcherSetSelectedLote(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_SET_SELECTED_LOTE, workerSetSelectedLote)
}
export function* watcherLotesPesos(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_PESOS, workerGetLotesPesos)
}
export function* watcherLotesAlojamentos(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_ALOJAMENTOS, workerGetLotesAlojamentos)
}
export function* watcherLotesAcompanhamentos(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_ACOMPANHAMENTOS, workerGetLotesAcompanhamentos)
}
export function* watcherLotesFechamentos(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_FECHAMENTOS, workerGetLotesFechamentos)
}
export function* watcherSetSelectedLoteAcompanhamentos(): Generator<
  ForkEffect<{
    type: LoteConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    LoteConstants.ACTION_SET_SELECTED_LOTE_ACOMPANHAMENTO,
    workerSetSelectedLoteAcompanhamento
  )
}
export function* watcherGetLotesDuracaoCiclos(): Generator<
  ForkEffect<{
    type: LoteConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_DURACAO_CICLO, workerGetLotesDuracaoCiclos)
}
export function* watcherGetLotesPorMes(): Generator<
  ForkEffect<{
    type: LoteConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_POR_MES, workerGetLotesPorMes)
}

export function* watcherGetLotesFechamentosMedias(): Generator<
  ForkEffect<{
    type: LoteConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(
    LoteConstants.ACTION_GET_LOTES_FECHAMENTOS_MEDIAS,
    workerGetLotesFechamentosMedias
  )
}

export function* watcherGetQuantLotesProcessados(): Generator<
  ForkEffect<{
    type: LoteConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_PROCESSADOS, workerGetQuantLotesProcessados)
}

export function* watcherGetLotesAtivos(): Generator<
  ForkEffect<{
    type: LoteConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_LOTES_ATIVOS, workerGetLotesAtivos)
}

export function* watcherGetOneYearAgoLotes(): Generator<
  ForkEffect<{
    type: LoteConstants
  }>,
  void,
  unknown
> {
  yield takeLatest(LoteConstants.ACTION_GET_ONE_YEAR_AGO_LOTES, workerGetOneYearAgoLotes)
}

export default function* granjaWatchers(): Generator<any, any, any> {
  yield all([
    watcherLotes(),
    watcherLotesPesos(),
    watcherLotesAcompanhamentos(),
    watcherLotesFechamentos(),
    watcherLotesAlojamentos(),
    watcherSetSelectedLoteAcompanhamentos(),
    watcherSetSelectedLote(),
    watcherGetLotesDuracaoCiclos(),
    watcherGetLotesPorMes(),
    watcherGetLotesFechamentosMedias(),
    watcherGetQuantLotesProcessados(),
    watcherGetLotesAtivos(),
    watcherGetOneYearAgoLotes()
  ])
}
