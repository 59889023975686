import produce from 'immer'
import { AnyAction } from 'redux'

import { PlanejamentoStore, PlanejamentoConstants } from './types.d'

const INITIAL_STORE: PlanejamentoStore = {
  planejamentoDiarioLoading: false,
  // planejamentoSemanal: [],
  planejamentoSemanalLoading: false,
  planejamentoSemanalTotais: {
    animaisPreditosTotal: 0,
    pesoPreditoTotal: 0
  },
  planejamentosAlojamentos: [],
  planejamentosDiarios: {
    monday: {
      planejamentoDiario: []
    },
    tuesday: {
      planejamentoDiario: []
    },
    wednesday: {
      planejamentoDiario: []
    },
    thursday: {
      planejamentoDiario: []
    },
    friday: {
      planejamentoDiario: []
    },
    saturday: {
      planejamentoDiario: []
    }
  }
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case PlanejamentoConstants.REDUCER_SET_PLANEJAMENTO_DATA:
        Object.assign(draft, payload)
        break
      case PlanejamentoConstants.REDUCER_CLEAR_PLANEJAMENTO_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
