import { SaidaQueryParams } from './types.ts'
import Exception from 'helpers/Exception'
import Codes from 'helpers/Codes'
import { apiPost, apiRequest } from 'api'
import { PlanejamentoSemanal } from 'store/planejamento/types.js'

export const getSaidas = async (
  params: SaidaQueryParams,
  token: string
): Promise<PlanejamentoSemanal[]> => {
  try {
    return apiRequest<PlanejamentoSemanal[]>('/v1/ds/saidas/cliente', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/saidas/get', error)
  }
}

export const postPlanejamentoSemanal = async (
  token: string,
  planejamentosSemanais: PlanejamentoSemanal[]
): Promise<PlanejamentoSemanal> => {
  try {
    return apiPost<PlanejamentoSemanal>(`/v1/ds/saidas/cliente`, token, planejamentosSemanais)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/saidas/post', error)
  }
}

export const deletePlanejamentoSemanal = async (
  token: string,
  planejamentosSemanaisIds: { ids: number[] }
): Promise<PlanejamentoSemanal> => {
  try {
    return apiPost<PlanejamentoSemanal>(
      `/v1/ds/saidas/cliente/deletar`,
      token,
      planejamentosSemanaisIds
    )
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/saidas/post', error)
  }
}
