import produce from 'immer'
import { AnyAction } from 'redux'

import { ParametrosPOStore, ParametrosPOConstants } from './types.d'
import { weekDay } from 'store/main/types.d'

export const INITIAL_STORE: ParametrosPOStore = {
  parametrosPO: [],
  parametrosPOLoading: false,
  selectedParametrosPO: {
    clientId: '',
    idIntegrador: '',
    diaAbatesSanitarios: weekDay[6].value,
    custoPorKm: 0,
    custoPorViagem: 0,
    kgPorCaixa: 0,
    quantidadeCaixasPorCaminhao: 0,
    valorKgFrango: 0,
    parametros: {
      seg: {
        abateMaxDiario: 0,
        abateMinDiario: 0,
        mediaMaxIdadeCiclo: 0,
        mediaMinIdadeCiclo: 0,
        proporcaoMaxMachosFemeas: 0,
        proporcaoMinMachosFemeas: 0
      },
      ter: {
        abateMaxDiario: 0,
        abateMinDiario: 0,
        mediaMaxIdadeCiclo: 0,
        mediaMinIdadeCiclo: 0,
        proporcaoMaxMachosFemeas: 0,
        proporcaoMinMachosFemeas: 0
      },
      qua: {
        abateMaxDiario: 0,
        abateMinDiario: 0,
        mediaMaxIdadeCiclo: 0,
        mediaMinIdadeCiclo: 0,
        proporcaoMaxMachosFemeas: 0,
        proporcaoMinMachosFemeas: 0
      },
      qui: {
        abateMaxDiario: 0,
        abateMinDiario: 0,
        mediaMaxIdadeCiclo: 0,
        mediaMinIdadeCiclo: 0,
        proporcaoMaxMachosFemeas: 0,
        proporcaoMinMachosFemeas: 0
      },
      sex: {
        abateMaxDiario: 0,
        abateMinDiario: 0,
        mediaMaxIdadeCiclo: 0,
        mediaMinIdadeCiclo: 0,
        proporcaoMaxMachosFemeas: 0,
        proporcaoMinMachosFemeas: 0
      },
      sab: {
        abateMaxDiario: 0,
        abateMinDiario: 0,
        mediaMaxIdadeCiclo: 0,
        mediaMinIdadeCiclo: 0,
        proporcaoMaxMachosFemeas: 0,
        proporcaoMinMachosFemeas: 0
      }
    }
  }
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case ParametrosPOConstants.REDUCER_SET_PARAMETROSPO_DATA:
        Object.assign(draft, payload)
        break
      case ParametrosPOConstants.REDUCER_CLEAR_PARAMETROSPO_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
