// Types
import { apiPost, apiRequest } from 'api'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import { ParametrosPOQueryParams } from './types'
import { ParametrosPO } from 'store/parametrosPO/types'

export const getParametrosPO = async (
  token: string,
  params: ParametrosPOQueryParams
): Promise<ParametrosPO[]> => {
  try {
    return apiRequest<ParametrosPO[]>('/v1/ds/parametros/po', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/parametros/get', error)
  }
}

export const postParametrosPO = async (
  token: string,
  parametrosPO: ParametrosPO
): Promise<ParametrosPO> => {
  try {
    return apiPost<ParametrosPO>(`/v1/ds/parametros/po`, token, parametrosPO)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/parametros/post', error)
  }
}
