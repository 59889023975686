import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

// components
import Loading from 'components/shared/Loading'

// Resources
import './styles.scss'

// Methods
import { getAnaliseCrescimento } from 'store/analiseCrescimento/action'
import Format from 'helpers/Format'
import FormatDate from 'helpers/FormatDate'

// Types
import { ApplicationStore } from 'store/types'
import { AnaliseCrescimentoConstants, DadosAnalise } from 'store/analiseCrescimento/types.d'
import { Props, TableData } from './types.d'
import CustomMaterialTable from '../CustomMaterialTable'
import { TableColumns } from 'store/main/types'

const AnaliseCrescimentoTable: React.FC<Props> = ({ setLots }) => {
  const { dadosAnalise, analiseCrescimentoLoading } = useSelector(
    (state: ApplicationStore) => state.analiseCrescimento
  )
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)
  const { selectedGranjaId } = useSelector((state: ApplicationStore) => state.granja)
  const [originalTableData, setOriginalTableData] = useState<Array<any>>([])
  const [tableRows, setTableRows] = useState<Array<TableData>>([])

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const columns: Array<TableColumns> = [
    {
      field: 'unity',
      title: t('unity')
    },
    { field: 'core', title: t('core') },
    { field: 'aviary', title: t('aviary') },
    { field: 'lot', title: t('lot') },
    { field: 'birthDate', title: t('housing_date') },
    { field: 'age', title: t('age') + ', ' + t('days') },
    { field: 'poultryNumber', title: t('poultry_number') },
    { field: 'mortality', title: t('mortality') + ', %' },
    { field: 'lineage', title: t('lineage') },
    { field: 'sex', title: t('sex') }
  ]

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedIntegratorId = usePrevious(selectedIntegrador?.id)

  useEffect(() => {
    if (!dadosAnalise || prevSelectedIntegratorId !== selectedIntegrador?.id) {
      dispatch({
        type: AnaliseCrescimentoConstants.REDUCER_SET_ANALISE_CRESCIMENTO_DATA,
        payload: { dadosAnalise: undefined }
      })
      dispatch(getAnaliseCrescimento())
      setOriginalTableData([])
      setTableRows([])
    }
    if (dadosAnalise && !originalTableData.length) {
      const tableData: any = []
      dadosAnalise.map((d: DadosAnalise) => {
        const data = {
          unity: d.idGranja,
          core: d.idNucleo,
          aviary: d.numeroGalpao,
          lot: d.nroLote,
          birthDate: d.dataNascimento ? FormatDate(d.dataNascimento) : null,
          age: d.idadeMatriz,
          poultryNumber: Format(d.nroAnimais),
          mortality: d.mortalidade ? Format(d.mortalidade) + '%' : t('no_data'),
          lineage: d.raca,
          sex: d.genero?.toUpperCase()
        }
        tableData.push(data)
      })
      setOriginalTableData(tableData)
    }
  }, [dadosAnalise, selectedIntegrador])

  // useEffect(() => {
  //   // LOAD DATA FOR FIRST TIME
  //   if (originalTableData?.length && selectedGranjaId && !tableRows.length) {
  //     setTableRows(originalTableData?.filter((da) => da.unity === selectedGranjaId) ?? [])
  //     setLots(dadosAnalise?.filter((da) => da.idGranja === selectedGranjaId) ?? [])
  //   }
  // }, [selectedGranjaId, originalTableData])

  useEffect(() => {
    if (originalTableData?.length && selectedGranjaId) {
      setTableRows(originalTableData?.filter((da) => da.unity === selectedGranjaId) ?? [])
      setLots(dadosAnalise?.filter((da) => da.idGranja === selectedGranjaId) ?? [])
    }
  }, [originalTableData, selectedGranjaId])

  return analiseCrescimentoLoading ? (
    <Loading />
  ) : (
    <div className='analise-crescimento-table'>
      <CustomMaterialTable
        columns={columns}
        data={tableRows}
        options={{
          sorting: true,
          showTitle: false,
          search: true,
          paging: false
        }}
      />
    </div>
  )
}

export default AnaliseCrescimentoTable
