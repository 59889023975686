import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListItem, Popover, List, Button } from '@material-ui/core'

// Types
import { Props } from './types'
import { Integrador } from '../../../store/integrador/types'
import { ApplicationStore } from '../../../store/types'

// Methods
import { getIntegradores, setSelectedIntegrador } from '../../../store/integrador/actions'

// Resources
import './styles.scss'
import { mdiChevronDown } from '@mdi/js'
import colors, { Colors } from 'styles/variables/colors'
import Icon from '@mdi/react'

const IntegradorSwitcher: React.FC<Props> = () => {
  const dispatch = useDispatch()
  const { integradores, selectedIntegrador } = useSelector(
    (state: ApplicationStore) => state.integrador
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState<boolean>(false)
  const { theme } = useSelector((state: ApplicationStore) => state.main)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
    setOpen(!open)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  useEffect(() => {
    if (!integradores.length) dispatch(getIntegradores({ ativos: true }))
    if (!selectedIntegrador)
      dispatch(setSelectedIntegrador({ id: integradores[0]?.id, ativos: true }))
    // dispatch(setSelectedIntegrador({ id: 'SSA', ativos: true }))
  }, [integradores])

  const navigate = (id: string) => {
    dispatch(setSelectedIntegrador({ id }))
  }

  return (
    <div className={'integrador-switcher'}>
      <Button onClick={handleMenu} className={'integrador-switcher-button'} disabled={false}>
        <span className={'integrador-switcher-button-text'}>
          {selectedIntegrador?.descrIntegrador}
        </span>
        <Icon
          path={mdiChevronDown}
          size={1}
          className={'icon'}
          color={colors(theme, Colors.white)}
        />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List
          component='nav'
          aria-labelledby='nested-list-subheader'
          style={{ backgroundColor: 'white' }}
        >
          {integradores.map((item: Integrador) => (
            <ListItem
              key={item.id}
              button
              onClick={() => {
                handleClose()
                navigate(item.id)
              }}
            >
              <text className={`integrador-list-item-${theme}`}>{item.descrIntegrador}</text>
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

export default IntegradorSwitcher
