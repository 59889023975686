import { apiRequest } from 'api'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import {
  Lote,
  LotesAcompanhamentos,
  LotesAcompanhamentosQueryParams,
  LotePesos,
  LotePesosQueryParams,
  LotesProcessadosQueryParams,
  LotesQuantidadeMensal,
  LotesQuantidadeMensalQueryParams
} from './types.d'
import {
  LoteQueryParams,
  LotesAlojamentos,
  LotesAlojamentosQueryParams,
  LotesFechamentos,
  LotesFechamentosQueryParams
} from './types.d'
import { AlojamentoResumo } from 'store/planejamentoSemanal/types'

export const getLotes = async (params: LoteQueryParams, token: string): Promise<Lote[]> => {
  try {
    return apiRequest<Lote[]>('/v1/lotes', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/get', error)
  }
}

export const getLotesDuracao = async (params: LoteQueryParams, token: string): Promise<Lote[]> => {
  try {
    return apiRequest<Lote[]>('/v1/lotes/duracao', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/duracao/get', error)
  }
}

export const getLotesFechamentos = async (
  params: LotesFechamentosQueryParams,
  token: string
): Promise<LotesFechamentos[]> => {
  try {
    return apiRequest<LotesFechamentos[]>('/v1/lotes/fechamentos', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/fechamentos/get', error)
  }
}

export const getLotesAlojamentos = async (
  params: LotesAlojamentosQueryParams,
  token: string
): Promise<LotesAlojamentos[]> => {
  try {
    return apiRequest<LotesAlojamentos[]>('/v1/lotes/alojamentos', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/alojamentos/get', error)
  }
}

export const getLotesAlojamentosResumo = async (
  params: LotesAlojamentosQueryParams,
  token: string
): Promise<AlojamentoResumo[]> => {
  try {
    return apiRequest<AlojamentoResumo[]>('/v1/lotes/alojamentos/resumo', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/alojamentos/resumo/get', error)
  }
}

export const getLotesPesos = async (
  params: LotePesosQueryParams,
  token: string
): Promise<LotePesos[]> => {
  try {
    return apiRequest<LotePesos[]>('/v1/lotes/pesos', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/pesos/get', error)
  }
}

export const getLotesAcompanhamentos = async (
  params: LotesAcompanhamentosQueryParams,
  token: string
): Promise<LotesAcompanhamentos[]> => {
  try {
    const data = await apiRequest<LotesAcompanhamentos[]>(
      '/v1/lotes/acompanhamentos',
      token,
      params
    )
    return data
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/lotes/acompanhamentos/get', error)
  }
}

export const getQuantLotesProcessados = async (
  token: string,
  params?: LotesProcessadosQueryParams
): Promise<number> => {
  try {
    return apiRequest<any>('/v1/lotes/count', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'v1/lotes/count/get', error)
  }
}

export const getLotesQuantidadeMensal = async (
  token: string,
  params: LotesQuantidadeMensalQueryParams
): Promise<LotesQuantidadeMensal[]> => {
  try {
    return apiRequest<LotesQuantidadeMensal[]>('/v1/lotes/quantidadeMensal', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'v1/lotes/quantidadeMensal/get', error)
  }
}
