import React, { ReactElement, Suspense } from 'react'
import { Provider } from 'react-redux'

// Resources
import store from 'store'
import 'styles/reset.scss'
import 'styles/index.scss'
import 'helpers/i18n'

// Components
import Router from '../Router'
import Loading from 'components/shared/Loading'

const App = (): ReactElement<any, any> => (
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <Router />
    </Suspense>
  </Provider>
)

export default App
