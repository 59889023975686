import produce from 'immer'
import { AnyAction } from 'redux'
import { LoteStore, LoteConstants } from './types.d'

const INITIAL_STORE: LoteStore = {
  loteLoading: false,
  duracaoCiclosLoading: false,
  lotesPorMesLoading: false,
  lotesPorMes: [],
  duracaoCiclos: [],
  lotes: [],
  // oneYearAgoLotes: [],
  lotesPesos: [],
  lotesAlojamentos: [],
  lotesAcompanhamentos: [],
  lotesFechamentos: [],
  lotesFechamentosMedias: {
    mediaCiclo: null,
    mediaAbate: null
  },
  oneYearAgoLotesLoading: false,
  lotesFechamentosMediasLoading: false,
  quantLotesProcessados: null,
  lotesAtivos: null,
  quantLotesProcessadosLoading: false,
  lotesAtivosLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case LoteConstants.REDUCER_SET_LOTE_DATA:
        Object.assign(draft, payload)
        break
      case LoteConstants.REDUCER_CLEAR_LOTE_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
