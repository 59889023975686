import { all, ForkEffect, takeLatest } from 'redux-saga/effects'
import {
  workerGetParametrosPO,
  workerSaveParametrosPO,
  workerSetSelectedParametrosPO
} from './sagas'
import { ParametrosPOConstants } from './types.d'

export function* watcherGetParametrosPO(): Generator<
  ForkEffect<{
    type: ParametrosPOConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(ParametrosPOConstants.ACTION_GET_PARAMETROSPO, workerGetParametrosPO)
}

export function* watcherSetSelectedParametrosPO(): Generator<
  ForkEffect<{
    type: ParametrosPOConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    ParametrosPOConstants.ACTION_SET_SELECTED_PARAMETROSPO,
    workerSetSelectedParametrosPO
  )
}

export function* watcherSaveParametrosPO(): Generator<
  ForkEffect<{
    type: ParametrosPOConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(ParametrosPOConstants.ACTION_SAVE_PARAMETROSPO, workerSaveParametrosPO)
}

export default function* parametrosPOWatchers(): Generator<any, any, any> {
  yield all([watcherGetParametrosPO(), watcherSetSelectedParametrosPO(), watcherSaveParametrosPO()])
}
