import { all, ForkEffect, takeLatest } from 'redux-saga/effects'

import {
  workerSignOut,
  workerCheckAuthentication,
  workerSignIn,
  workerUpdatePassword
} from './sagas'

import { AuthConstants } from './types.d'

export function* watcherCheckAuthentication(): Generator<
  ForkEffect<{
    type: AuthConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(AuthConstants.ACTION_CHECK_AUTHENTICATION, workerCheckAuthentication)
}

export function* watcherSignIn(): Generator<
  ForkEffect<{
    type: AuthConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(AuthConstants.ACTION_SIGN_IN, workerSignIn)
}

export function* watcherSignOut(): Generator<
  ForkEffect<{
    type: AuthConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(AuthConstants.ACTION_SIGN_OUT, workerSignOut)
}

export function* watcherUpdatePassword(): Generator<
  ForkEffect<{
    type: AuthConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(AuthConstants.ACTION_UPDATE_PASSWORD, workerUpdatePassword)
}

export default function* authWatchers(): Generator<any, any, any> {
  yield all([
    watcherCheckAuthentication(),
    watcherSignIn(),
    watcherSignOut(),
    watcherUpdatePassword()
  ])
}
