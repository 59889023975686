import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import { ForgotPasswordQueryParams, RecoverPasswordQueryParams } from './types'
import axiosInstance from 'config/axiosConfig'

export const forgotPassword = async (body: ForgotPasswordQueryParams): Promise<any> => {
  try {
    const { data } = await axiosInstance.post('/v1/passwordRecovery/forgotPassword', body)
    return data
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/v1/passwordRecovery/forgotPassword',
      error
    )
  }
}

export const recoverPassword = async (body: RecoverPasswordQueryParams): Promise<any> => {
  try {
    const { data } = await axiosInstance.post('/v1/passwordRecovery/resetPassword', body)
    return data
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'api/v1/passwordRecovery/resetPassword',
      error
    )
  }
}
