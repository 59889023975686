import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dispatch, bindActionCreators } from 'redux'
// Components
import {
  CartesianGrid,
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis
} from 'recharts'
// Types
import { Props } from './types'
// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { ApplicationStore } from 'store/types'
import { connect } from 'react-redux'

const WeighingScatterCharts = (props: Props) => {
  const { t } = useTranslation()
  // Texts
  const realWeighingsScatterName: string = t('weighing:current_weighings')
  const predictedWeighingsScatterName: string = t('weighing:predicted_weighings')
  const interpolationWeighingsScatterName: string = t('weighing:interpolation_weighings')
  const realData = props.realWeighings?.filter((r) => r.tipo == 'r')
  // .map((r) => ({ ...r, valor: Number(r.valor?.toFixed(4)) }))
  const interpolacaoData = props.realWeighings?.filter((r) => r.tipo == 'i')
  // .map((r) => ({ ...r, valor: Number(r.valor?.toFixed(4)) }))
  const predictedData = props.realWeighings?.filter((r) => r.tipo == 'p')
  // .map((r) => ({ ...r, valor: Number(r.valor?.toFixed(4)) }))

  // const maxChartDay = predictedData ? predictedData[predictedData?.length - 1]?.dia : 55
  const minChartWeight = props.realWeighings ? props.realWeighings[0]?.valor : 0
  const maxChartWeight = props.realWeighings
    ? Number(props.realWeighings[props.realWeighings?.length - 1]?.valor.toFixed()) + 100 || '2000'
    : 2000
  return (
    <div className={'scatter-chart'}>
      <ResponsiveContainer className={'chart-responsive-container'}>
        <ScatterChart
          // width={530}
          // height={250}
          margin={{ top: 5, right: 20, bottom: 35, left: 10 }}
        >
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey={'dia'}
            name={t('day')}
            type='number'
            allowDecimals={false}
            domain={[1, 55]}
          ></XAxis>
          <YAxis
            dataKey='valor'
            name={t('weight')}
            unit='g'
            allowDecimals={false}
            domain={[minChartWeight | 0, maxChartWeight || 2000]}
          />
          <ZAxis dataKey='data' type='category' range={[64, 144]} name={t('date')} />
          <Tooltip cursor={{ strokeDasharray: '3 3' }} />
          <Scatter
            name={realWeighingsScatterName}
            data={realData}
            fill={colors(props.theme, Colors.redDark)}
          />
          <Scatter
            name={interpolationWeighingsScatterName}
            data={interpolacaoData}
            fill={colors(props.theme, Colors.gray)}
          />
          <Scatter
            name={predictedWeighingsScatterName}
            data={predictedData}
            fill={colors(props.theme, Colors.accent)}
          />
        </ScatterChart>
      </ResponsiveContainer>
    </div>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  theme: store.main.theme
})

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(mapStoreToProps, mapDispatchToProps)(WeighingScatterCharts)
