import Message from '../../helpers/Message'

export type MainStore = {
  isCheckingSettings: boolean
  message?: Message
  infoModal: {
    type: AlertModalTypes.info | AlertModalTypes.warning | AlertModalTypes.error
    open: boolean
    message?: string
    btnTxt?: string
    btnLink?: string
  }
  theme: Themes
  locale: Locales
  timeZone: string
  isDrawerOpen: boolean
}
export type ColumnsCollapse = {
  order: number
  id: string
  label: string
  collapse: boolean
  columns: Columns[]
}
export type Columns = {
  order: number
  id: string
  active: boolean
  disablePadding: boolean
  type: string
  label: string
}

export enum AlertModalTypes {
  info = 'info',
  warning = 'warning',
  error = 'error',
  success = 'success'
}

export enum Locales {
  en = 'en',
  pt = 'pt'
}

export type Option = {
  value: number | string
  label: string
}

export type WeekDayOption = {
  value: string
  label: string
}

export const weekDay: WeekDayOption[] = [
  { value: 'dom', label: 'sunday' },
  { value: 'seg', label: 'monday' },
  { value: 'ter', label: 'tuesday' },
  { value: 'qua', label: 'wednesday' },
  { value: 'qui', label: 'thursday' },
  { value: 'sex', label: 'friday' },
  { value: 'sab', label: 'saturday' }
]

export const planningWeekdays: string[] = [
  'week',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
]

export enum UnitySystem {
  imperial = 'imperial',
  metric = 'metric'
}

export enum Themes {
  LIGHT = 'light',
  DARK = 'dark'
}

export enum Genders {
  macho = 'macho',
  femea = 'femea',
  misto = 'misto',
  sexado = 'sexado',
  m = 'm',
  f = 'f',
  x = 'x'
}

export type DadoDiario = {
  [key: string]: any
  nrolote: number
  valor: number
  tipo: string
  dia: number
  data: Date
}

export type MediaPorCategoria = {
  valor: number
  [key: string]: Array<Media>
}

export type Media = {
  valor: number
  dia: number
  genero: string
}

export enum TableColumnsTypes {
  numeric = 'numeric',
  string = 'string'
}

export type TableColumns = {
  field: string
  title?: string
  sortable?: boolean
}

export enum WeighingDataType {
  TABLE = 'table',
  CHARTS = 'charts'
}

export enum MainConstants {
  ACTION_CHECK_SETTINGS = 'ACTION_CHECK_SETTINGS',
  ACTION_SET_DATA_TYPE = 'ACTION_SET_DATA_TYPE',
  ACTION_SET_THEME = 'ACTION_SET_THEME',
  ACTION_SET_LOCALE = 'ACTION_SET_LOCALE',
  REDUCER_CLEAR_MAIN_DATA = 'REDUCER_CLEAR_MAIN_DATA',
  REDUCER_SET_MAIN_DATA = 'REDUCER_SET_MAIN_DATA'
}
