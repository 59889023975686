import { all, ForkEffect, takeLatest } from 'redux-saga/effects'
import { workerGetIntegradores, workerSetSelectedIntegrador } from './sagas'
import { IntegradorConstants } from './types.d'

export function* watcherGetIntegradores(): Generator<
  ForkEffect<{
    type: IntegradorConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(IntegradorConstants.ACTION_GET_INTEGRADORES, workerGetIntegradores)
}

export function* watcherSetSelectedIntegrador(): Generator<
  ForkEffect<{
    type: IntegradorConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(IntegradorConstants.ACTION_SET_SELECTED_INTEGRADOR, workerSetSelectedIntegrador)
}

export default function* integradorWatchers(): Generator<any, any, any> {
  yield all([watcherGetIntegradores(), watcherSetSelectedIntegrador()])
}
