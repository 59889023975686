import React from 'react'
import { Button } from '@material-ui/core'
import './styles.scss'
import { WeighingDataType } from 'store/main/types.d'

interface Props {
  t: any
  selectedType: WeighingDataType
  handleTypeChange: (type: WeighingDataType) => void
}

const CardTypesButtons: React.FC<Props> = ({ t, selectedType, handleTypeChange }: Props) => {
  function handleClick(event: React.MouseEvent<HTMLElement>, type: WeighingDataType) {
    event.stopPropagation()
    handleTypeChange(type)
  }
  return (
    <div className={'type-button'}>
      <Button
        className={
          selectedType === WeighingDataType.CHARTS
            ? 'selected-card-type-button'
            : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          handleClick(event, WeighingDataType.CHARTS)
        }}
      >
        <span
          className={
            selectedType === WeighingDataType.CHARTS ? 'selected-type-text' : 'type-button-text'
          }
        >
          {t('charts')}
        </span>
      </Button>
      <Button
        className={
          selectedType === WeighingDataType.TABLE ? 'selected-card-type-button' : 'card-type-button'
        }
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.stopPropagation()
          handleClick(event, WeighingDataType.TABLE)
        }}
      >
        <span
          className={
            selectedType === WeighingDataType.TABLE ? 'selected-type-text' : 'type-button-text'
          }
        >
          {t('table')}
        </span>
      </Button>
    </div>
  )
}

export default CardTypesButtons
