export type ParametrosPOStore = {
  parametrosPO: ParametrosPO[]
  selectedParametrosPO: ParametrosPO
  parametrosPOLoading: boolean
}

export type ParametrosPO = {
  [key: string]: any
  clientId: string
  idIntegrador: string
  diaAbatesSanitarios?: string
  custoPorKm?: number
  custoPorViagem?: number
  kgPorCaixa?: number
  quantidadeCaixasPorCaminhao?: number
  valorKgFrango?: number
  parametros: {
    [key: string]: Parametros
  }
}

export type Parametros = {
  [key: string]: any
  abateMaxDiario?: number
  abateMinDiario?: number
  mediaMaxIdadeCiclo?: number
  mediaMinIdadeCiclo?: number
  proporcaoMaxMachosFemeas?: number
  proporcaoMinMachosFemeas?: number
}

export enum ParametrosPOConstants {
  ACTION_GET_PARAMETROSPO = 'ACTION_GET_PARAMETROSPO',
  ACTION_SET_SELECTED_PARAMETROSPO = 'ACTION_SET_SELECTED_PARAMETROSPO',
  ACTION_SAVE_PARAMETROSPO = 'ACTION_SAVE_PARAMETROSPO',
  REDUCER_CLEAR_PARAMETROSPO_DATA = 'REDUCER_CLEAR_PARAMETROSPO_DATA',
  REDUCER_SET_PARAMETROSPO_DATA = 'REDUCER_SET_PARAMETROSPO_DATA'
}
