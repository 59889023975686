import { call, Effect, put, PutEffect, select } from 'redux-saga/effects'
import Exception from '../../helpers/Exception'

// types
import {
  PlanejamentoConstants,
  PlanejamentoSemanal,
  PlanejamentoStore,
  Saida,
  PlanejamentosDiarios
} from './types.d'
import { MainConstants, planningWeekdays } from '../main/types.d'
import store from 'store'
import { workerSignOut } from 'store/auth/sagas'
import { getSaida } from 'api/dadosProcessados'
import FormatDate from 'helpers/FormatDate'
import _ from 'lodash'
import moment from 'moment'
import Codes from 'helpers/Codes'
import { getLotesAlojamentos } from 'api/lote'

function* putPlanejamentoData(payload: any): Generator<
  PutEffect<{
    type: PlanejamentoConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: PlanejamentoConstants.REDUCER_SET_PLANEJAMENTO_DATA,
    payload
  })
}

function* putMainData(payload: any): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetPlanejamentoAlojamentos(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putPlanejamentoData, { planejamentoDiarioLoading: true })
    const { credentials } = store.getState().auth

    // const planejamentoStore: PlanejamentoStore = yield select((s) => s.planejamento)
    // let { planejamentosAlojamentos } = planejamentoStore

    // if (!planejamentosAlojamentos.length) {
    const planejamentosAlojamentos = yield call(getLotesAlojamentos, params, credentials.token)
    // }

    const planejamentosDiarios = {
      monday: {
        planejamentoDiario: []
      },
      tuesday: {
        planejamentoDiario: []
      },
      wednesday: {
        planejamentoDiario: []
      },
      thursday: {
        planejamentoDiario: []
      },
      friday: {
        planejamentoDiario: []
      },
      saturday: {
        planejamentoDiario: []
      }
    }

    yield call(putPlanejamentoData, {
      planejamentosAlojamentos,
      planejamentosDiarios,
      planejamentoDiarioLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamento/workerGetPlanejamentoAlojamentos',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoData, { planejamentoDiarioLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetPlanejamentoDiarioSemanal(): any {
  try {
    yield call(putPlanejamentoData, { planejamentoDiarioLoading: true })

    const planejamentoStore: PlanejamentoStore = yield select((s) => s.planejamento)
    const { saida } = planejamentoStore
    const { planejamentosAlojamentos, planejamentoSemanal } = planejamentoStore
    const todosPlanejamentos: PlanejamentosDiarios = {
      monday: {
        planejamentoDiario: []
      },
      tuesday: {
        planejamentoDiario: []
      },
      wednesday: {
        planejamentoDiario: []
      },
      thursday: {
        planejamentoDiario: []
      },
      friday: {
        planejamentoDiario: []
      },
      saturday: {
        planejamentoDiario: []
      }
    }

    planningWeekdays.map((w: string) => {
      if (w !== 'week') {
        const lotePlanejamento: any = []
        const ps = planejamentoSemanal?.find((ps) => ps.diaSemanaSaida?.toLowerCase() === w)
        if (ps?.nroLote?.length && ps?.idGalpao?.length) {
          for (let j = 0; j < ps?.idGalpao?.length; j++) {
            const lote = planejamentosAlojamentos?.find(
              (p) => p.idGalpao === ps.idGalpao[j]?.toString()
            )
            lote ? lotePlanejamento.push(lote) : null
          }
        }

        let idadePreditaTotal = 0
        let pesoPreditoTotal = 0
        let lucroTotal = 0
        let consumoTotal = 0
        let animaisPreditosTotal = 0

        let dailyData = _.map(lotePlanejamento, (pd) => {
          const findedSaida = saida?.find(
            (s: Saida) => s.nroLote === pd?.nroLote && s.idGalpao === pd?.idGalpao
          )
          if (findedSaida && findedSaida?.animaisPreditos) {
            idadePreditaTotal += findedSaida?.idadeSaida
              ? findedSaida?.idadeSaida * findedSaida?.animaisPreditos
              : 0
            pesoPreditoTotal += findedSaida?.pesoPredito
              ? findedSaida?.pesoPredito * findedSaida?.animaisPreditos
              : 0
            lucroTotal += findedSaida?.lucro || 0
            consumoTotal += findedSaida?.consumo
              ? findedSaida?.consumo * findedSaida?.animaisPreditos
              : 0
            animaisPreditosTotal += findedSaida?.animaisPreditos ? findedSaida?.animaisPreditos : 0
          }
          return {
            ...pd,
            idadePredita: findedSaida?.idadeSaida,
            pesoPredito: Number(findedSaida?.pesoPredito?.toFixed(3)),
            lucro: Number(findedSaida?.lucro?.toFixed(2)),
            consumo: Number(findedSaida?.consumo?.toFixed(3)),
            animaisPreditos: findedSaida?.animaisPreditos
          }
        })

        const planejamentoDiarioTotais = {
          idadePreditaTotal: Number((idadePreditaTotal / animaisPreditosTotal).toFixed()),
          pesoPreditoTotal: Number((pesoPreditoTotal / animaisPreditosTotal).toFixed(3)),
          lucroTotal: Number(lucroTotal.toFixed(2)),
          consumoTotal: Number((consumoTotal / animaisPreditosTotal).toFixed(3)),
          animaisPreditosTotal: animaisPreditosTotal
        }
        dailyData = _.uniqBy(dailyData, 'idGalpao')

        dailyData = _.orderBy(dailyData, ['idGranja', 'numeroGalpao'], ['asc', 'asc'])

        todosPlanejamentos[w].planejamentoDiario = dailyData
        todosPlanejamentos[w].totais = planejamentoDiarioTotais
      }
    })

    yield call(putPlanejamentoData, {
      planejamentosDiarios: todosPlanejamentos,
      planejamentoDiarioLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamento/workerGetPlanejamentoDiarioSemanal',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoData, { planejamentoDiarioLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerGetPlanejamentoSemanal(action: Effect): any {
  const { params } = action.payload
  try {
    yield call(putPlanejamentoData, { planejamentoSemanalLoading: true })
    const { credentials } = store.getState().auth

    let saida: Saida[] = yield call(getSaida, params, credentials.token)
    saida = _.orderBy(saida, 'dataSaida')
    let result = []
    let planejamentoSemanalTotais
    if (saida.length) {
      result = saida
        .reduce((acc: any, cur: any) => {
          const idx = acc.findIndex((v: any) => v[0].dataSaida === cur.dataSaida)
          if (idx > -1) acc[idx].push(cur)
          else acc.push([cur])
          return acc
        }, [])
        .map((cur: any) => {
          // pesoPredito, lucro, consumo e idadeSaida são calculados usando média ponderada
          const planejamentoSemanal = cur.reduce(
            (acc: any, cur: any) => {
              acc.nroLote.push(cur.nroLote)
              acc.idGalpao.push(cur.idGalpao)
              acc.dataSaida = cur.dataSaida
              acc.animaisPreditos += cur.animaisPreditos
              acc.pesoPredito = acc.pesoPredito + cur.pesoPredito * cur.animaisPreditos
              acc.idadeSaida = acc.idadeSaida + cur.idadeSaida * cur.animaisPreditos
              acc.lucro = acc.lucro + cur.lucro
              acc.consumo = acc.consumo + cur.consumo * cur.animaisPreditos
              return acc
            },
            {
              nroLote: [],
              idGalpao: [],
              dataSaida: '',
              animaisPreditos: 0,
              pesoPredito: 0,
              lucro: 0,
              consumo: 0,
              idadeSaida: 0
            } as PlanejamentoSemanal
          )
          planejamentoSemanal.diaSemanaSaida = moment
            .utc(planejamentoSemanal.dataSaida)
            .format('dddd')
            .toLowerCase()
          planejamentoSemanal.pesoPredito = Number(
            (planejamentoSemanal.pesoPredito / planejamentoSemanal.animaisPreditos).toFixed(3)
          )
          planejamentoSemanal.idadeSaida =
            planejamentoSemanal.idadeSaida / planejamentoSemanal.animaisPreditos

          planejamentoSemanal.lucro = planejamentoSemanal.lucro.toFixed(2)

          planejamentoSemanal.consumo = Number(
            (planejamentoSemanal.consumo / planejamentoSemanal.animaisPreditos).toFixed(3)
          )
          return planejamentoSemanal
        }, [])

      let pesoPreditoTotal = 0
      let lucroTotal = 0
      let consumoTotal = 0
      let animaisPreditosTotal = 0
      let idadeSaida = 0
      let numLotes = 0

      result = _.orderBy(result, 'dataSaida', 'asc')

      result.map((r: PlanejamentoSemanal) => {
        if (r) {
          pesoPreditoTotal += (r.pesoPredito || 0) * (r.animaisPreditos || 0)
          lucroTotal += Number(r.lucro) || 0
          consumoTotal += (r.consumo || 0) * (r.animaisPreditos || 0)
          animaisPreditosTotal += r.animaisPreditos || 0
          idadeSaida += (r.idadeSaida || 0) * (r.animaisPreditos || 0)
          numLotes += r.nroLote?.length || 0
          if (r.dataSaida) r.dataSaida = FormatDate(r.dataSaida)
        }
        return r
      })

      planejamentoSemanalTotais = {
        pesoPreditoTotal: Number((pesoPreditoTotal / animaisPreditosTotal).toFixed(3)),
        lucroTotal: lucroTotal,
        consumoTotal: Number((consumoTotal / animaisPreditosTotal).toFixed(3)),
        animaisPreditosTotal: Number(animaisPreditosTotal.toFixed()),
        idadeSaida: Number((idadeSaida / animaisPreditosTotal).toFixed(1)),
        numLotes: numLotes
      }
    }
    yield call(putPlanejamentoData, {
      saida,
      planejamentoSemanalTotais,
      planejamentoSemanal: result,
      planejamentoSemanalLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/planejamento/workerGetPlanejamentoSemanal',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putPlanejamentoData, { planejamentoSemanalLoading: false })
    yield call(putMainData, { message })
  }
}
