import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

// Methods
import { getGranjas, setSelectedGranja } from 'store/granjas/actions'

// Types
import { Props } from './types'
import { ApplicationStore } from 'store/types.d'
import { Granja } from 'store/granjas/types'

// Components
import Loading from 'components/shared/Loading'
import { Autocomplete, TextField } from '@mui/material'

// Resources
import './styles.scss'

const GranjaFilterCard: React.FC<Props> = ({ active, withActiveLots }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { granjas, granjaLoading, selectedGranjaId } = useSelector(
    (state: ApplicationStore) => state.granja
  )
  const [value, setValue] = useState<null | number | string>(null)
  const [options, setOptions] = useState<Array<number | string>>([])
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedIntegratorId = usePrevious(selectedIntegrador?.id)

  useEffect(() => {
    const finded = granjas.find((g) => g.id === 300)
    if (finded) {
      setValue(finded.id)
      dispatch(setSelectedGranja({ id: Number(finded.id) }))
    } else {
      setValue(granjas[0]?.id)
      dispatch(setSelectedGranja({ id: Number(granjas[0]?.id) }))
    }
  }, [granjas])

  useEffect(() => {
    if (!granjas?.length || prevSelectedIntegratorId !== selectedIntegrador?.id)
      dispatch(
        getGranjas({
          ativo: active,
          comLotesAtivos: withActiveLots,
          idIntegrador: selectedIntegrador?.id
        })
      )
    // if (granjas?.length && !selectedGranja) dispatch(setSelectedGranja({ id: granjas[0].id }))
    // if (selectedGranja && !granjasNucleos?.length)
    //   dispatch(getGranjasNucleos({ idGranja: selectedGranja.id }))
    // if (!galpoes?.length) dispatch(getGalpoes())
  }, [granjas, selectedIntegrador /*, selectedGranja, galpoes, granjasNucleos */])

  useEffect(() => {
    if (selectedGranjaId) setValue(selectedGranjaId)
  }, [selectedGranjaId])

  // const prevSelectedGranjaId = usePrevious(selectedGranjaId)

  // useEffect(() => {
  //   if (prevSelectedGranjaId && prevSelectedGranjaId !== value) setValue(prevSelectedGranjaId)
  // }, [])

  useEffect(() => {
    const op: Array<number | string> = []
    granjas.map((g: Granja) => {
      op.push(g.id)
    })
    setOptions(op)
    // if (op.length && op[0] !== t('all')) {
    //   const allValueTxt = t('all')
    //   op.unshift(allValueTxt)
    // }
    if (op.length && !value) {
      // setValue(op[0])
      // if (op[0]) dispatch(setSelectedGranja({ id: Number(op[0]) }))
    }
  }, [granjas])

  const navigate = (id: number | string) => {
    if (id && id !== t('all')) dispatch(setSelectedGranja({ id: Number(id) }))
    else dispatch(setSelectedGranja({ id: undefined }))
  }

  return (
    <Autocomplete
      className='granja-filter-card'
      value={value}
      onChange={(event, newValue) => {
        setValue(newValue), newValue ? navigate(newValue) : {}
      }}
      options={options}
      getOptionLabel={(option) => {
        return option.toString()
      }}
      sx={{ width: 300 }}
      renderInput={(params: any) =>
        granjaLoading ? <Loading /> : <TextField {...params} label={t('unity')} />
      }
    />
  )
}

export default GranjaFilterCard
