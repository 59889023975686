export type IntegradorStore = {
  integradores: Integrador[]
  selectedIntegrador?: Integrador
  integradorLoading: boolean
}

export enum IntegratorEnum {
  NV = 'NV',
  SSA = 'SSA'
}

export type Integrador = {
  id: string
  descrIntegrador: string
  centroCusto: number
  createdAt: Date
  updatedAt: Date
}

export enum IntegradorConstants {
  ACTION_GET_INTEGRADORES = 'ACTION_GET_INTEGRADOR',
  ACTION_SET_SELECTED_INTEGRADOR = 'ACTION_SET_SELECTED_INTEGRADOR',
  REDUCER_CLEAR_INTEGRADOR_DATA = 'REDUCER_CLEAR_INTEGRADOR_DATA',
  REDUCER_SET_INTEGRADOR_DATA = 'REDUCER_SET_INTEGRADOR_DATA'
}
