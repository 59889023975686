import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

// Types
import { Props } from './types.d'

// Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider
} from '@material-ui/core'
import { Form } from '@unform/web'
import { SubmitHandler } from '@unform/core'
import Loading from 'components/shared/Loading'
import Input from 'components/widgets/Input'

// Methods
import { updatePassword } from 'store/auth/actions'
import { ApplicationStore } from 'store/types'

// Resources
import './styles.scss'

const UserSettingsForm: React.FC<Props> = ({ open, handleClose }) => {
  const [op, setOp] = React.useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const formRef: any = useRef(null)
  const clearError = (e: any) => formRef.current?.setFieldError(e.target?.id, '')
  const { updatePasswordLoading } = useSelector((state: ApplicationStore) => state.auth)

  useEffect(() => {
    setOp(open || false)
  }, [open])

  const updateData: SubmitHandler<any> = async (data) => {
    try {
      formRef.current.setErrors({})
      const schema = Yup.object().shape({
        oldPassword: Yup.string()
          .typeError(t('components:formFields:invalid_number'))
          .nullable()
          .required(() => t('components:formFields:required')),
        newPassword: Yup.string()
          .typeError(t('components:formFields:invalid_number'))
          .nullable()
          .required(() => t('components:formFields:required'))
          .min(8, t('components:formFields:pwd_min_chars')),
        newPasswordConfirmation: Yup.string().oneOf(
          [Yup.ref('newPassword'), null],
          t('components:formFields:pwd_confirmation')
        )
      })
      await schema.validate(data, { abortEarly: false })
      delete data.newPasswordConfirmation
      dispatch(updatePassword(data))
    } catch (err) {
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: any) => (validationErrors[error.path] = error.message))
        formRef.current.setErrors(validationErrors)
      }
    }
  }

  return (
    <Dialog className={'user-settings'} open={op} onClose={handleClose}>
      <DialogTitle className='title'>{t('my_data')}</DialogTitle>
      <Divider light />
      <span className='subtitle'>{t('security')}</span>
      <Form className={'form'} ref={formRef} onSubmit={updateData}>
        {updatePasswordLoading ? (
          <Loading />
        ) : (
          <>
            <DialogContent>
              <Input
                onFocus={clearError}
                name='oldPassword'
                label={t('components:formFields:old_pwd')}
                type='password'
                placeholder={t('components:formFields:old_pwd')}
              />
              <Input
                onFocus={clearError}
                name='newPassword'
                label={t('update_pwd')}
                type='password'
                placeholder={t('components:formFields:new_pwd')}
              />
              <Input
                onFocus={clearError}
                name='newPasswordConfirmation'
                label={''}
                type='password'
                placeholder={t('components:formFields:repeat_new_pwd')}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{t('components:button:cancel')}</Button>
              <Button type='submit'>{t('update')}</Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  )
}

export default UserSettingsForm
