import produce from 'immer'
import { AnyAction } from 'redux'

import { IntegradorStore, IntegradorConstants } from './types.d'

const INITIAL_STORE: IntegradorStore = {
  integradores: [],
  integradorLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case IntegradorConstants.REDUCER_SET_INTEGRADOR_DATA:
        Object.assign(draft, payload)
        break
      case IntegradorConstants.REDUCER_CLEAR_INTEGRADOR_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
