import { FormControl, InputLabel, Select as MSelect, MenuItem } from '@material-ui/core'
import { useField } from '@unform/core'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Props } from './types.d'
import './styles.scss'

const Select: React.FC<Props> = ({
  name,
  label,
  selectedValue,
  options,
  disabled,
  onChange
}: Props) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLSelectElement>(null)
  const { fieldName, registerField, error } = useField(name)
  const [value, setValue] = useState<string>('sab')
  const [op, setOp] = useState<[{ value: any; label: string }]>()

  useEffect(() => {
    if (selectedValue) setValue(selectedValue)
    if (options) setOp(options)
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => value
    })
  }, [selectedValue, fieldName, options, value, registerField, setValue])

  return (
    <FormControl variant='outlined' className={!error ? 'formSelect' : 'formSelect-error'}>
      <InputLabel id='label'>{label}</InputLabel>
      <MSelect
        className={'options'}
        labelId='label'
        id={fieldName}
        ref={inputRef}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {op?.map((o: any, index: number) => (
          <MenuItem key={index} value={o.value}>
            {t(o.label)}
          </MenuItem>
        ))}
      </MSelect>
      {error && <span className='error'>{error}</span>}
    </FormControl>
  )
}
export default Select
