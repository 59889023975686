import { GalpaoQueryParams } from 'api/galpao/types.d'
import { GranjasNucleosQueryParams, GranjaQueryParams } from 'api/granja/types.d'
import { GranjaConstants } from './types.d'

export const getGranjas = (params?: GranjaQueryParams): Record<string, any> => ({
  type: GranjaConstants.ACTION_GET_GRANJAS,
  payload: { params }
})

export const getGranjasNucleos = (params?: GranjasNucleosQueryParams): Record<string, any> => ({
  type: GranjaConstants.ACTION_GET_GRANJAS_NUCLEOS,
  payload: { params }
})

export const getGalpoes = (params?: GalpaoQueryParams): Record<string, any> => ({
  type: GranjaConstants.ACTION_GET_GALPOES,
  payload: { params }
})

export const setSelectedGranja = (params: GranjaQueryParams): Record<string, any> => ({
  type: GranjaConstants.ACTION_SET_SELECTED_GRANJA,
  payload: { params }
})

export const setSelectedGranjasNucleos = (
  params: GranjasNucleosQueryParams
): Record<string, any> => ({
  type: GranjaConstants.ACTION_SET_SELECTED_GRANJA_NUCLEOS,
  payload: { params }
})

export const setSelectedGalpao = (params: GalpaoQueryParams): Record<string, any> => ({
  type: GranjaConstants.ACTION_SET_SELECTED_GALPAO,
  payload: { params }
})

export const getGalpoesIelm = (): Record<string, any> => ({
  type: GranjaConstants.ACTION_GET_GAPOES_IELM_GALPAO
})

export const getPesos = (
  idGranja: number,
  media: boolean,
  lotesAtivos: boolean,
  inicio?: Date,
  fim?: Date,
  idIntegrador?: string
): Record<string, any> => ({
  type: GranjaConstants.ACTION_GET_PESOS,
  payload: { idGranja, media, lotesAtivos, inicio, fim, idIntegrador }
})

export const getActiveLotPesos = (
  idGranja: number,
  media: boolean,
  inicio?: Date,
  fim?: Date,
  idIntegrador?: string
): Record<string, any> => ({
  type: GranjaConstants.ACTION_GET_ACTIVE_LOT_PESOS,
  payload: { idGranja, media, inicio, fim, idIntegrador }
})
