import React from 'react'
import { Props } from './types'

const AnaliseIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='analise'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 40 40'
    >
      <path
        id='Caminho_663'
        data-name='Caminho 663'
        d='M5.107,2.25H2.25V39.393A2.857,2.857,0,0,0,5.107,42.25H42.25V39.393H5.107Z'
        transform='translate(-2.25 -2.25)'
        fill={fill}
      />
      <path
        id='Caminho_664'
        data-name='Caminho 664'
        d='M42.25,10.125H31.9v2.25h5.311L25.979,20.914l-6.345-4.837a1.824,1.824,0,0,0-2.1,0L6.75,24.289l2.085,1.586,9.748-7.414L24.929,23.3a1.824,1.824,0,0,0,2.1,0L39.29,13.96V18h2.958Z'
        transform='translate(-2.25 2)'
        fill={fill}
      />
    </svg>
  )
}

export default AnaliseIcon
