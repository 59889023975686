import React from 'react'
import { Route, Redirect } from 'react-router'

import Home from 'pages/Home'
import ParametrosPO from 'pages/ParametrosPO'
import AnaliseCrescimento from 'pages/AnaliseCrescimento'
import Pesos from 'pages/Pesos'
import PlanejamentoSemanalV2Page from 'pages/PlanejamentoSemanalV2'

export default [
  <Route exact path='/' key={'/'} component={Home} />,
  <Route
    exact
    path='/planejamentoSemanalV2'
    key={'/planejamentoSemanalV2'}
    component={PlanejamentoSemanalV2Page}
  />,
  <Route
    exact
    path='/analiseCrescimento'
    key={'/analiseCrescimento'}
    component={AnaliseCrescimento}
  />,
  <Route exact path='/pesos' key={'/pesos'} component={Pesos} />,
  <Route exact path='/parametrosPO' key={'/parametrosPO'} component={ParametrosPO} />,
  <Route path='*' key={'*'}>
    <Redirect to={'/not-found'} />
  </Route>
]
