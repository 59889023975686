import { takeLatest, all, ForkEffect } from 'redux-saga/effects'
import { workerGetDadosAnalise } from './sagas'
import { AnaliseCrescimentoConstants } from './types.d'

export function* watcherAnaliseCrescimento(): Generator<
  ForkEffect<{
    type: AnaliseCrescimentoConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    AnaliseCrescimentoConstants.ACTION_GET_ANALISE_CRESCIMENTO,
    workerGetDadosAnalise
  )
}

export default function* granjaWatchers(): Generator<any, any, any> {
  yield all([watcherAnaliseCrescimento()])
}
