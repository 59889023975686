// React
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { t } from 'i18next'
import _ from 'lodash'
import moment from 'moment'
import ReactExport from 'react-data-export'

// Types
import { rowClassRules, summaryTableRowClassRules } from './types.d'
import { ApplicationStore } from 'store/types'
import {
  AlojamentoResumo,
  MovementTypeEnum,
  NovoPlanejamentoSemanal,
  OrigemDadoEnum,
  PlanejamentoSemanal,
  SummaryTableData
} from 'store/planejamentoSemanal/types.d'
import { IntegratorEnum } from 'store/integrador/types.d'
import { Themes } from 'store/main/types.d'

// AGGrid
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'
import {
  ColDef,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  RowClassParams,
  RowStyle
} from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'

// Components
import Legend from 'components/Legend'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tab,
  Tabs,
  IconButton,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core'
import { FileCopyOutlined, Restore, Save, InsertDriveFile, DeleteOutline } from '@material-ui/icons'

// Methods
import {
  TabPanel,
  generateSummaryData,
  generatesTotals,
  getDropZoneParams,
  getFileData,
  getFileTotals
} from './helpers'
import {
  deletePlanejamentoSemanal,
  filterAlojamentosByPlanejamento,
  getAlojamentosAtivos,
  getPlanejamentosSemanais,
  savePlanejamentosSemanal
} from 'store/planejamentoSemanal/actions'

// Styles
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import Format from 'helpers/Format'

const PlanejamentoSemanalV2Page: React.FC = () => {
  const {
    alojamentosAtivos,
    filteredAlojamentosAtivos,
    planejamentosSemanais,
    primeiraSemanaInicio,
    primeiraSemanaFim,
    segundaSemanaInicio,
    segundaSemanaFim,
    terceiraSemanaInicio,
    terceiraSemanaFim,
    quartaSemanaInicio,
    quartaSemanaFim,
    primeiraSemana,
    segundaSemana,
    terceiraSemana,
    quartaSemana
  } = useSelector((state: ApplicationStore) => state.planejamentoSemanal)
  const dispatch = useDispatch()
  const [tabValue, setTabValue] = useState<number>(0)
  const [saveButtonPressed, setSaveButtonPressed] = useState<boolean>(false)
  const saveNewValue = (params: any) => {
    const field = params.column.colId
    const newRow = { ...params.data }
    newRow[field] = params.newValue

    const findedAlojamentoAtivo = alojamentosAtivos?.find(
      (aa: AlojamentoResumo) => aa.idGalpao === newRow.idGalpao && aa.nroLote === newRow.nroLote
    )

    if (field === 'dataSaidaFormatada') {
      setSaveButtonPressed(false)
      //const newLeaveDate = moment(new Date(params.newValue)).utc().format() // Addes time to the date to comparison
      const newLeaveDate = params.newValue
      // Does not consider sunday
      if (moment(newLeaveDate).isoWeekday() === 7) return false

      // Calculates predicted age
      newRow['idadeSaida'] = moment(newRow.dataSaidaFormatada).diff(
        moment(newRow.dataAlojamento),
        'days'
      )

      if (findedAlojamentoAtivo) {
        newRow['animaisPreditos'] = findedAlojamentoAtivo.predicao.find(
          (fa: any) => fa.idadePredita === newRow.idadeSaida
        )?.nroAvesPreditas
        newRow['pesoPredito'] = findedAlojamentoAtivo.predicao.find(
          (fa: any) => fa.idadePredita === newRow.idadeSaida
        )?.pesoPredito
        if (newRow.idIntegrador === newRow.idIntegradorDestino) {
          newRow['lucro'] = findedAlojamentoAtivo.predicao.find(
            (fa: any) => fa.idadePredita === newRow.idadeSaida
          )?.lucroPredito
        } else newRow['lucro'] = undefined
        newRow['consumo'] = findedAlojamentoAtivo.predicao.find(
          (fa: any) => fa.idadePredita === newRow.idadeSaida
        )?.consumoPredito
      }

      if (newLeaveDate >= terceiraSemanaInicio && newLeaveDate <= terceiraSemanaFim) {
        const index = terceiraSemanaCopy.findIndex((d) => d.id === params.data.id)

        // Change the movement type just if the line does not exists in original table not copy one and was not dragable from housing
        if (
          newRow['movementType'] !== MovementTypeEnum.fromHousings &&
          newRow['movementType'] !== MovementTypeEnum.fromIntegrator
        ) {
          if (terceiraSemana.findIndex((d: any) => d.id === params.data.id) === -1)
            newRow['movementType'] = MovementTypeEnum.inTheWeek
          else newRow['movementType'] = null
        }

        // IF SOURCE TABLE (ORIGIN TABLE) IS DIFFERENT FROM TARGET ONE
        if (index === -1) {
          const index = quartaSemanaCopy.findIndex((d) => d.id === params.data.id)
          quartaSemanaCopy.splice(index, 1)
          setQuartaSemanaCopy([...quartaSemanaCopy])
          terceiraSemanaCopy.push(newRow)
        } else terceiraSemanaCopy[index] = newRow
        const terceiraSemanaCopyOrdered = _.orderBy(terceiraSemanaCopy, 'dataSaidaFormatada')
        setTerceiraSemanaCopy([...terceiraSemanaCopyOrdered])
      } else if (newLeaveDate >= quartaSemanaInicio && newLeaveDate <= quartaSemanaFim) {
        console.log('akiee')
        const index = quartaSemanaCopy.findIndex((d) => d.id === params.data.id)

        // Change the movement type just if the line does not exists in original table not copy one and was not dragable from housing
        if (
          newRow['movementType'] !== MovementTypeEnum.fromHousings &&
          newRow['movementType'] !== MovementTypeEnum.fromIntegrator
        ) {
          if (quartaSemana.findIndex((d) => d.id === params.data.id) === -1)
            newRow['movementType'] = MovementTypeEnum.inTheWeek
          else newRow['movementType'] = null
        }

        if (index === -1) {
          // IF SOURCE TABLE (ORIGIN TABLE) IS DIFFERENT FROM TARGET ONE
          const index = terceiraSemanaCopy.findIndex((d) => d.id === params.data.id)
          terceiraSemanaCopy.splice(index, 1)
          setTerceiraSemanaCopy([...terceiraSemanaCopy])
          quartaSemanaCopy.push(newRow)
        } else quartaSemanaCopy[index] = newRow
        const quartaSemanaCopyOrdered = _.orderBy(quartaSemanaCopy, 'dataSaidaFormatada')
        setQuartaSemanaCopy([...quartaSemanaCopyOrdered])
      }
    } else if (field === 'idIntegradorDestino') {
      // Just updates the color if original idIntegrator was different from new one
      if (newRow.idIntegrador !== newRow.idIntegradorDestino) {
        newRow['movementType'] = MovementTypeEnum.fromIntegrator
        newRow.lucro = undefined
      } else {
        newRow['movementType'] = null
        if (findedAlojamentoAtivo)
          newRow.lucro = findedAlojamentoAtivo.predicao.find(
            (fa: any) => fa.idadePredita === newRow.idadeSaida
          ).lucroPredito
      }
      const index = terceiraSemanaCopy.findIndex((d) => d.id === params.data.id)
      // FIND WHERE IS THE NEW DATA
      if (index === -1) {
        // DATA IS ON FOUR TABLE
        const index = quartaSemanaCopy.findIndex((d) => d.id === params.data.id)
        quartaSemanaCopy[index] = newRow
        const quartaSemanaCopyOrdered = _.orderBy(quartaSemanaCopy, 'dataSaidaFormatada')
        setQuartaSemanaCopy([...quartaSemanaCopyOrdered])
      } else {
        // DATA IS ON THIRD TABLE
        terceiraSemanaCopy[index] = newRow
        const terceiraSemanaCopyOrdered = _.orderBy(terceiraSemanaCopy, 'dataSaidaFormatada')
        setTerceiraSemanaCopy([...terceiraSemanaCopyOrdered])
      }
    }
    return false
  }

  const moveLotFromHousing = (params: any) => {
    const field = params.column.colId
    const newRow = { ...params.data }
    newRow[field] = params.newValue
    const index = housingData.findIndex((h) => h.id === params.data.id)

    if (field === 'idIntegradorDestino') {
      if (!newRow.dataSaidaFormatada || !newRow.idadeSaida) {
        newRow['movementType'] = MovementTypeEnum.fromIntegrator
        housingData[index] = newRow
      }
    }

    if (newRow.dataSaidaFormatada) {
      newRow['movementType'] = MovementTypeEnum.fromHousings
      setSaveButtonPressed(false)
      const newLeaveDate = moment(new Date(params.newValue)).utc().format() // Addes time to the date to comparison
      // Does not consider sunday
      if (moment(newLeaveDate).isoWeekday() === 6) return false

      // Calculates predicted age
      newRow['idadeSaida'] = moment(newRow.dataSaidaFormatada).diff(
        moment(newRow.dataAlojamento),
        'days'
      )

      if (newRow.idIntegrador === newRow.idIntegradorDestino && newRow.predicao.length) {
        newRow['animaisPreditos'] = newRow.predicao.find(
          (fa: any) => fa.idadePredita === newRow.idadeSaida
        )?.nroAvesPreditas
        newRow['pesoPredito'] = newRow.predicao.find(
          (fa: any) => fa.idadePredita === newRow.idadeSaida
        )?.pesoPredito
        if (newRow.idIntegrador === newRow.idIntegradorDestino) {
          newRow['lucro'] = newRow.predicao.find(
            (fa: any) => fa.idadePredita === newRow.idadeSaida
          )?.lucroPredito
        } else newRow['lucro'] = undefined
        newRow['consumo'] = newRow.predicao.find(
          (fa: any) => fa.idadePredita === newRow.idadeSaida
        )?.consumoPredito
      }

      // Removes data from housing
      housingData.splice(index, 1)

      if (newLeaveDate >= terceiraSemanaInicio && newLeaveDate <= terceiraSemanaFim) {
        terceiraSemanaCopy.push(newRow)
        const terceiraSemanaCopyOrdered = _.orderBy(terceiraSemanaCopy, 'dataSaidaFormatada')
        setTerceiraSemanaCopy([...terceiraSemanaCopyOrdered])
      } else if (newLeaveDate >= quartaSemanaInicio && newLeaveDate <= quartaSemanaFim) {
        quartaSemanaCopy.push(newRow)
        const quartaSemanaCopyOrdered = _.orderBy(quartaSemanaCopy, 'dataSaidaFormatada')
        setQuartaSemanaCopy([...quartaSemanaCopyOrdered])
      }
    }
    setHousingData([...housingData])
    return false
  }

  const housingColumns: ColDef[] = [
    { headerName: t('movement_type'), field: 'movementType', rowDrag: true },
    {
      headerValueGetter: () => t('integrator'),
      field: 'idIntegradorDestino',
      cellEditor: 'agSelectCellEditor',
      editable: true,
      cellEditorParams: {
        values: [IntegratorEnum.SSA, IntegratorEnum.NV]
      },
      valueSetter: moveLotFromHousing
    },
    {
      headerValueGetter: () => t('slaughterDate'),
      field: 'dataSaidaFormatada',
      editable: true,
      cellDataType: 'dateString',
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: moment(terceiraSemanaInicio).utc().format('YYYY-MM-DD'),
        max: moment(quartaSemanaFim).utc().format('YYYY-MM-DD')
      },
      valueSetter: moveLotFromHousing
    },
    { headerName: t('unity'), field: 'idGranja' },
    { headerName: t('core'), field: 'numeroNucleo' },
    { headerName: t('aviary'), field: 'numeroGalpao' },
    { headerName: t('lot'), field: 'nroLote' },
    { headerName: t('sex'), field: 'sexo' },
    { headerName: t('uf'), field: 'uf' },
    { headerName: t('city'), field: 'municipio' },
    {
      headerName: t('distanceNV'),
      field: 'distanciaAbatedouroNV',
      valueFormatter: (params) => Format(params.data.distanciaAbatedouroNV)
    },
    {
      headerName: t('distanceSSA'),
      field: 'distanciaAbatedouroITA',
      valueFormatter: (params) => Format(params.data.distanciaAbatedouroITA)
    },
    {
      headerName: t('averageWeight'),
      field: 'pesoMedio',
      valueFormatter: (params) => Format(params.data.pesoMedio)
    },
    { headerName: t('housingDate'), field: 'dataAlojamento' },
    { headerName: t('age'), field: 'idade' },
    {
      headerName: t('housingPoultryNumber'),
      field: 'qtdAvesAlojadas',
      valueFormatter: (params) => Format(params.data.qtdAvesAlojadas)
    },
    {
      headerName: t('mortality'),
      field: 'mortalidade',
      valueFormatter: (params) => Format(params.data.mortalidade)
    },
    {
      headerName: t('leftover'),
      field: 'saldo',
      valueFormatter: (params) => Format(params.data.saldo)
    }
  ]

  const removeLotFromPlanning = (params: any) => {
    params.api.applyTransaction({
      remove: [params.node.data]
    })

    const data = { ...params.node.data }
    data.movementType = MovementTypeEnum.removedFromPlanning

    const transaction = {
      add: [data]
    }

    if (housingTableApi) housingTableApi?.applyTransaction(transaction)
  }

  const RemoveLotButtonComponent = (params: CustomCellRendererProps) => {
    return (
      <IconButton onClick={() => removeLotFromPlanning(params)}>
        <DeleteOutline style={{ color: colors(Themes.LIGHT, Colors.accentDark) }} />
      </IconButton>
    )
  }

  // In this table was not working HeaderName, so it was changed by headerValueGetter
  const planningColumnsDraggable: ColDef[] = [
    { headerValueGetter: () => t('movement_type'), field: 'movementType', rowDrag: true },
    {
      headerValueGetter: () => t('integrator'),
      field: 'idIntegradorDestino',
      cellEditor: 'agSelectCellEditor',
      editable: true,
      cellEditorParams: {
        values: [IntegratorEnum.SSA, IntegratorEnum.NV]
      },
      valueSetter: saveNewValue
    },
    {
      headerValueGetter: () => t('slaughterDate'),
      field: 'dataSaidaFormatada',
      editable: true,
      cellDataType: 'dateString',
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: moment(terceiraSemanaInicio).utc().format('YYYY-MM-DD'),
        max: moment(quartaSemanaFim).utc().format('YYYY-MM-DD')
      },
      valueSetter: saveNewValue,
      cellClassRules: {
        'error-cell': (params) =>
          saveButtonPressed && !params.value && params.data.origemDado !== OrigemDadoEnum.total
      }
    },
    { headerValueGetter: () => t('unity'), field: 'idGranja' },
    { headerValueGetter: () => t('core'), field: 'numeroNucleo' },
    { headerValueGetter: () => t('aviary'), field: 'numeroGalpao' },
    { headerValueGetter: () => t('lot'), field: 'nroLote' },
    { headerValueGetter: () => t('sex'), field: 'sexo' },
    { headerValueGetter: () => t('uf'), field: 'uf' },
    { headerValueGetter: () => t('city'), field: 'municipio' },
    {
      headerValueGetter: () => t('distanceNV'),
      field: 'distanciaAbatedouroNV',
      valueFormatter: (params) => Format(params.data.distanciaAbatedouroNV)
    },
    {
      headerValueGetter: () => t('distanceSSA'),
      field: 'distanciaAbatedouroITA',
      valueFormatter: (params) => Format(params.data.distanciaAbatedouroITA)
    },
    {
      headerValueGetter: () => t('weight'),
      field: 'pesoPredito',
      valueFormatter: (params) => Format(params.data.pesoPredito)
    },
    { headerValueGetter: () => t('age'), field: 'idadeSaida' },
    {
      headerValueGetter: () => t('predictedPoultryNumber'),
      field: 'animaisPreditos',
      valueFormatter: (params) => Format(params.data.animaisPreditos)
    },
    {
      headerValueGetter: () => t('consumption'),
      field: 'consumo',
      valueFormatter: (params) => Format(params.data.consumo)
    },
    {
      headerValueGetter: () => t('profit'),
      field: 'lucro',
      valueFormatter: (params) => Format(params.data.lucro)
    },
    {
      field: 'remove',
      headerValueGetter: () => t('remove_lot_from_planning'),
      cellRenderer: RemoveLotButtonComponent
    }
  ]
  const planningColumns: ColDef[] = [
    {
      headerName: t('integrator'),
      field: 'idIntegradorDestino'
    },
    { headerName: t('slaughterDate'), field: 'dataSaidaFormatada' },
    { headerName: t('unity'), field: 'idGranja' },
    { headerName: t('core'), field: 'numeroNucleo' },
    { headerName: t('aviary'), field: 'numeroGalpao' },
    { headerName: t('lot'), field: 'nroLote' },
    { headerName: t('sex'), field: 'sexo' },
    { headerName: t('uf'), field: 'uf' },
    { headerName: t('city'), field: 'municipio' },
    {
      headerName: t('distanceNV'),
      field: 'distanciaAbatedouroNV',
      valueFormatter: (params) => Format(params.data.distanciaAbatedouroNV)
    },
    {
      headerName: t('distanceSSA'),
      field: 'distanciaAbatedouroITA',
      valueFormatter: (params) => Format(params.data.distanciaAbatedouroITA)
    },
    {
      headerName: t('weight'),
      field: 'pesoPredito',
      valueFormatter: (params) => Format(params.data.pesoPredito)
    },
    { headerName: t('age'), field: 'idadeSaida' },
    {
      headerName: t('predictedPoultryNumber'),
      field: 'animaisPreditos',
      valueFormatter: (params) => Format(params.data.animaisPreditos)
    },
    {
      headerName: t('consumption'),
      field: 'consumo',
      valueFormatter: (params) => Format(params.data.consumo)
    },
    {
      headerName: t('profit'),
      field: 'lucro',
      valueFormatter: (params) => Format(params.data.lucro)
    }
  ]
  const summaryTableColumns: ColDef[] = [
    { headerName: t('integrator'), field: 'idIntegrador' },
    { headerName: t('slaughterDate'), field: 'data' },
    { headerName: t('day_of_the_week'), field: 'diaSemana' },
    {
      headerName: t('predictedPoultryNumber'),
      field: 'animaisPreditos',
      valueFormatter: (params) => Format(params.data.animaisPreditos)
    },
    {
      headerName: t('weight'),
      field: 'pesoPredito',
      valueFormatter: (params) => Format(params.data.pesoPredito)
    },
    { headerName: t('age'), field: 'idadeSaida' },
    {
      headerName: t('profit'),
      field: 'lucro',
      valueFormatter: (params) => Format(params.data.lucro)
    },
    {
      headerName: t('consumption'),
      field: 'consumo',
      valueFormatter: (params) => Format(params.data.consumo)
    }
  ]

  const [firstWeekSummary, setFirstWeekSummary] = useState<SummaryTableData[]>([])
  const [secondWeekSummary, setSecondWeekSummary] = useState<SummaryTableData[]>([])
  const [thirdWeekSummary, setThirdWeekSummary] = useState<SummaryTableData[]>([])
  const [fourWeekSummary, setFourWeekSummary] = useState<SummaryTableData[]>([])
  const [summaryData, setSummaryData] = useState<SummaryTableData[]>([])
  const [summaryDataCP, setSummaryDataCP] = useState<SummaryTableData[]>([])
  const [summaryTableApi, setSummaryTableApi] = useState<GridApi | null>(null)
  const [housingData, setHousingData] = useState<AlojamentoResumo[]>([])
  const [housingTableApi, setHousingTableApi] = useState<GridApi | null>(null)
  const [firstTableApi, setFirstTableApi] = useState<GridApi | null>(null)
  const [secondTableApi, setSecondTableApi] = useState<GridApi | null>(null)
  const [thirdTableApi, setThirdTableApi] = useState<GridApi | null>(null)
  const [fourTableApi, setFourTableApi] = useState<GridApi | null>(null)
  const [gridDropZoneAdded, setGridDropZoneAdded] = useState<boolean>(false)
  const [firstTableTotals, setFirstTableTotals] = useState<Array<Record<string, any>>>([])
  const [secondTableTotals, setSecondTableTotals] = useState<Array<Record<string, any>>>([])
  const [thirdTableTotals, setThirdTableTotals] = useState<Array<Record<string, any>>>([])
  const [fourTableTotals, setFourTableTotals] = useState<Array<Record<string, any>>>([])
  const [terceiraSemanaCopy, setTerceiraSemanaCopy] = useState<PlanejamentoSemanal[]>([])
  const [quartaSemanaCopy, setQuartaSemanaCopy] = useState<PlanejamentoSemanal[]>([])
  const ExcelFile = ReactExport.ExcelFile
  const ExcelSheet = ReactExport.ExcelSheet
  const [fileLoading, setFileLoading] = React.useState<boolean>(false)
  const [fileColumns, setFileColumns] = useState<Array<Record<string, any>>>([])
  const [open, setOpen] = React.useState(false)

  // The translation was not working if put this variable on types or helpers file
  const tablesText = {
    noRowsToShow: t('table:no_rows_to_show'),
    loading: t('loading'),
    // Set Filter
    selectAll: t('table:select_all'),
    selectAllSearchResults: t('table:select_all_search_results'),
    addCurrentSelectionToFilter: t('table:add_current_selection_to_filter'),
    searchOoo: t('table:search'),
    blanks: t('table:blank'),
    noMatches: t('table:no_matches'),

    // Number Filter & Text Filter
    filterOoo: t('table:filter'),
    equals: t('table:equals'),
    notEqual: t('table:not_equal'),
    blank: t('table:blank'),
    notBlank: t('table:not_blank'),
    empty: t('table:choose_one'),

    // Number Filter
    lessThan: t('table:less_than'),
    greaterThan: t('table:greater_than'),
    lessThanOrEqual: t('table:less_than_or_equal_to'),
    greaterThanOrEqual: t('table:greater_than_or_equal'),
    inRange: t('table:in_range'),
    inRangeStart: t('table:from'),
    inRangeEnd: t('table:to'),

    // Text Filter
    contains: t('table:contains'),
    notContains: t('table:not_contains'),
    startsWith: t('table:starts_with'),
    endsWith: t('table:ends_with'),

    // Date Filter
    dateFormatOoo: 'yyyy-mm-dd',
    before: t('table:before'),
    after: t('table:after'),

    // Filter Conditions
    andCondition: t('table:and'),
    orCondition: t('table:or'),

    // Filter Buttons
    applyFilter: t('table:apply_filter'),
    resetFilter: t('table:reset_filter'),
    clearFilter: t('table:clear_filter'),
    cancelFilter: t('table:cancel_filter'),

    // Filter Titles
    textFilter: t('table:text_filter'),
    numberFilter: t('table:number_filter'),
    dateFilter: t('table:date_filter'),
    setFilter: t('table:set_filter')
  }

  const columnsOfFile = [
    { title: t('movement_type'), field: 'movementType' },
    {
      title: t('integrator'),
      field: 'idIntegradorDestino'
    },
    {
      title: t('slaughterDate'),
      field: 'dataSaidaFormatada'
    },
    { title: t('unity'), field: 'idGranja' },
    { title: t('core'), field: 'numeroNucleo' },
    { title: t('aviary'), field: 'numeroGalpao' },
    { title: t('lot'), field: 'nroLote' },
    { title: t('sex'), field: 'sexo' },
    { title: t('uf'), field: 'uf' },
    { title: t('city'), field: 'municipio' },
    { title: t('distanceNV'), field: 'distanciaAbatedouroNV' },
    { title: t('distanceSSA'), field: 'distanciaAbatedouroITA' },
    { title: t('weight'), field: 'pesoPredito' },
    { title: t('age'), field: 'idadeSaida' },
    { title: t('predictedPoultryNumber'), field: 'animaisPreditos' },
    { title: t('consumption'), field: 'consumo' },
    { title: t('profit'), field: 'lucro' }
  ]

  if (!fileColumns.length && columnsOfFile.length) setFileColumns(columnsOfFile)

  const [allFileData, setAllFileData] = React.useState<any>([
    {
      columns: columnsOfFile,
      data: []
    }
  ])

  const [currentFileData, setCurrentFileData] = React.useState<any>([
    {
      columns: columnsOfFile,
      data: []
    }
  ])

  const [firstWeekFileData, setFirstWeekFileData] = React.useState<any>([
    {
      columns: columnsOfFile,
      data: []
    }
  ])

  const [secondWeekFileData, setSecondWeekFileData] = React.useState<any>([
    {
      columns: columnsOfFile,
      data: []
    }
  ])

  const [thirdWeekFileData, setThirdWeekFileData] = React.useState<any>([
    {
      columns: columnsOfFile,
      data: []
    }
  ])

  const [fourWeekFileData, setFourWeekFileData] = React.useState<any>([
    {
      columns: columnsOfFile,
      data: []
    }
  ])

  const eSummaryTableGrid = useRef<AgGridReact>(null)
  const eHousingTableGrid = useRef<AgGridReact>(null)
  const eThirdTableGrid = useRef<AgGridReact>(null)
  const eFourTableGrid = useRef<AgGridReact>(null)
  const eAllDataExcelFile = useRef<any>(null)
  const eCurrentDataExcelFile = useRef<ReactExport.ExcelFile>(null)

  const defaultColDef: ColDef = {
    initialWidth: 200,
    wrapHeaderText: true,
    autoHeaderHeight: true,
    filter: true,
    floatingFilter: true,
    suppressHeaderMenuButton: true,
    suppressHeaderFilterButton: true,
    suppressHeaderContextMenu: true
  }

  const restoreButtonTooltip = t('components:button:restore') ?? ''
  const downloadAllPlanningButtonTooltip = t('components:button:download_all_weekly_planning') ?? ''
  const downloadCurrentPlanningButtonTooltip =
    t('components:button:download_current_weekly_planning') ?? ''
  const saveButtonTooltip = t('components:button:save') ?? ''
  // const runPoultrytraderButtonTooltip = t('tool_under_development') ?? ''

  const getRowId = (params: GetRowIdParams) => params.data.id

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue)

    const summaryTablefilterComponent = summaryTableApi?.getFilterInstance('idIntegrador')
    summaryTablefilterComponent?.setModel({
      type: 'text',
      filter: newValue === 0 ? 'NV' : 'SSA'
    })

    const firstTablefilterComponent = firstTableApi?.getFilterInstance('idIntegradorDestino')
    firstTablefilterComponent?.setModel({
      type: 'text',
      filter: newValue === 0 ? 'NV' : 'SSA'
    })
    const secondTablefilterComponent = secondTableApi?.getFilterInstance('idIntegradorDestino')
    secondTablefilterComponent?.setModel({
      type: 'text',
      filter: newValue === 0 ? 'NV' : 'SSA'
    })
    const thirdTablefilterComponent = thirdTableApi?.getFilterInstance('idIntegradorDestino')
    thirdTablefilterComponent?.setModel({
      type: 'text',
      filter: newValue === 0 ? 'NV' : 'SSA'
    })
    const fourTablefilterComponent = fourTableApi?.getFilterInstance('idIntegradorDestino')
    fourTablefilterComponent?.setModel({
      type: 'text',
      filter: newValue === 0 ? 'NV' : 'SSA'
    })
  }

  const onGridReady = (table: string, params: GridReadyEvent) => {
    params.api.sizeColumnsToFit()
    params.api.showLoadingOverlay()
    const filterComponent = params.api.getFilterInstance('idIntegradorDestino')
    if (table !== 'housing') {
      filterComponent?.setModel({
        type: 'text',
        filter: tabValue === 0 ? 'NV' : 'SSA'
      })
    }
    switch (table) {
      case 'housing':
        setHousingTableApi(params.api)
        break
      case 'first':
        setFirstTableApi(params.api)
        break
      case 'second':
        setSecondTableApi(params.api)
        break
      case 'third':
        setThirdTableApi(params.api)
        break
      case 'four':
        setFourTableApi(params.api)
        break
    }
  }

  const addGridDropZone = (table: string, api: GridApi) => {
    if (table === 'housing' && housingTableApi) {
      if (thirdTableApi) {
        const thirdTableDropZone = getDropZoneParams(
          document.getElementById('thirdTable'),
          MovementTypeEnum.fromHousings,
          housingTableApi,
          thirdTableApi
        )
        if (thirdTableDropZone) api.addRowDropZone(thirdTableDropZone)
      }

      if (fourTableApi) {
        const fourTableDropZone = getDropZoneParams(
          document.getElementById('fourTable'),
          MovementTypeEnum.fromHousings,
          housingTableApi,
          fourTableApi
        )
        if (fourTableDropZone) api.addRowDropZone(fourTableDropZone)
      }
    } else if (table === 'third' && thirdTableApi) {
      if (housingTableApi) {
        const housingTableDropZone = getDropZoneParams(
          document.getElementById('housingTable'),
          MovementTypeEnum.removedFromPlanning,
          thirdTableApi,
          housingTableApi
        )
        if (housingTableDropZone) api.addRowDropZone(housingTableDropZone)
      }

      if (fourTableApi) {
        const fourTableDropZone = getDropZoneParams(
          document.getElementById('fourTable'),
          MovementTypeEnum.inTheWeek,
          thirdTableApi,
          fourTableApi
        )
        if (fourTableDropZone) api.addRowDropZone(fourTableDropZone)
      }
    } else if (table === 'four' && fourTableApi) {
      if (housingTableApi) {
        const housingTableDropZone = getDropZoneParams(
          document.getElementById('housingTable'),
          MovementTypeEnum.removedFromPlanning,
          fourTableApi,
          housingTableApi
        )
        if (housingTableDropZone) api.addRowDropZone(housingTableDropZone)
      }

      if (thirdTableApi) {
        const thirdTableDropZone = getDropZoneParams(
          document.getElementById('thirdTable'),
          MovementTypeEnum.inTheWeek,
          fourTableApi,
          thirdTableApi
        )
        if (thirdTableDropZone) api.addRowDropZone(thirdTableDropZone)
      }
    }
    setGridDropZoneAdded(true)
  }

  useEffect(() => {
    if (housingTableApi && thirdTableApi && fourTableApi && !gridDropZoneAdded) {
      addGridDropZone('housing', housingTableApi)
      addGridDropZone('third', thirdTableApi)
      addGridDropZone('four', fourTableApi)
    }
  })

  const getRowStyle = useCallback((params: RowClassParams): RowStyle | undefined => {
    if (params.node.rowPinned) {
      return { fontWeight: 'bold' }
    }
  }, [])

  useEffect(() => {
    if (!alojamentosAtivos) {
      setHousingData([])
      dispatch(getAlojamentosAtivos())
    }
  }, [alojamentosAtivos])

  useEffect(() => {
    if (!planejamentosSemanais) {
      setTerceiraSemanaCopy([])
      setQuartaSemanaCopy([])
      dispatch(getPlanejamentosSemanais())

      // Clear file data when planning data change
      firstWeekFileData[0].data = []
      setFirstWeekFileData(firstWeekFileData)
      secondWeekFileData[0].data = []
      setSecondWeekFileData(secondWeekFileData)
      thirdWeekFileData[0].data = []
      setThirdWeekFileData(thirdWeekFileData)
      fourWeekFileData[0].data = []
      setFourWeekFileData(fourWeekFileData)
    }
  }, [planejamentosSemanais])

  useEffect(() => {
    if (
      planejamentosSemanais?.length &&
      alojamentosAtivos?.length &&
      !filteredAlojamentosAtivos?.length
    )
      dispatch(filterAlojamentosByPlanejamento())
  }, [planejamentosSemanais, alojamentosAtivos])

  // useEffect(() => {
  //   if (primeiraSemana.length) {
  //     // const totals = generatesTotals(primeiraSemana)
  //     // setFirstTableTotals([totals])

  //     const summaryData = generateSummaryData([...primeiraSemana])
  //     setFirstWeekSummary([...summaryData])
  //     setSummaryData([
  //       ...summaryData,
  //       ...secondWeekSummary,
  //       ...thirdWeekSummary,
  //       ...fourWeekSummary
  //     ])
  //   }
  // }, [primeiraSemana])

  // useEffect(() => {
  //   if (segundaSemana.length) {
  //     // const totals = generatesTotals(segundaSemana)
  //     // setSecondTableTotals([totals])

  //     const summaryData = generateSummaryData([...segundaSemana])
  //     setSecondWeekSummary([...summaryData])
  //   }
  // }, [segundaSemana])

  useEffect(() => {
    if (terceiraSemana.length && !terceiraSemanaCopy.length)
      setTerceiraSemanaCopy([...terceiraSemana])
  }, [terceiraSemana])

  // useEffect(() => {
  //   if (terceiraSemanaCopy.length && !thirdWeekSummary.length) {
  //     // const totals = generatesTotals([...terceiraSemanaCopy])
  //     // setThirdTableTotals([totals])

  //     const summaryData = generateSummaryData(terceiraSemanaCopy)
  //     setThirdWeekSummary([...summaryData])
  //   }
  // }, [terceiraSemanaCopy])

  useEffect(() => {
    if (quartaSemana.length && !quartaSemanaCopy.length) setQuartaSemanaCopy([...quartaSemana])
  }, [quartaSemana])

  // useEffect(() => {
  //   if (quartaSemanaCopy.length && !fourWeekSummary.length) {
  //     // const totals = generatesTotals(quartaSemanaCopy)
  //     // setFourTableTotals([totals])

  //     const summaryData = generateSummaryData([...quartaSemanaCopy])
  //     setFourWeekSummary([...summaryData])
  //   }
  // }, [quartaSemanaCopy])

  useEffect(() => {
    if (summaryData?.length && !summaryDataCP.length) {
      setSummaryDataCP([...summaryData])
    }
  }, [summaryData])

  const updateTotals = (data: PlanejamentoSemanal[]) => {
    // return generatesTotals(
    //   data.filter((d) =>
    //     tabValue === 0
    //       ? d.idIntegrador === 'NV'
    //       : d.idIntegrador === 'SSA'
    //   )
    // )
    return []
  }

  const getNodes = (api: GridApi | null) => {
    if (api) {
      const data: PlanejamentoSemanal[] = []
      api?.forEachNode((rowNode: any) => {
        data.push(rowNode.data)
      })
      return data
    }
  }

  useEffect(() => {
    if (filteredAlojamentosAtivos.length) setHousingData([...filteredAlojamentosAtivos])
  }, [filteredAlojamentosAtivos])

  const restore = () => {
    setHousingData(filteredAlojamentosAtivos)
    setTerceiraSemanaCopy([...terceiraSemana])
    setQuartaSemanaCopy([...quartaSemana])
  }

  const save = () => {
    handleClose()
    const terceiraSemanaData: PlanejamentoSemanal[] | undefined = []
    thirdTableApi?.forEachNode((rowNode) => {
      return terceiraSemanaData?.push(rowNode.data)
    })

    const quartaSemanaData: PlanejamentoSemanal[] | undefined = []

    fourTableApi?.forEachNode((rowNode) => {
      return quartaSemanaData?.push(rowNode.data)
    })

    const housing: AlojamentoResumo[] | undefined = []

    housingTableApi?.forEachNode((rowNode) => {
      return housing?.push(rowNode.data)
    })

    const newData = []
    if (terceiraSemanaData) newData.push(...terceiraSemanaData)
    if (quartaSemanaData) newData.push(...quartaSemanaData)

    newData?.map((nd: PlanejamentoSemanal | undefined) => {
      if (!nd?.dataSaidaFormatada) return
    })

    const planningToRemove = housing?.filter((hd) => {
      return hd.movementType === MovementTypeEnum.removedFromPlanning
    })

    const planningIdsToRemove: number[] | undefined = []
    planningToRemove?.map((h) => {
      const finded = planejamentosSemanais.find(
        (ps: PlanejamentoSemanal) => ps.idGalpao === h.idGalpao && ps.nroLote === h.nroLote
      )
      if (finded) planningIdsToRemove.push(finded.id)
    })

    console.log('planningIdsToRemove: ', planningIdsToRemove)
    // Verifyes if there are data without slaughterhouse date
    setSaveButtonPressed(true)

    const novosPlanejamentos: NovoPlanejamentoSemanal[] = []
    newData?.map((nd: PlanejamentoSemanal | undefined) => {
      if (nd && nd?.origemDado !== OrigemDadoEnum.total)
        novosPlanejamentos.push({
          idIntegrador: nd.idIntegradorDestino,
          dataSaida: nd.dataSaidaFormatada,
          idGalpao: nd.idGalpao,
          nroLote: nd.nroLote,
          idadeSaida: nd.idadeSaida,
          animaisPreditos: nd.animaisPreditos,
          pesoPredito: nd.pesoPredito,
          lucro: nd.lucro,
          consumo: nd.consumo,
          custo: nd.custo
        })
    })
    console.log(novosPlanejamentos)
    if (novosPlanejamentos?.length) dispatch(savePlanejamentosSemanal(novosPlanejamentos))
    if (planningIdsToRemove?.length) dispatch(deletePlanejamentoSemanal(planningIdsToRemove))
  }

  // To download xlsx, all data should be prepared before
  // useEffect(() => {
  //   if (firstTableApi) {
  //     const firstWeekData: PlanejamentoSemanal[] | undefined = firstTableApi
  //       ?.getRenderedNodes()
  //       ?.map((ts) => ts.data)
  //     firstWeekFileData[0].data = getFileData(firstWeekData)
  //     setFirstWeekFileData(firstWeekFileData)
  //   }
  // }, [firstTableApi?.getRenderedNodes()])

  // useEffect(() => {
  //   if (secondTableApi) {
  //     const secondWeekData: PlanejamentoSemanal[] | undefined = secondTableApi
  //       ?.getRenderedNodes()
  //       ?.map((ts) => ts.data)
  //     secondWeekFileData[0].data = getFileData(secondWeekData)
  //     setSecondWeekFileData(secondWeekFileData)
  //   }
  // }, [secondTableApi?.getRenderedNodes()])

  // useEffect(() => {
  //   if (thirdTableApi) {
  //     const thirdWeekData: PlanejamentoSemanal[] | undefined = thirdTableApi
  //       ?.getRenderedNodes()
  //       ?.map((ts) => ts.data)

  //     thirdWeekFileData[0].data = getFileData(thirdWeekData)
  //     setThirdWeekFileData(thirdWeekFileData)
  //   }
  // }, [thirdTableApi?.getRenderedNodes()])

  // useEffect(() => {
  //   if (fourTableApi) {
  //     const fourWeekData: PlanejamentoSemanal[] | undefined = fourTableApi
  //       ?.getRenderedNodes()
  //       ?.map((ts) => ts.data)

  //     fourWeekFileData[0].data = getFileData(fourWeekData)
  //     setFourWeekFileData(fourWeekFileData)
  //   }
  // }, [fourTableApi?.getRenderedNodes()])

  // useEffect(() => {
  //   const data = [
  //     ...firstWeekFileData,
  //     ...secondWeekFileData,
  //     ...thirdWeekFileData,
  //     ...fourWeekFileData
  //   ]

  //   setAllFileData(data)
  // }, [firstTableApi, secondTableApi, thirdTableApi, fourTableApi])

  const downloadAllData = () => {
    const firstWeekData: PlanejamentoSemanal[] | undefined = []
    firstTableApi?.forEachNode((rowNode) => {
      return firstWeekData?.push(rowNode.data)
    })

    firstWeekFileData[0].data = getFileData(firstWeekData)
    // const firstWeekTotalFileData = getFileTotals(firstTableTotals)
    // firstWeekFileData[0].data.push(firstWeekTotalFileData)
    setFirstWeekFileData(firstWeekFileData)

    const secondWeekData: PlanejamentoSemanal[] | undefined = []
    secondTableApi?.forEachNode((rowNode) => {
      return secondWeekData?.push(rowNode.data)
    })

    secondWeekFileData[0].data = getFileData(secondWeekData)
    // const secondWeekTotalFileData = getFileTotals(secondTableTotals)
    // secondWeekFileData[0].data.push(secondWeekTotalFileData)
    setSecondWeekFileData(secondWeekFileData)

    const thirdWeekData: PlanejamentoSemanal[] | undefined = []
    thirdTableApi?.forEachNode((rowNode) => {
      return thirdWeekData?.push(rowNode.data)
    })

    thirdWeekFileData[0].data = getFileData(thirdWeekData)
    // const thirdWeekTotalFileData = getFileTotals(thirdTableTotals)
    // thirdWeekFileData[0].data.push(thirdWeekTotalFileData)
    setThirdWeekFileData(thirdWeekFileData)

    const fourWeekData: PlanejamentoSemanal[] | undefined = []
    fourTableApi?.forEachNode((rowNode) => {
      return fourWeekData?.push(rowNode.data)
    })

    fourWeekFileData[0].data = getFileData(fourWeekData)
    // const fourWeekTotalFileData = getFileTotals(fourTableTotals)
    // fourWeekFileData[0].data.push(fourWeekTotalFileData)
    setFourWeekFileData(fourWeekFileData)

    const data = [
      ...firstWeekFileData,
      ...secondWeekFileData,
      ...thirdWeekFileData,
      ...fourWeekFileData
    ]

    setAllFileData(data)
  }

  const downloadCurrentData = () => {
    const thirdWeekData: PlanejamentoSemanal[] | undefined = []
    thirdTableApi?.forEachNode((rowNode) => {
      return thirdWeekData?.push(rowNode.data)
    })

    thirdWeekFileData[0].data = getFileData(thirdWeekData)
    // const thirdWeekTotalFileData = getFileTotals(thirdTableTotals)
    // thirdWeekFileData[0].data.push(thirdWeekTotalFileData)
    setThirdWeekFileData(thirdWeekFileData)

    const fourWeekData: PlanejamentoSemanal[] | undefined = []
    fourTableApi?.forEachNode((rowNode) => {
      return fourWeekData?.push(rowNode.data)
    })

    fourWeekFileData[0].data = getFileData(fourWeekData)
    // const fourWeekTotalFileData = getFileTotals(fourTableTotals)
    // fourWeekFileData[0].data.push(fourWeekTotalFileData)
    setFourWeekFileData(fourWeekFileData)

    const data = [...thirdWeekFileData, ...fourWeekFileData]

    setCurrentFileData(data)
  }

  useEffect(() => {
    if (
      allFileData[0]?.data?.length ||
      allFileData[1]?.data?.length ||
      allFileData[2]?.data?.length ||
      allFileData[3]?.data?.length
    )
      eAllDataExcelFile.current.handleDownload()
  }, [allFileData])

  useEffect(() => {
    if (currentFileData[0]?.data?.length || currentFileData[1]?.data?.length)
      eCurrentDataExcelFile.current.handleDownload()
  }, [currentFileData])

  // const runPoultrytrader = () => {
  //   return
  // }

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: t('loading')
    }
  }, [])

  // Creates tables
  const getSummaryTable = () => (
    <div id='summaryTable' className={'table'} style={{ width: '90vw', height: '50vh' }}>
      <AgGridReact
        ref={eSummaryTableGrid}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        rowData={summaryDataCP}
        columnDefs={[...summaryTableColumns]}
        onGridReady={(params) => {
          const filterComponent = params.api.getFilterInstance('idIntegrador')
          filterComponent?.setModel({
            type: 'text',
            filter: tabValue === 0 ? 'NV' : 'SSA'
          })
          setSummaryTableApi(params.api)
        }}
        localeText={tablesText}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
      />
    </div>
  )

  const getHousingTable = () => (
    <div id='housingTable' className={'table'} style={{ width: '100vw', height: '100vh' }}>
      <AgGridReact
        ref={eHousingTableGrid}
        defaultColDef={defaultColDef}
        rowClassRules={rowClassRules}
        getRowId={getRowId}
        rowDragManaged={true}
        suppressMoveWhenRowDragging={true}
        rowData={housingData}
        columnDefs={[...housingColumns]}
        onGridReady={(params) => onGridReady('housing', params)}
        localeText={tablesText}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
        editType={'fullRow'}
      />
    </div>
  )

  const getFirstPlanningTable = () => (
    <div id='firstTable' className={'table'} style={{ width: '100vw', height: '100vh' }}>
      <AgGridReact
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        rowData={primeiraSemana}
        columnDefs={[...planningColumns]}
        onGridReady={(params) => onGridReady('first', params)}
        pinnedBottomRowData={firstTableTotals}
        getRowStyle={getRowStyle}
        localeText={tablesText}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
      />
    </div>
  )

  const getSecondPlanningTable = () => (
    <div id='secondTable' className={'table'} style={{ width: '100vw', height: '100vh' }}>
      <AgGridReact
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        rowData={segundaSemana}
        columnDefs={[...planningColumns]}
        onGridReady={(params) => onGridReady('second', params)}
        pinnedBottomRowData={secondTableTotals}
        getRowStyle={getRowStyle}
        localeText={tablesText}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
      />
    </div>
  )

  const getThirdPlanningTable = () => (
    <div id='thirdTable' className={'table'} style={{ width: '100vw', height: '100vh' }}>
      <AgGridReact
        ref={eThirdTableGrid}
        defaultColDef={defaultColDef}
        rowClassRules={rowClassRules}
        getRowId={getRowId}
        rowDragManaged={true}
        suppressMoveWhenRowDragging={true}
        rowData={terceiraSemanaCopy}
        columnDefs={[...planningColumnsDraggable]}
        onGridReady={(params) => onGridReady('third', params)}
        pinnedBottomRowData={thirdTableTotals}
        getRowStyle={getRowStyle}
        editType={'fullRow'}
        localeText={tablesText}
        //onRowDataUpdated={() => {
        //const nodes = getNodes(thirdTableApi)
        // const totals = nodes?.length ? updateTotals(nodes) : []
        // if (totals) setThirdTableTotals([totals])

        //if (nodes) {
        // const summaryData = generateSummaryData(nodes)
        //setThirdWeekSummary(summaryData)
        //}
        // }}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
      />
    </div>
  )

  const getFourPlanningTable = () => (
    <div id='fourTable' className={'table'} style={{ width: '100vw', height: '100vh' }}>
      <AgGridReact
        ref={eFourTableGrid}
        defaultColDef={defaultColDef}
        getRowId={getRowId}
        rowClassRules={rowClassRules}
        rowDragManaged={true}
        suppressMoveWhenRowDragging={true}
        rowData={quartaSemanaCopy}
        columnDefs={[...planningColumnsDraggable]}
        onGridReady={(params) => onGridReady('four', params)}
        pinnedBottomRowData={fourTableTotals}
        getRowStyle={getRowStyle}
        editType={'fullRow'}
        localeText={tablesText}
        onRowDataUpdated={() => {
          const nodes = getNodes(fourTableApi)
          // const totals = nodes?.length ? updateTotals(nodes) : []
          // if (totals) setFourTableTotals([totals])

          if (nodes) {
            const data = generateSummaryData(nodes)
            setFourWeekSummary(data)
            setSummaryDataCP([])
            setSummaryData(data)
          }
        }}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
      />
    </div>
  )

  const handleClose = () => {
    setOpen(false)
  }

  console.log(housingData)

  return (
    <div className='weekly-planning-container'>
      {/* {planejamentosSemanaisLoading ? <PlanningLoading /> : <></>} */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {t('message:planning_save')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()}>{t('components:button:cancel')}</Button>
          <Button onClick={() => save()} autoFocus>
            ok
          </Button>
        </DialogActions>
      </Dialog>
      <Tabs
        className='weekly-planning-container-tabs'
        value={tabValue}
        onChange={handleTabChange}
        centered
      >
        <Tab label='NV' />
        <Tab label='SSA' />
      </Tabs>
      <TabPanel value={0} index={0} key={0}>
        <div className='weekly-planning-container-tab-panel'>
          <div className='summary-table-container-tab-panel ag-theme-quartz'>
            <Accordion className='summary-accordion'>
              <AccordionSummary
                className='summary-accordion-summary'
                aria-controls='summary-accordion-details'
                id='summary-accordion-summary'
              >
                {t('summary')}
              </AccordionSummary>
              <AccordionDetails className='summary-accordion-details ag-theme-quartz'>
                {getSummaryTable()}
              </AccordionDetails>
            </Accordion>
          </div>
          <div className='weekly-planning-container-tab-panel-section-housing'>
            <Accordion className='housing-accordion' defaultExpanded>
              <AccordionSummary
                className='housing-accordion-summary'
                aria-controls='housing-accordion-details'
                id='housing-accordion-summary'
              >
                {t('active_lots')}
              </AccordionSummary>
              <AccordionDetails className='housing-accordion-details ag-theme-quartz'>
                {getHousingTable()}
              </AccordionDetails>
            </Accordion>
          </div>
          <div className='table-menu-buttons'>
            <div className='initial'>
              <Tooltip title={restoreButtonTooltip}>
                <IconButton
                  className={'restore-button'}
                  aria-label={t('components:button:restore')}
                  onClick={() => restore()}
                >
                  <Restore />
                </IconButton>
              </Tooltip>
              <Tooltip title={downloadAllPlanningButtonTooltip}>
                <IconButton
                  className={'download-report-button'}
                  aria-label={t('components:button:download_weekly_planning')}
                  onClick={() => downloadAllData()}
                >
                  <FileCopyOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title={downloadCurrentPlanningButtonTooltip}>
                <IconButton
                  className={'download-report-button'}
                  aria-label={t('components:button:download_weekly_planning')}
                  onClick={() => downloadCurrentData()}
                >
                  <InsertDriveFile />
                </IconButton>
              </Tooltip>
              <ExcelFile
                filename={t('planning') + ': ' + primeiraSemanaInicio + '__' + quartaSemanaFim}
                disabled={fileLoading}
                ref={eAllDataExcelFile}
                element={<></>}
              >
                <ExcelSheet dataSet={allFileData} name={t('link:weekly_planning')} />
              </ExcelFile>
              <ExcelFile
                filename={t('planning') + ': ' + terceiraSemanaInicio + '__' + quartaSemanaFim}
                disabled={fileLoading}
                ref={eCurrentDataExcelFile}
                element={<></>}
              >
                <ExcelSheet dataSet={currentFileData} name={t('link:weekly_planning')} />
              </ExcelFile>
            </div>
            <Legend />
            <Tooltip title={saveButtonTooltip}>
              <IconButton
                className={'save-button'}
                aria-label={t('components:button:save')}
                onClick={() => setOpen(true)}
              >
                <Save />
              </IconButton>
            </Tooltip>
          </div>
          <div className='weekly-planning-container-tab-panel-section-planning'>
            <div className='ag-theme-quartz'>
              <Accordion className='planning-accordion'>
                <AccordionSummary
                  className='planning-accordion-summary'
                  aria-controls='planning-accordion-details'
                  id='planning-accordion-summary'
                >
                  {moment(quartaSemanaInicio).utc().format('DD/MM/YY') +
                    ' - ' +
                    moment(quartaSemanaFim).utc().format('DD/MM/YY')}
                </AccordionSummary>
                <AccordionDetails className='planning-accordion-details'>
                  {getFourPlanningTable()}
                </AccordionDetails>
              </Accordion>
              <Accordion className='planning-accordion'>
                <AccordionSummary
                  className='planning-accordion-summary'
                  aria-controls='planning-accordion-details'
                  id='planning-accordion-summary'
                >
                  {moment(terceiraSemanaInicio).utc().format('DD/MM/YY') +
                    ' - ' +
                    moment(terceiraSemanaFim).utc().format('DD/MM/YY') +
                    ' - '}
                  <span id='running-text'>{t('running_week')}</span>
                </AccordionSummary>
                <AccordionDetails className='planning-accordion-details'>
                  {getThirdPlanningTable()}
                </AccordionDetails>
              </Accordion>
              <hr className='dotted-line'></hr>
              <h2>{t('historic')}</h2>
              <Accordion className='planning-accordion'>
                <AccordionSummary
                  className='planning-accordion-summary'
                  aria-controls='planning-accordion-details'
                  id='planning-accordion-summary'
                >
                  {moment(segundaSemanaInicio).utc().format('DD/MM/YY') +
                    ' - ' +
                    moment(segundaSemanaFim).utc().format('DD/MM/YY')}
                </AccordionSummary>
                <AccordionDetails className='planning-accordion-details'>
                  {getSecondPlanningTable()}
                </AccordionDetails>
              </Accordion>
              <Accordion className='planning-accordion'>
                <AccordionSummary
                  className='planning-accordion-summary'
                  aria-controls='planning-accordion-details'
                  id='planning-accordion-summary'
                >
                  {moment(primeiraSemanaInicio).utc().format('DD/MM/YY') +
                    ' - ' +
                    moment(primeiraSemanaFim).utc().format('DD/MM/YY')}
                </AccordionSummary>
                <AccordionDetails className='planning-accordion-details'>
                  {getFirstPlanningTable()}
                </AccordionDetails>
              </Accordion>
            </div>
            {/* <Tooltip title={runPoultrytraderButtonTooltip}>
              <Button
                className={'run-poultrytrader-button'}
                component='label'
                role={undefined}
                variant='contained'
                tabIndex={-1}
                startIcon={<Save />}
              >
                {t('components:button:run')}
              </Button>
            </Tooltip> */}
          </div>
        </div>
      </TabPanel>
    </div>
  )
}

export default PlanejamentoSemanalV2Page
