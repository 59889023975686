import { takeLatest, all, ForkEffect } from 'redux-saga/effects'
import { workerGetLotesProcessados } from './sagas'
import { DadosProcessadosConstants } from './types.d'

export function* watcherGetLotesProcessados(): Generator<
  ForkEffect<{
    type: DadosProcessadosConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    DadosProcessadosConstants.ACTION_GET_LOTES_PROCESSADOS,
    workerGetLotesProcessados
  )
}

export default function* granjaWatchers(): Generator<any, any, any> {
  yield all([watcherGetLotesProcessados()])
}
