import { IntegradorQueryParams } from 'api/integrador/types.d'
import { IntegradorConstants } from './types.d'

export const getIntegradores = (params?: IntegradorQueryParams): Record<string, any> => ({
  type: IntegradorConstants.ACTION_GET_INTEGRADORES,
  payload: { params }
})

export const setSelectedIntegrador = (params?: IntegradorQueryParams): Record<string, any> => ({
  type: IntegradorConstants.ACTION_SET_SELECTED_INTEGRADOR,
  payload: { params }
})
