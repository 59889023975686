import produce from 'immer'
import { AnyAction } from 'redux'
import { WeighingDataType } from '../main/types.d'
import { GranjaStore, GranjaConstants } from './types.d'

const INITIAL_STORE: GranjaStore = {
  granjaLoading: false,
  galpaoLoading: false,
  nucleoLoading: false,
  galpoes: [],
  granjas: [],
  granjasNucleos: [],
  galpoesIelmLoading: false,
  galpoesIelm: null,
  tipoDadoPeso: WeighingDataType.TABLE || WeighingDataType.CHARTS,
  activeLotPesos: [],
  lotesAtivos: true,
  averageWeighingData: false,
  pesos: [],
  pesosLoading: false,
  activeLotpesosLoading: false
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case GranjaConstants.REDUCER_SET_GRANJA_DATA:
        Object.assign(draft, payload)
        break
      case GranjaConstants.REDUCER_CLEAR_GRANJA_DATA:
        Object.assign(draft, INITIAL_STORE)
        break
      default:
        break
    }
  })
