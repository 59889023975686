import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

// Types
import { ApplicationStore } from 'store/types'
import { Props } from './types'

// Resources
import './styles.scss'
import colors, { Colors } from 'styles/variables/colors'
import { MovementTypeEnum } from 'store/planejamentoSemanal/types.d'

const Legend: React.FC<Props> = () => {
  const { theme } = useSelector((state: ApplicationStore) => state.main)
  const { t } = useTranslation()

  return (
    <div className={'legend'}>
      {t('movement_types')}
      <div className={'border-legend'}>
        <div className={'group-legend'}>
          <div
            className={'color-legend'}
            style={{ backgroundColor: colors(theme, Colors.blueLightOpacity) }}
          >
            {MovementTypeEnum.inTheWeek}
          </div>
          <h6 className={'text-legend'}>{t('lots_moved_in_the_week')}</h6>
        </div>
        <div className={'group-legend'}>
          <div
            className={'color-legend'}
            style={{ backgroundColor: colors(theme, Colors.yellowLightOpacity) }}
          >
            {MovementTypeEnum.fromHousings}
          </div>
          <h6 className={'text-legend'}>{t('lots_from_the_housings')}</h6>
        </div>
        <div className={'group-legend'}>
          <div
            className={'color-legend'}
            style={{ backgroundColor: colors(theme, Colors.grayLight) }}
          >
            {MovementTypeEnum.removedFromPlanning}
          </div>
          <h6 className={'text-legend'}>{t('lots_removed_from_the_planning')}</h6>
        </div>
        <div className={'group-legend'}>
          <div
            className={'color-legend'}
            style={{ backgroundColor: colors(theme, Colors.accent35) }}
          >
            {MovementTypeEnum.fromIntegrator}
          </div>
          <h6 className={'text-legend'}>{t('lots_from_others_integrators')}</h6>
        </div>
      </div>
    </div>
  )
}

export default Legend
