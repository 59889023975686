import { combineReducers, applyMiddleware, Store, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import auth from './auth/reducers'
import main from './main/reducers'
import granja from './granjas/reducers'
import lote from './lotes/reducers'
import integrador from './integrador/reducers'
import planejamento from './planejamento/reducers'
import planejamentoSemanal from './planejamentoSemanal/reducers'
import parametrosPO from './parametrosPO/reducers'
import analiseCrescimento from './analiseCrescimento/reducers'
import dadosProcessados from './dadosProcessados/reducers'

import authWatchers from './auth/watchers'
import mainWatchers from './main/watchers'
import integradorWatchers from './integrador/watchers'
import granjaWatchers from './granjas/watchers'
import loteWatchers from './lotes/watchers'
import analiseCrescimentoWatchers from './analiseCrescimento/watchers'
import parametrosPOWatchers from './parametrosPO/watchers'
import planejamentoWatchers from './planejamento/watchers'
import planejamentoSemanalWatchers from './planejamentoSemanal/watchers'
import dadosProcessadosWatchers from './dadosProcessados/watchers'

import { ApplicationStore } from './types.d'

const reducers = combineReducers({
  auth,
  main,
  granja,
  lote,
  integrador,
  planejamento,
  planejamentoSemanal,
  parametrosPO,
  analiseCrescimento,
  dadosProcessados
})

const sagaMiddleware = createSagaMiddleware()
const store: Store<ApplicationStore> = createStore(reducers, applyMiddleware(sagaMiddleware))

sagaMiddleware.run(authWatchers)
sagaMiddleware.run(mainWatchers)
sagaMiddleware.run(granjaWatchers)
sagaMiddleware.run(loteWatchers)
sagaMiddleware.run(integradorWatchers)
sagaMiddleware.run(planejamentoWatchers)
sagaMiddleware.run(planejamentoSemanalWatchers)
sagaMiddleware.run(parametrosPOWatchers)
sagaMiddleware.run(analiseCrescimentoWatchers)
sagaMiddleware.run(dadosProcessadosWatchers)

export default store
