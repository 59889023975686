import { apiRequest } from 'api'
import Codes from 'helpers/Codes'
import Exception from 'helpers/Exception'
import { Galpao, GalpaoQueryParams } from './types.d'

export const getGalpoes = async (params: GalpaoQueryParams, token: string): Promise<Galpao[]> => {
  try {
    return apiRequest<Galpao[]>('/v1/granjas/nucleos/galpoes', token, params)
  } catch (error: any) {
    console.log(error)
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'v1/granjas/nucleos/galpoes', error)
  }
}
