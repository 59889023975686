import produce from 'immer'
import { AnyAction } from 'redux'

import { MainStore, MainConstants, Themes, Locales } from './types.d'

const INITIAL_STORE: MainStore = {
  isCheckingSettings: true,
  isDrawerOpen: false,
  isInfoModalOpen: false,
  locale: Locales.en,
  message: undefined,
  theme: Themes.LIGHT,
  timeZone: '',
  infoModalMessage: undefined
}

export default (store = INITIAL_STORE, action: AnyAction): any =>
  produce(store, (draft) => {
    const { payload } = action
    switch (action.type) {
      case MainConstants.REDUCER_CLEAR_MAIN_DATA:
        console.log('sair: ', draft)
        Object.assign(draft, null)
        break
      case MainConstants.REDUCER_SET_MAIN_DATA:
        Object.assign(draft, payload)
        break
      default:
        break
    }
  })
