import React, { ReactElement } from 'react'
import { Route, Redirect } from 'react-router-dom'

// Types
import { Props } from './types.d'

export default (props: Props): ReactElement<any, any> => {
  const { isAuthenticated } = props

  return (
    <Route
      render={(routeProps) =>
        isAuthenticated ? (
          props.component
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location }
            }}
          />
        )
      }
    />
  )
}
