import { Galpao } from 'store/granjas/types'

export type LoteStore = {
  loteLoading: boolean
  duracaoCiclosLoading: boolean
  lotesPorMesLoading: boolean
  lotesFechamentosMediasLoading: boolean
  lotesFechamentosMedias: Medias
  lotesPorMes: LotePorMes[]
  selectedLote?: Lote
  lotes: Lotes[]
  oneYearAgoLotes?: Lotes[]
  duracaoCiclos: number[]
  lotesPesos: LotesPesos[]
  quantLotesProcessados: number | null
  lotesAtivos: number | null
  quantLotesProcessadosLoading: boolean
  lotesAtivosLoading: boolean
  oneYearAgoLotesLoading: boolean
  lotesAcompanhamentos: LotesAcompanhamentos[]
  lotesAlojamentos: LotesAlojamentos[]
  lotesFechamentos: LotesFechamentos[]
  selectedLoteAcompanhamento?: Record<string, number | string>
}

export type LotePorMes = {
  data: string
  count: number
}

export enum LotesTipoAcompanhamentos {
  ACOMPANHAMENTO_MANUAL = 'acompanhamentoManual',
  ACOMPANHAMENTO_BALANÇA = 'acompanhamentoBalança'
}

export type Medias = {
  mediaCiclo: number | null
  mediaAbate: number | null
}

export type Lote = {
  nroLote: number
  idFornecedor: number
  idCriador: number
  idIntegrador: number
  dataNascimento: string
  qtdAves?: number
  qtdAvesRemun?: number
  sexo?: string
  raca?: string
  estacao?: string
  linhagem?: string
  tratamento?: string
  alojamentos: LotesAlojamentos[]
  fechamento: LotesFechamentos[] | null
}

export type LotesPesos = {
  nroLote: number
  id: string
  idade: number
  peso: number
  amostra: number
  ca: number
  dataPeso: string
}

export type LotesAcompanhamentos = {
  nroLote: number
  id: number
  dataAcomp0?: number
  mortManha?: number
  mortTarde?: number
  elimAscite?: number
  elimRefugos?: number
  elimAleijados?: number
  leituraHidrometro?: number
}

export type LotesAlojamentos = {
  nroLote: number
  idGalpao?: string
  qtdAvesAlojadas?: number
  pesoMedio?: number
  idadeMatriz?: number
  dataAlojamento: string
  galpao: Galpao
}

export type LotesFechamentos = {
  nroLote: number
  dataSaida: string
  qtdAvesAbatidas: number
  qtdAvesMortas: number
  qtdAvesEliminadas: number
  diferencaAvesEntregue: number
  consumoRacao: number
  sobraRacao: number
  pesoTotalLote: number
  pesoMedio: number
  idadeMedia: number
  fatorProducao: number
  iepIpsu: number
}

type LoteFechado = Required<Lote> & { fechamento: Fechamento }

export type DuracaoCiclo = {
  nroLote: number
  mesAlojamento: number
  tempoAlojado: number
}

export enum LoteConstants {
  ACTION_GET_LOTES = 'ACTION_GET_LOTES',
  ACTION_GET_DURACAO_CICLO = 'ACTION_GET_DURACAO_CICLO',
  ACTION_GET_LOTES_PESOS = 'ACTION_GET_LOTES_PESOS',
  ACTION_SET_SELECTED_LOTE = 'ACTION_SET_SELECTED_LOTE',
  ACTION_GET_LOTES_ALOJAMENTOS = 'ACTION_GET_LOTES_ALOJAMENTOS',
  ACTION_GET_LOTES_ACOMPANHAMENTOS = 'ACTION_GET_LOTES_ACOMPANHAMENTOS',
  ACTION_GET_LOTES_FECHAMENTOS = 'ACTION_GET_LOTES_FECHAMENTOS',
  ACTION_SET_SELECTED_LOTE_ACOMPANHAMENTO = 'ACTION_SET_SELECTED_LOTE_ACOMPANHAMENTO',
  ACTION_GET_ONE_YEAR_AGO_LOTES = 'ACTION_GET_ONE_YEAR_AGO_LOTES',
  ACTION_GET_LOTES_POR_MES = 'ACTION_GET_LOTES_POR_MES',
  ACTION_GET_LOTES_FECHAMENTOS_MEDIAS = 'ACTION_GET_LOTES_FECHAMENTOS_MEDIAS',
  ACTION_GET_LOTES_ATIVOS = 'ACTION_GET_LOTES_ATIVOS',
  ACTION_GET_LOTES_PROCESSADOS = 'ACTION_GET_LOTES_PROCESSADOS',
  REDUCER_CLEAR_LOTE_DATA = 'REDUCER_CLEAR_LOTE_DATA',
  REDUCER_SET_LOTE_DATA = 'REDUCER_SET_LOTE_DATA'
}
