import { RouteComponentProps } from 'react-router-dom'
import { MovementTypeEnum } from '../../store/planejamentoSemanal/types.d'

export type Props = RouteComponentProps<any>

export const rowClassRules = {
  'white-row': 'data.movementType == undefined',
  'blue-row': 'data.movementType == ' + MovementTypeEnum.inTheWeek,
  'gray-row': 'data.movementType == ' + MovementTypeEnum.removedFromPlanning,
  'yellow-row': 'data.movementType == ' + MovementTypeEnum.fromHousings,
  'red-row': 'data.movementType == ' + MovementTypeEnum.fromIntegrator
}

export const summaryTableRowClassRules = {
  'gray-row': '!data.diaSemana'
}
