import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Checkbox,
  FormControlLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core'
import CardTypesButtons from 'components/shared/CardTypeButtons'
import _ from 'lodash'

// Types
import { GranjaConstants } from 'store/granjas/types.d'
import { ApplicationStore } from 'store/types.d'
import { WeighingDataType } from 'store/main/types.d'

// Components
import GranjaFilterCard from 'components/cards/GranjaFilterCard'
import AviaryWeighingCharts from 'components/charts/AviaryWeighingCharts'
import AviaryWeighingTable from 'components/tables/LotWeighingsTable'

// Resources
import './styles.scss'
import { LotesAlojamentos } from 'store/lotes/types'
import moment from 'moment'

const PesosPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const { tipoDadoPeso, lotesAtivos, averageWeighingData } = useSelector(
    (state: ApplicationStore) => state.granja
  )
  const [activeLots, setActiveLots] = useState<boolean>(lotesAtivos)
  const [averageData, setAverageData] = useState<boolean>(averageWeighingData)
  const { lotesAlojamentos } = useSelector((state: ApplicationStore) => state.lote)
  const [housingSummary, setHousingSummary] = useState<LotesAlojamentos[]>([])

  const handleAviaryWeighingDataType = (tipoDadoPeso: any) => {
    dispatch({
      type: GranjaConstants.REDUCER_SET_GRANJA_DATA,
      payload: { tipoDadoPeso: tipoDadoPeso }
    })
  }

  // useEffect(() => {
  //   if (granjas.length) dispatch(setSelectedGranja({ id: 300 }))
  // }, [granjas])

  useEffect(() => {
    if (lotesAlojamentos) setHousingSummary(_.uniqBy(lotesAlojamentos, 'nroLote'))
  }, [lotesAlojamentos])

  const handleActiveLotsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveLots(event.target.checked)
  }

  const handleAverageDataChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAverageData(event.target.checked)
  }

  return (
    <div className={'weighing-body'}>
      <div className={'weighing-card'}>
        <div
          className={'weighing-column'}
          style={{ width: housingSummary?.length > 3 ? '30%' : '70%' }}
        >
          <div className={'weighing-row'}>
            <CardTypesButtons
              t={t}
              selectedType={tipoDadoPeso}
              handleTypeChange={(x) => handleAviaryWeighingDataType(x)}
            />
          </div>
          <div className={'weighing-row'}>
            {tipoDadoPeso === WeighingDataType.TABLE ? (
              <>
                <GranjaFilterCard active={activeLots} withActiveLots={activeLots} />
                <FormControlLabel
                  label={t('active_lots')}
                  control={
                    <Checkbox
                      checked={activeLots}
                      onChange={handleActiveLotsChange}
                      name={'activeLots'}
                    />
                  }
                ></FormControlLabel>
                <FormControlLabel
                  label={t('average')}
                  control={
                    <Checkbox
                      checked={averageData}
                      onChange={handleAverageDataChange}
                      name={'averageData'}
                    />
                  }
                ></FormControlLabel>
              </>
            ) : (
              <GranjaFilterCard active={true} withActiveLots={true} />
            )}
          </div>
        </div>
        <div
          className={'weighing-column'}
          style={{ width: housingSummary?.length > 3 ? '70%' : '30%' }}
        >
          <div className={'weighing-row'}>
            <Box className='box'>
              <div className={'weighing-row'}>
                <text className={'text-title'}>{t('housings')}</text>
              </div>
              <TableContainer component={Paper} style={{ maxWidth: '1200px', overflowX: 'auto' }}>
                <Table className='table' size='small' aria-label='a dense table'>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <strong style={{ fontWeight: 'bold' }}>{t('aviary')}</strong>
                      </TableCell>
                      {housingSummary.map((row) => (
                        <TableCell component='th' scope='row' key={row.nroLote}>
                          {row.idGalpao}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong style={{ fontWeight: 'bold' }}>{t('type')}</strong>
                      </TableCell>
                      {housingSummary.map((row) => (
                        <>
                          <TableCell component='th' scope='row' key={row.galpao.tipo}>
                            {row.galpao.tipo}
                          </TableCell>
                        </>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong style={{ fontWeight: 'bold' }}>{t('lot')}</strong>
                      </TableCell>
                      {housingSummary.map((row) => (
                        <TableCell component='th' scope='row' key={row.nroLote}>
                          {row.nroLote}
                        </TableCell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <strong style={{ fontWeight: 'bold' }}>{t('start_date')}</strong>
                      </TableCell>
                      {housingSummary.map((row) => (
                        <TableCell component='th' scope='row' key={row.nroLote}>
                          {row.dataAlojamento
                            ? moment(row.dataAlojamento).utc().format('DD/MM/YY')
                            : ''}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
        </div>
      </div>
      <div className={'weighing-card-data'}>
        <div className='granja-weighing-container'>
          {tipoDadoPeso === WeighingDataType.TABLE ? (
            <AviaryWeighingTable
              activeLots={activeLots}
              averageData={averageData}
            ></AviaryWeighingTable>
          ) : (
            <AviaryWeighingCharts />
          )}
        </div>
      </div>
    </div>
  )
}

export default PesosPage
