import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, IconButton, Tooltip, Button } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory, withRouter } from 'react-router-dom'

import { mdiClose } from '@mdi/js'

// Resources
import './styles.scss'

// Types
import { Props } from './types.d'
import { ApplicationStore } from 'store/types.d'

// Methods
import { toggleDrawer } from 'store/main/actions'
import Icon from '@mdi/react'
import { DrawerItem } from 'components/Drawer/types.d'
import { drawerItens } from 'components/Drawer'

const MenuModal: React.FC<Props> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { pathname } = history.location
  const dispatch = useDispatch()
  const { theme, isDrawerOpen } = useSelector((state: ApplicationStore) => state.main)

  const handleClick = (path: string) => {
    history.push(path)
    dispatch(toggleDrawer(false))
  }

  const renderMenuItems = () =>
    drawerItens.map((item: DrawerItem) => {
      return (
        <div key={item.key} className='menu-modal-row'>
          <Tooltip key={item.key} title={t(item.label) as string}>
            <Button
              className={`menu-modal-button-${
                item.checkActivePath(pathname) ? 'active' : 'inactive'
              }`}
              aria-label={t(item.label)}
              onClick={() => handleClick(item.path)}
            >
              <div className='menu-modal-button-content'>
                {item.icon}
                <p
                  className={`menu-button-text-${
                    item.checkActivePath(pathname) ? 'active' : 'inactive'
                  }`}
                >
                  {t(item.label)}
                </p>
              </div>
            </Button>
          </Tooltip>
        </div>
      )
    })

  return (
    <Dialog
      style={{ backgroundImage: 'linear-gradient(#D1422A 10%, #990000 100%)' }}
      className={`modal-menu-${theme} modal-menu-${isDrawerOpen ? 'open' : 'closed'}`}
      fullScreen
      open={isDrawerOpen}
      onClose={() => dispatch(toggleDrawer(false))}
    >
      <div className={'menu-modal-content'}>
        <IconButton
          className={'modal-menu-close-button'}
          aria-label={t('close')}
          onClick={() => {
            dispatch(toggleDrawer(false))
          }}
        >
          <Icon path={mdiClose} size={1} className={'modal-menu-close-icon'} />
        </IconButton>
        {renderMenuItems()}
      </div>
    </Dialog>
  )
}

export default withRouter(MenuModal)
