export type PlanejamentoStore = {
  planejamentosAlojamentos: PlanejamentosAlojamentos[]
  planejamentosDiarios: PlanejamentosDiarios
  planejamentoDiarioLoading: boolean
  planejamentoSemanal?: PlanejamentoSemanal[]
  planejamentoSemanalLoading: boolean
  saida?: Saida[]
  planejamentoSemanalTotais: PlanejamentoSemanalTotais
}

export type PlanejamentosDiarios = {
  [key: string]: any
  monday: {
    planejamentoDiario: PlanejamentosAlojamentos[]
    totais?: PlanejamentosAlojamentosTotais
  }
  tuesday: {
    planejamentoDiario: PlanejamentosAlojamentos[]
    totais?: PlanejamentosAlojamentosTotais
  }
  wednesday: {
    planejamentoDiario: PlanejamentosAlojamentos[]
    totais?: PlanejamentosAlojamentosTotais
  }
  thursday: {
    planejamentoDiario: PlanejamentosAlojamentos[]
    totais?: PlanejamentosAlojamentosTotais
  }
  friday: {
    planejamentoDiario: PlanejamentosAlojamentos[]
    totais?: PlanejamentosAlojamentosTotais
  }
  saturday: {
    planejamentoDiario: PlanejamentosAlojamentos[]
    totais?: PlanejamentosAlojamentosTotais
  }
}

export type PlanejamentosAlojamentos = {
  [key: string]: any
  nroLote?: number
  idGranja?: number
  idNucleo?: string
  idGalpao?: string
  numeroGalpao?: string
  municipio?: string
  extensionista?: string
  uf?: string
  distanciaAbatedouroITA?: number
  distanciaAbatedouroNV?: number
  handle?: unknown
  genero?: string
  leaveAge?: number
  idadePredita?: number
  pesoPredito?: number
  lucro?: number
  consumo?: number
  animaisPreditos?: number
}

export type PlanejamentoSemanalTotais = {
  pesoPreditoTotal?: number
  lucroTotal?: number
  consumoTotal?: number
  animaisPreditosTotal?: number
  idadeSaida?: number
  numLotes?: number
}

export type PlanejamentosAlojamentosTotais = {
  idadePreditaTotal?: number
  pesoPreditoTotal?: number
  lucroTotal?: number
  consumoTotal?: number
  animaisPreditosTotal?: number
}

export type PlanejamentoSemanal = {
  [key: string]: any
  nroLote?: number[]
  idGalpao?: number[]
  dataSaida?: string
  diaSemanaSaida?: string
  idadeSaida?: number
  pesoPredito?: number
  animaisPreditos?: number
  lucro?: number
  consumo?: number
}

export type Saida = {
  dataSaida?: string
  idGalpao?: string
  nroLote?: number
  idadeSaida?: number
  pesoPredito?: number
  animaisPreditos?: number
  lucro?: number
  consumo?: number
}

export type PlanejamentoDiarioQueryParams = {
  lotes: number[]
  weekDay?: string
}

export enum PlanejamentoConstants {
  ACTION_GET_PLANEJAMENTO_ALOJAMENTOS = 'ACTION_GET_PLANEJAMENTO_ALOJAMENTOS',
  ACTION_GET_PLANEJAMENTO_DIARIO_SEMANAL = 'ACTION_GET_PLANEJAMENTO_DIARIO_SEMANAL',
  REDUCER_CLEAR_PLANEJAMENTO_DATA = 'REDUCER_CLEAR_PLANEJAMENTO_DATA',
  REDUCER_SET_PLANEJAMENTO_DATA = 'REDUCER_SET_PLANEJAMENTO_DATA',
  ACTION_GET_PLANEJAMENTO_SEMANAL = 'ACTION_GET_PLANEJAMENTO_SEMANAL'
}
