export type SummaryStore = {
  summary: Summary
  summaryLoading: boolean
}

export type Summary = {
  lots: {
    processed: number
    actives: number
  }
  averageCycle: number | null
  averageSlaughter: number | null
  ielm: number | null
}

export enum SummaryConstants {
  ACTION_GET_SUMMARY = 'ACTION_GET_SUMMARY',
  REDUCER_CLEAR_SUMMARY_DATA = 'REDUCER_CLEAR_SUMMARY_DATA',
  REDUCER_SET_SUMMARY_DATA = 'REDUCER_SET_SUMMARY_DATA'
}
