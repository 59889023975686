import React from 'react'
import { Props } from './types'

const ParametrosPOIcon: React.FC<Props> = ({ width, height, fill }: Props) => {
  return (
    <svg
      id='parametrosPO'
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 40 40'
    >
      <path
        id='Caminho_673'
        data-name='Caminho 673'
        d='M2.25,26.786h25.3a3.648,3.648,0,0,1,6.545,0H42.25V29.5H34.094a3.648,3.648,0,0,1-6.545,0H2.25'
        transform='translate(-2.25 3.463)'
        fill={fill}
      />
      <path
        id='Caminho_674'
        data-name='Caminho 674'
        d='M2.25,17.223h8.156a3.648,3.648,0,0,1,6.545,0h25.3v2.714h-25.3a3.648,3.648,0,0,1-6.545,0H2.25'
        transform='translate(-2.25 -1.081)'
        fill={fill}
      />
      <path
        id='Caminho_675'
        data-name='Caminho 675'
        d='M2.25,7.661h25.3a3.648,3.648,0,0,1,6.545,0H42.25v2.714H34.094a3.648,3.648,0,0,1-6.545,0H2.25'
        transform='translate(-2.25 -5.625)'
        fill={fill}
      />
    </svg>
  )
}

export default ParametrosPOIcon
