type ParentProps = {
  activeLots: boolean
  averageData: boolean
}

export type Props = ParentProps

export interface Weighing {
  aviaryId: string
  lotNumber: number
  age?: number
  date: Date | string
  weightType: string
  weight: number | string
  scaleSector?: string
  type: string
  color: string
}

export enum DateType {
  start = 'start',
  end = 'end'
}
