import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'

// Types
import { weekDay } from 'store/main/types.d'
import { ApplicationStore } from 'store/types.d'
import { Parametros, ParametrosPO } from 'store/parametrosPO/types.d'

// Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
  Tooltip,
  IconButton
} from '@material-ui/core'
import Loading from 'components/shared/Loading'
import ParametersForm from 'components/Forms/ParametersForm'
import Input from 'components/widgets/Input'
import Select from 'components/widgets/select'

// Methods
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { saveParametrosPO, setSelectedParametrosPO } from 'store/parametrosPO/actions'

// Resources
import Icon from '@mdi/react'
import './styles.scss'
import { mdiChevronDown, mdiContentDuplicate } from '@mdi/js'
import { Form } from '@unform/web'
import { SubmitHandler } from '@unform/core'

const ParametrosPOScreen: React.FC = () => {
  const mainFormRef: any = useRef(null)
  const clearError = (e: any) => mainFormRef.current?.setFieldError(e.target?.id, '')
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { selectedIntegrador } = useSelector((state: ApplicationStore) => state.integrador)
  const { selectedParametrosPO, parametrosPOLoading } = useSelector(
    (state: ApplicationStore) => state.parametrosPO
  )
  const [pageLoading, setPageLoading] = useState<boolean>(false)
  const [newParametrosPo, setNewParametrosPO] = useState<ParametrosPO>()
  const [sanitarySlaughterDay, setSanitarySlaughterDay] = useState<any>()

  const usePrevious = (value: any) => {
    const ref = useRef()

    useEffect(() => {
      ref.current = value
    })

    return ref.current
  }

  const prevSelectedIntegratorId = usePrevious(selectedIntegrador?.id)

  const prevSelectedParametrosPO = usePrevious(selectedParametrosPO)

  useEffect(() => {
    if (
      (!selectedParametrosPO ||
        (prevSelectedIntegratorId !== selectedIntegrador?.id &&
          prevSelectedIntegratorId !== undefined)) &&
      selectedIntegrador?.id
    ) {
      dispatch(setSelectedParametrosPO(selectedIntegrador?.id))
      mainFormRef?.current?.reset()
    }
  }, [selectedParametrosPO, selectedIntegrador])

  useEffect(() => {
    if (selectedParametrosPO) {
      setNewParametrosPO(selectedParametrosPO)
      setSanitarySlaughterDay(selectedParametrosPO.diaAbatesSanitarios)
      mainFormRef.current.setData(selectedParametrosPO)
    }
  }, [selectedParametrosPO])

  const saveData = async (weekDay: string, data: Parametros) => {
    const parametros: ParametrosPO = { ...newParametrosPo }
    parametros.parametros = { ...newParametrosPo?.parametros }

    parametros.parametros[weekDay] = data

    setNewParametrosPO(parametros)
  }

  const replicateValues = async () => {
    const parametros: ParametrosPO = { ...newParametrosPo }
    parametros.parametros = { ...newParametrosPo?.parametros }

    weekDay.map((w) => {
      // IF IS MONDAY, DOES NOT CHANGE THE VALUES JUST KEEP THEM
      if (w.value !== weekDay[1].value) {
        parametros.parametros[w.value] = { ...parametros.parametros[w.value] }
        for (const key in parametros.parametros[w.value]) {
          const value = parametros.parametros[weekDay[1].value][key]
          parametros.parametros[w.value][key] = value
        }
      }
    })
    setNewParametrosPO(parametros)
    mainFormRef?.current?.reset()
    mainFormRef.current.setData(parametros)
  }

  const saveAllData: SubmitHandler<ParametrosPO> = async (data) => {
    setPageLoading(true)

    const parametros: ParametrosPO = { ...newParametrosPo }
    parametros.parametros = { ...newParametrosPo?.parametros }

    if (data)
      _.map(
        // Fomat data to save: updates empty text to null and text number to type number
        Object.entries(data),
        ([key, value]) =>
          (data[key] = value === '' || undefined ? null : isNaN(value) ? value : Number(value))
      )

    try {
      mainFormRef.current.setErrors({})
      const schema = Yup.object().shape({
        diaAbatesSanitarios: Yup.mixed().test(
          'notNone',
          t('components:formFields:invalid_weekday'),
          (value) => value !== 'none'
        ),
        valorKgFrango: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .required(() => t('components:formFields:required')),
        custoPorKm: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .required(() => t('components:formFields:required')),
        custoPorViagem: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .required(() => t('components:formFields:required')),
        quantidadeCaixasPorCaminhao: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .required(() => t('components:formFields:required')),
        kgPorCaixa: Yup.number()
          .typeError(t('components:formFields:invalid_number'))
          .min(1, t('components:formFields:must_be_greatter_than_1'))
          .required(() => t('components:formFields:required'))
      })

      await schema.validate(data, { abortEarly: false })

      if (parametros) {
        parametros.diaAbatesSanitarios = sanitarySlaughterDay
        parametros.valorKgFrango = data.valorKgFrango
        parametros.custoPorKm = data.custoPorKm
        parametros.custoPorViagem = data.custoPorViagem
        parametros.quantidadeCaixasPorCaminhao = data.quantidadeCaixasPorCaminhao
        parametros.kgPorCaixa = data.kgPorCaixa

        weekDay.map((w) => {
          parametros.parametros[w.value] = { ...parametros.parametros[w.value] }
          for (const key in parametros.parametros[w.value]) {
            const value = parametros.parametros[w.value][key]
            parametros.parametros[w.value][key] =
              value === '' || undefined ? null : isNaN(value) ? value : Number(value)
          }
        })

        setNewParametrosPO(parametros)

        if (selectedIntegrador?.id && parametros) {
          parametros.idIntegrador = selectedIntegrador?.id
          if (JSON.stringify(prevSelectedParametrosPO) !== JSON.stringify(parametros)) {
            dispatch(saveParametrosPO(parametros))
          }
          setPageLoading(false)
          mainFormRef?.current?.reset()
          mainFormRef?.current?.setData(parametros)
        }
      }
    } catch (err) {
      setPageLoading(false)
      mainFormRef?.current?.setData(parametros)
      const validationErrors: any = {}
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: any) => (validationErrors[error.path] = error.message))
        mainFormRef?.current?.setErrors(validationErrors)
      }
    }
  }

  return (
    <div className='parametrosPO-container'>
      <div className='container'>
        <h1 className='pageTitle'>{t('parameters')}</h1>
        <h1>{t('general')}</h1>
        {parametrosPOLoading || pageLoading ? (
          <Loading />
        ) : (
          <>
            <Form className={'parametrosPOForm'} ref={mainFormRef} onSubmit={saveAllData}>
              <Input
                name='valorKgFrango'
                label={t('components:formFields:valor_kg_frango') + ', ' + t('unity:R$/kg')}
                placeholder={'0'}
                onFocus={clearError}
              />
              <Input
                name='custoPorKm'
                label={t('components:formFields:custo_por_km') + ', ' + t('unity:R$/km')}
                placeholder={'0'}
              />
              <Input
                name='custoPorViagem'
                label={t('components:formFields:custo_por_viagem') + ', ' + t('unity:R$')}
                placeholder={'0'}
                onFocus={clearError}
              />
              <Input
                name='quantidadeCaixasPorCaminhao'
                label={t('components:formFields:quantidade_caixas_por_caminhao')}
                placeholder={'0'}
                onFocus={clearError}
              />
              <Input
                name='kgPorCaixa'
                label={t('components:formFields:kg_por_caixa') + ', ' + t('unity:kg')}
                placeholder={'0'}
                onFocus={clearError}
              />
              <Select
                name='diaAbatesSanitarios'
                label={t('components:formFields:dia_abates_sanitarios')}
                options={weekDay}
                selectedValue={sanitarySlaughterDay}
                onChange={(value) => setSanitarySlaughterDay(value.target.value)}
                // disabled={true}
              ></Select>
              <h1>
                {t('weekly')}
                <Tooltip
                  className='replicate-tooltip'
                  title={t('components:button:replicate_values_from_monday')}
                >
                  <IconButton className='replicate-button' size='medium' onClick={replicateValues}>
                    <Icon className='replicate-icon' path={mdiContentDuplicate} size={2} />
                  </IconButton>
                </Tooltip>
              </h1>

              {weekDay.map((w) => {
                if (w.value !== 'dom')
                  return (
                    <Accordion key={w.value}>
                      <AccordionSummary
                        expandIcon={<Icon path={mdiChevronDown} size={1} className={'icon'} />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'
                      >
                        <Typography> {t(w.label)}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <ParametersForm
                          parameters={newParametrosPo ? newParametrosPo?.parametros[w.value] : {}}
                          saveData={(data: Parametros) => saveData(w.value, data)}
                        ></ParametersForm>
                      </AccordionDetails>
                    </Accordion>
                  )
              })}
              <Button type='submit' className='save-button'>
                <span className='save-button-text'>{t('save')}</span>
              </Button>
            </Form>
          </>
        )}
      </div>
    </div>
  )
}

export default ParametrosPOScreen
