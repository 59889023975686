import { LotesAlojamentosQueryParams } from 'api/lote/types'
import { SaidaQueryParams } from 'api/dadosProcessados/types'

import { PlanejamentoConstants } from './types.d'

export const getPlanejamentoSemanal = (params?: SaidaQueryParams): Record<string, any> => ({
  type: PlanejamentoConstants.ACTION_GET_PLANEJAMENTO_SEMANAL,
  payload: { params }
})

export const getPlanejamentoAlojamentos = (
  params?: LotesAlojamentosQueryParams
): Record<string, any> => ({
  type: PlanejamentoConstants.ACTION_GET_PLANEJAMENTO_ALOJAMENTOS,
  payload: { params }
})

export const getPlanejamentoDiarioSemanal = (): Record<string, any> => ({
  type: PlanejamentoConstants.ACTION_GET_PLANEJAMENTO_DIARIO_SEMANAL
})
