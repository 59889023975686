import { takeLatest, all } from 'redux-saga/effects'
import { PlanejamentoSemanalConstants } from './types.d'
import {
  workerGetAlojamentosAtivos,
  workerGetPlanejamentosSemanais,
  workerFilterAlojamentosByPlanejamentos,
  workerSavePlanejamentoSemanal,
  workerDeletePlanejamentoSemanal
} from './sagas'

export function* watcherGetAlojamentosAtivos() {
  yield takeLatest(
    PlanejamentoSemanalConstants.ACTION_GET_ALOJAMENTOS_ATIVOS,
    workerGetAlojamentosAtivos
  )
}

export function* watcherGetPlanejamentoSemanal() {
  yield takeLatest(
    PlanejamentoSemanalConstants.ACTION_GET_PLANEJAMENTOS_SEMANAIS,
    workerGetPlanejamentosSemanais
  )
}

export function* watcherFilterAlojamentosByPlanejamentos() {
  yield takeLatest(
    PlanejamentoSemanalConstants.ACTION_FILTER_ALOJAMENTOS_BY_PLANEJAMENTOS,
    workerFilterAlojamentosByPlanejamentos
  )
}

export function* watcherSavePlanejamentoSemanal() {
  yield takeLatest(
    PlanejamentoSemanalConstants.ACTION_SAVE_PLANEJAMENTO_SEMANAL,
    workerSavePlanejamentoSemanal
  )
}

export function* watcherDeletePlanejamentoSemanal() {
  yield takeLatest(
    PlanejamentoSemanalConstants.ACTION_DELETE_PLANEJAMENTO_SEMANAL,
    workerDeletePlanejamentoSemanal
  )
}

export default function* alojamentoSemanalWatchers() {
  yield all([
    watcherGetAlojamentosAtivos(),
    watcherGetPlanejamentoSemanal(),
    watcherFilterAlojamentosByPlanejamentos(),
    watcherSavePlanejamentoSemanal(),
    watcherDeletePlanejamentoSemanal()
  ])
}
