import _ from 'lodash'
import React from 'react'
import Chart from 'react-apexcharts'
import { Props } from './types.d'

const LineChart: React.FC<Props> = ({ chartOptions, values }) => {
  const defaultChartOptions = {
    chart: {
      id: 'lines-chart',
      height: 350
    }
  }

  values = values?.filter(function (i) {
    if (i !== undefined) return i
  })
  values = _.orderBy(values, 'name')
  return (
    <Chart
      className='line-chart'
      options={chartOptions ?? defaultChartOptions}
      series={values}
      type='line'
      width={600}
    />
  )
}

export default LineChart
