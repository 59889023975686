import { all, ForkEffect, takeLatest } from 'redux-saga/effects'
import {
  workerGetPlanejamentoAlojamentos,
  workerGetPlanejamentoDiarioSemanal,
  workerGetPlanejamentoSemanal
} from './sagas'
import { PlanejamentoConstants } from './types.d'

export function* watcherGetPlanejamentoAlojamentos(): Generator<
  ForkEffect<{
    type: PlanejamentoConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    PlanejamentoConstants.ACTION_GET_PLANEJAMENTO_ALOJAMENTOS,
    workerGetPlanejamentoAlojamentos
  )
}

export function* watcherGetPlanejamentoDiarioSemanal(): Generator<
  ForkEffect<{
    type: PlanejamentoConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    PlanejamentoConstants.ACTION_GET_PLANEJAMENTO_DIARIO_SEMANAL,
    workerGetPlanejamentoDiarioSemanal
  )
}

export function* watcherGetPlanejamentoSemanal(): Generator<
  ForkEffect<{
    type: PlanejamentoConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(
    PlanejamentoConstants.ACTION_GET_PLANEJAMENTO_SEMANAL,
    workerGetPlanejamentoSemanal
  )
}

export default function* planejamentoWatchers(): Generator<any, any, any> {
  yield all([
    watcherGetPlanejamentoAlojamentos(),
    watcherGetPlanejamentoSemanal(),
    watcherGetPlanejamentoDiarioSemanal()
  ])
}
