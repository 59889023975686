import React, { useEffect, useRef, useState } from 'react'
import { useField } from '@unform/core'
import { Props } from './types.d'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import Loading from 'components/shared/Loading'
import { IconButton, Input as TextField, InputAdornment } from '@material-ui/core'
import { VisibilityOff, Visibility } from '@material-ui/icons'

const Input: React.FC<Props> = ({ name, label, loading, type, ...rest }: Props) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)
  const [showPwd, setShowPwd] = useState<boolean>(false)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({ name: fieldName, path: 'value', ref: inputRef.current })
  }, [fieldName, registerField])

  const changeShowPwd = () => {
    setShowPwd(!showPwd)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return (
    <div className={!error ? 'div' : 'div-error'}>
      {error && <span>{t(error)}</span>}
      <input
        className='input'
        id={fieldName}
        ref={inputRef}
        defaultValue={defaultValue}
        style={{ display: loading ? 'none' : 'block' }}
        type={type === 'password' ? (showPwd ? 'text' : 'password') : type}
        {...rest}
      />
      <TextField
        className='inputLoading'
        startAdornment={<Loading />}
        style={{ display: loading ? 'block' : 'none' }}
        endAdornment={
          type === 'password' ? (
            <InputAdornment position='end'>
              <IconButton
                aria-label='toggle password visibility'
                onClick={changeShowPwd}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {showPwd ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : (
            <></>
          )
        }
      />
      {label && (
        <label className={'label'} htmlFor={fieldName}>
          {t(label || '')}
        </label>
      )}
    </div>
  )
}
export default Input
