import { all, ForkEffect, takeLatest } from 'redux-saga/effects'

// Types
import { MainConstants } from './types.d'

// Methods
import { workerCheckSettings, workerSetTheme, workerSetLocale } from './sagas'

export function* watcherCheckSettings(): Generator<
  ForkEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(MainConstants.ACTION_CHECK_SETTINGS, workerCheckSettings)
}

export function* watcherSetTheme(): Generator<
  ForkEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(MainConstants.ACTION_SET_THEME, workerSetTheme)
}

export function* watcherSetLocale(): Generator<
  ForkEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield takeLatest(MainConstants.ACTION_SET_LOCALE, workerSetLocale)
}

export default function* mainWatchers(): Generator<any, any, any> {
  yield all([watcherCheckSettings(), watcherSetTheme(), watcherSetLocale()])
}
