// Classes
import Exception from 'helpers/Exception'

// Types
import Codes from 'helpers/Codes'
import { AuthenticationBody, UpdatePwdQueryBody } from './types.d'

// Instance
import axiosInstance from 'config/axiosConfig'
import { apiPatch } from 'api'

export const authenticate = async (body: AuthenticationBody): Promise<string> => {
  try {
    const { data }: any = await axiosInstance.post('/v1/authentication', body)
    return data.token
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/auth/authenticate', error)
  }
}

export const updatePassword = async (token: string, body: UpdatePwdQueryBody): Promise<string> => {
  try {
    const { message }: any = await apiPatch(`/v1/users/password`, token, body)
    return message
  } catch (error: any) {
    if (error instanceof Exception) {
      throw error
    }
    throw new Exception(Codes.Internals.UNEXPECTED_ERROR, 'api/password/patch', error)
  }
}
