import { call, Effect, put, PutEffect, select } from 'redux-saga/effects'
import Exception from '../../helpers/Exception'

// Methods
import { workerSignOut } from 'store/auth/sagas'

// types
import { ParametrosPO, ParametrosPOConstants, ParametrosPOStore } from './types.d'
import { MainConstants } from '../main/types.d'
import store from '../index'
import _ from 'lodash'
import { getParametrosPO, postParametrosPO } from 'api/parametrosPO/po'
import Codes from 'helpers/Codes'
import { INITIAL_STORE } from './reducers'

function* putParametrosPOData(payload: any): Generator<
  PutEffect<{
    type: ParametrosPOConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: ParametrosPOConstants.REDUCER_SET_PARAMETROSPO_DATA,
    payload
  })
}

function* putMainData(payload: any): Generator<
  PutEffect<{
    type: MainConstants
    payload: any
  }>,
  void,
  unknown
> {
  yield put({
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload
  })
}

export function* workerGetParametrosPO(action: Effect): any {
  const { idIntegrador } = action.payload
  try {
    yield call(putParametrosPOData, { parametrosPOLoading: true })

    const { credentials } = store.getState().auth

    const parametrosPO: ParametrosPO = yield call(getParametrosPO, credentials.token, {
      idIntegrador
    })

    yield call(putParametrosPOData, {
      parametrosPO,
      parametrosPOLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/parametrosPO/workerGetParametrosPO',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putParametrosPOData, { parametrosPOLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSetSelectedParametrosPO(action: Effect): any {
  const { idIntegrador } = action.payload
  try {
    yield call(putParametrosPOData, { parametrosPOLoading: true })
    const { credentials } = store.getState().auth

    const parametrosPO = yield call(getParametrosPO, credentials.token, { idIntegrador })

    yield call(putParametrosPOData, {
      parametrosPO,
      parametrosPOLoading: false
    })
    if (parametrosPO[0]) {
      yield call(putParametrosPOData, {
        selectedParametrosPO: parametrosPO[0]
      })
    } else {
      yield call(putParametrosPOData, {
        selectedParametrosPO: INITIAL_STORE.selectedParametrosPO
      })
    }
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/parametrosPO/workerSetSelectedParametrosPO',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putParametrosPOData, { parametrosPOLoading: false })
    yield call(putMainData, { message })
  }
}

export function* workerSaveParametrosPO(action: Effect): any {
  const { data } = action.payload
  try {
    yield call(putParametrosPOData, { parametrosPOLoading: true })

    const { credentials } = store.getState().auth

    const newParametrosPO = yield call(postParametrosPO, credentials.token, data)
    yield call(putParametrosPOData, {
      parametrosPO: newParametrosPO,
      selectedParametrosPO: newParametrosPO[0],
      parametrosPOLoading: false
    })
  } catch (error: any) {
    console.log(error)
    if (error.response?.status === 401) yield call(workerSignOut)
    const ex = new Exception(
      Codes.Internals.UNEXPECTED_ERROR,
      'sagas/parametrosPO/workerSaveParametrosPO',
      error
    )
    let message = ex.getMessage().text

    if (error instanceof Exception) {
      message = error.getMessage().text
    }
    yield call(putParametrosPOData, { parametrosPOLoading: false })
    yield call(putMainData, { message })
  }
}
