export type AnaliseCrescimentoStore = {
  dadosAnalise?: DadosAnalise[]
  pesos: Peso[]
  selectedIdGranja?: number
  selectedIdGalpão?: number
  analiseCrescimentoLoading: boolean
  pesosLoading: boolean
}

export type DadosAnalise = {
  idGranja: number
  idNucleo: number
  idGalpao: string
  numeroGalpao?: any
  nroLote?: number
  idadeMatriz?: number
  ca?: number
  nroAnimais?: number
  mortalidade?: number
  raca?: string
  genero?: string
  dataNascimento?: string
}

export enum AnaliseCrescimentoConstants {
  ACTION_GET_ANALISE_CRESCIMENTO = 'ACTION_GET_ANALISE_CRESCIMENTO',
  REDUCER_CLEAR_ANALISE_CRESCIMENTO_DATA = 'REDUCER_CLEAR_ANALISE_CRESCIMENTO_DATA',
  REDUCER_SET_ANALISE_CRESCIMENTO_DATA = 'REDUCER_SET_ANALISE_CRESCIMENTO_DATA'
}
