import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { ThemeProvider } from '@material-ui/core'

// Types
import { ApplicationStore } from '../store/types.d'
import { Props } from './types.d'
import { Themes } from 'store/main/types.d'

// Components
import Auth from './Auth'
import Container from './Container'
import PublicRoutes from './PublicRoutes'
import NotFound from 'pages/NotFound'

// Methods
import { checkAuthentication } from '../store/auth/actions'
import { checkSettings } from '../store/main/actions'

// Resources
import { lightTheme, darkTheme } from 'styles/themes'
import { useTranslation } from 'react-i18next'
import Protected from './Protected'
import { StylesProvider } from '@material-ui/core/styles'

const Router = (props: Props) => {
  props.checkAuthentication()
  props.checkSettings()
  const { i18n } = useTranslation()
  if (i18n.language !== props.locale) {
    i18n.changeLanguage(props.locale)
  }
  return (
    <BrowserRouter>
      <StylesProvider>
        <ThemeProvider theme={props.theme === Themes.LIGHT ? lightTheme : darkTheme}>
          <div className={`app-container-${props.theme}`}>
            <Switch>
              <Route path='/not-found' component={NotFound} />
              {[...PublicRoutes]}
              <Auth exact path='/login' />
              <Protected
                path={'/'}
                component={<Container />}
                isAuthenticated={props.isAuthenticated}
              />
            </Switch>
          </div>
        </ThemeProvider>
      </StylesProvider>
    </BrowserRouter>
  )
}

const mapStoreToProps = (store: ApplicationStore) => ({
  isAuthenticated: store.auth.isAuthenticated,
  theme: store.main.theme,
  locale: store.main.locale
})

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      checkAuthentication,
      checkSettings
    },
    dispatch
  )
}

export default connect(mapStoreToProps, mapDispatchToProps)(Router)
