import { Themes, MainConstants, Locales } from './types.d'

export const checkSettings = (): Record<string, any> => {
  return {
    type: MainConstants.ACTION_CHECK_SETTINGS,
    payload: {}
  }
}

export const setTheme = (theme: Themes): Record<string, any> => {
  return {
    type: MainConstants.ACTION_SET_THEME,
    payload: {
      theme
    }
  }
}

export const setLocale = (locale: Locales): Record<string, any> => {
  return {
    type: MainConstants.ACTION_SET_LOCALE,
    payload: {
      locale
    }
  }
}

export const toggleDrawer = (isDrawerOpen: boolean): Record<string, any> => {
  return {
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload: {
      isDrawerOpen
    }
  }
}

export const setInfoModalMessage = (infoModalMessage: string): Record<string, any> => {
  return {
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload: {
      infoModal: {
        open: true,
        message: infoModalMessage
      }
    }
  }
}
export const closeInfoModal = (): Record<string, any> => {
  return {
    type: MainConstants.REDUCER_SET_MAIN_DATA,
    payload: {
      infoModal: {
        open: false
      }
    }
  }
}
